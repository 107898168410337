import React, { useEffect, useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import MyLoader from "../../../components/Loader/myLoader";
import { sortArray } from "../../../Utils/helper";
import SelectOption from "../../SelectOption/ProductSelectOption";
import moment from "moment";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import { downloadCsv } from "../../../components/CsvDownload/CsvDownload";
import Paginations from "../../../components/Pagination";
const { RangePicker } = DatePicker;

const ReportDetailsAgent = () => {
  const [history, setHistory] = useState({ data: [], count: 0, csvFile: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchdata, setSearchdata] = useState({
    customerName: "",
    startDate: "",
    endDate: "",
  });
  const [selectedProd, setSelectedProd] = useState(null);
  const [page, setPage] = useState(1);
  const [sortData, setSortData] = useState({ name: "", order: "" });

  const { http } = ApiClient();

  const getMonthllyHistoryData = async (payload = false) => {
    try {
      let reqPayload = {
        productId: selectedProd,
        ...searchdata,
      };

      if (payload) {
        setPage(1);
      }
      setLoading(true);

      const res = await http.post(
        `/getMonthWiseProductSearchDetailsAgent/${payload ? 1 : page}`,
        payload ? reqPayload : null
      );
      setHistory({
        ...history,
        data: res?.data?.data?.map((el) => {
          return { ...el, userNameSpecial: el?.userName?.toLowerCase() };
        }),
        count: res?.data?.count,
        csvFile: res?.data?.csvFile,
      });
      let totalPage = res?.data?.count;

      setLoading(false);
    } catch (error) {
      setLoading(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getMonthllyHistoryData();
    return () => {};
    // eslint-disable-next-line
  }, [page]);

  const sortHistoryData = (name, order) => {
    setHistory({
      ...history,
      data: sortArray(
        history.data,
        name === "userName" ? "userNameSpecial" : name,
        order
      ),
    });
  };

  useEffect(() => {
    if (sortData.name && sortData.order) {
      sortHistoryData(sortData.name, sortData.order);
    }
    return () => {};
    // eslint-disable-next-line
  }, [sortData]);

  return (
    <LayoutAgent sideBarActive={25}>
      <MyLoader loading={loading} />
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>
            <Link to={`${process.env.REACT_APP_BASE_URL}/ReportAgent`}>
              <img
                src={IMAGE_PATHS.ep_back}
                loading="lazy"
                alt="Logo"
                // width={"57px"}
              />
            </Link>
            Month wise product search details
          </h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}>
              Dashboard
            </Link>
            <span>/ Report</span>
          </div>
        </div>
        <div className="Container_body">
          <div className="twoPart">
            <div className="left">
              <div className="searchHistoryFormBar">
                <div>
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Customer Name"
                      name="customerName"
                      value={searchdata.customerName}
                      onChange={(e) => {
                        setSearchdata({
                          ...searchdata,
                          customerName: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </div>
                <div>
                  <SelectOption setSelectedProduct={setSelectedProd} />
                </div>
                <div className="dateButtonContainer2">
                  <RangePicker
                    onChange={(event) => {
                      setSearchdata({
                        ...searchdata,
                        startDate: event
                          ? moment(event[0]["$d"]).format("YYYY-MM-DD")
                          : "",
                        endDate: event
                          ? moment(event[1]["$d"]).format("YYYY-MM-DD")
                          : "",
                      });
                    }}
                    className="AgentRangePicker"
                  />
                </div>
                <div>
                  <Button
                    className="button_blue"
                    onClick={() => {
                      getMonthllyHistoryData(true);
                    }}
                  >
                    View
                  </Button>
                </div>
              </div>
            </div>
            <div className="right">
              <Button
                className="button_redBorder"
                onClick={() => downloadCsv(history.csvFile)}
              >
                Export Report
              </Button>
            </div>
          </div>
          <div className="table_area">
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>
                    <span className="shorting_container">
                      Customer Name
                      <span className="shorting">
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "customerName", order: "asc" });
                          }}
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({
                              name: "customerName",
                              order: "desc",
                            });
                          }}
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Month
                      <span className="shorting">
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "month", order: "asc" });
                          }}
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "month", order: "desc" });
                          }}
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      User Name
                      <span className="shorting">
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "userName", order: "asc" });
                          }}
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "userName", order: "desc" });
                          }}
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Created on
                      <span className="shorting">
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "createdOn", order: "asc" });
                          }}
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "createdOn", order: "desc" });
                          }}
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Last Update
                      <span className="shorting">
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "lastUpdated", order: "asc" });
                          }}
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "lastUpdated", order: "desc" });
                          }}
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Product
                      <span className="shorting">
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "productName", order: "asc" });
                          }}
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                        <img
                          aria-hidden="true"
                          onClick={() => {
                            setSortData({ name: "productName", order: "desc" });
                          }}
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                        />
                      </span>
                    </span>
                  </th>
                  <th className="tdWidth100">
                    <span className="shorting_container">Matched Carriers</span>
                  </th>
                  <th>
                    <span className="shorting_container">Reviewed Carrier</span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Confirmed Carrier
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {history?.data?.map((el, id) => (
                  <tr key={id}>
                    <td>{el?.customerName}</td>
                    <td>{moment(el?.month).format("MMMM")}</td>
                    <td>{el?.userName}</td>
                    <td>{moment(el?.createdOn).format("DD/MM/YYYY")}</td>
                    <td>{moment(el?.lastUpdated).format("DD/MM/YYYY")}</td>
                    <td>{el?.productName}</td>
                    <td className="tdCenter">{el?.matchedCarrier}</td>
                    <td>
                      {el?.reviwedCarrier?.length
                        ? el?.reviwedCarrier?.map((ele, idx) =>
                            idx === 0 ? `${ele}` : `, ${ele}`
                          )
                        : "NA"}
                    </td>
                    <td>{el?.confirmedCarrier[0] || "NA"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {!history?.data?.length ? (
              <div style={{ textAlign: "center", marginTop: "5rem" }}>
                No Data Found
              </div>
            ) : null}
          </div>
        </div>

        {history?.data.length ? (
          <Paginations page={page} setPage={setPage} count={history.count} />
        ) : null}
      </div>
      {/* <CommonMonthlySearchHistory type="agent" /> */}
    </LayoutAgent>
  );
};

export default ReportDetailsAgent;
