import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../components/Loader/Loader";
import { isLogin } from "../../../components/IsLogin/IsLogin";

const Reviews = () => {
  const [editReviewsDetails, setEditReviewsDetails] = useState({
    allReviews: [],
    reviewId: 0,
  });

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (reviewId, status) => {
    setEditReviewsDetails({
      ...editReviewsDetails,
      reviewId,
      initialCheckboxState: status,
    });
    setShow(true);
  };

  const { http } = ApiClient();

  const editActiveInActiveOnChangeHandle = () => {
    setIsLoader(true);
    http
      .delete(`/inactiveReview/${editReviewsDetails.reviewId}`)
      .then(() => {
        getAllReviews();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
    handleClose();
  };

  const getAllReviews = () => {
    setIsLoader(true);
    http
      .get(`/getReviewDetailsAdmin`)
      .then((res) => {
        setEditReviewsDetails({
          ...editReviewsDetails,
          allReviews: res.data,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllReviews();

    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <Layout sideBarActive={13}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>Reviews</h1>
          <div className="rightWithButton">
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Reviews</span>
          </div>
        </div>

        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Reviewer Name</th>
                <th>Review Date</th>
                <th>Review Message</th>
                <th>Rating</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {editReviewsDetails?.allReviews?.length ? (
                editReviewsDetails.allReviews.map(
                  ({
                    reviewEntityId,
                    reviewerName,
                    reviewMessage,
                    reviewNumber,
                    reviewDate,
                    status,
                  }) => (
                    <tr key={reviewEntityId}>
                      <td>{reviewerName}</td>
                      <td>{reviewDate}</td>
                      <td>{reviewMessage}</td>
                      <td>{reviewNumber}</td>

                      <td>
                        <span
                          className={
                            status === 1
                              ? "active_green cursorPointer"
                              : "pending_red cursorPointer"
                          }
                          onClick={() => handleShow(reviewEntityId, status)}
                        >
                          {status === 1 ? "Active" : "InActive"}
                        </span>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>No Data Found</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          <Modal
            show={show}
            onHide={handleClose}
            className="switchModal"
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h3>
                Are you sure,
                <br />
                you want to{" "}
                {editReviewsDetails.initialCheckboxState === 0
                  ? "active"
                  : "inActive"}{" "}
                Reviews?{" "}
              </h3>
              <div className="wrap">
                <label htmlFor="switchA1" className="switch-item">
                  <input
                    type="checkbox"
                    name="editIsActive"
                    className="control"
                    defaultChecked={editReviewsDetails.initialCheckboxState}
                    onChange={() => editActiveInActiveOnChangeHandle()}
                  />
                  <span className="label"></span>
                  <span className="label_L"></span>
                </label>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default Reviews;
