import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../components/Loader/Loader";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import Paginations from "../../../components/Pagination";

const UserManagement = () => {
  const [page, setPage] = useState(1);

  const [editUserDetails, setEditUserDetails] = useState({
    firstName: "",
    lastName: "",
    userEmail: "",
    organisationName: "",
    allUsers: [],
    userId: 0,
    count: 1,
  });

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [editShow, setEditShow] = useState(false);
  const handleShow = (userId, status) => {
    setEditUserDetails({
      ...editUserDetails,
      userId: userId,
      initialCheckboxState: status,
    });
    setShow(true);
  };
  const handleEditShowClose = () => setEditShow(false);

  const { http } = ApiClient();

  const handleEditShow = (userId, agentEntity, status) => {
    setIsLoader(true);

    http
      .get(`/getUser/${aesUtil.encrypt(JSON.stringify(userId))}`, {})
      .then((res) => {
        setEditUserDetails({
          ...editUserDetails,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.userEmail,
          organisationName: res.data.agentEntity.organisationName,
          userId: userId,
          agentId: agentEntity.entityId,
          status: status,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });

    setEditShow(true);
  };
  const deleteUserOnClick = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoader(true);
        http
          .delete(`/deleteUser/${aesUtil.encrypt(JSON.stringify(userId))}`, {})
          .then(() => {
            setIsLoader(false);
            getAllUsers();
          })
          .catch((error) => {
            setIsLoader(false);
            isLogin(error, navigate, Swal);
          });
      }
    });
  };

  const editUserOnChangeHandle = (event) => {
    let { name, value } = event.target;
    setEditUserDetails({ ...editUserDetails, [name]: value });
  };

  const editActiveInActiveOnChangeHandle = (event) => {
    setIsLoader(true);
    http
      .delete(
        `/inactiveUser/${aesUtil.encrypt(
          JSON.stringify(editUserDetails.userId)
        )}`,
        {}
      )
      .then((res) => {
        getAllUsers();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
    handleClose();
  };

  const handleSubmitEditButtonOnClick = () => {
    const { firstName, lastName, email } = editUserDetails;
    if (!firstName || !lastName || !email) {
      return Swal.fire({
        title: "Oops...",
        text: "All Fields are Mandatory",
        confirmButtonText: "Cancel",
      });
    }

    let updateUserPayload = {
      userId: editUserDetails.userId,
      agentEntity: {
        entityId: editUserDetails.agentId,
      },
      firstName,
      lastName,
      userEmail: email,
      status: editUserDetails.status,
    };
    setIsLoader(true);
    http
      .put("/updateUser", updateUserPayload)
      .then(() => {
        getAllUsers();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
    handleEditShowClose();
  };

  const getAllUsers = () => {
    setIsLoader(true);
    http
      .get(`/getAllUser/${page}`)
      .then((res) => {
        setEditUserDetails({
          ...editUserDetails,
          allUsers: res.data.data,
          count: res.data.count,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        // isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllUsers();

    return () => {};
    // eslint-disable-next-line
  }, [page]);
  return (
    <Layout sideBarActive={9}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>User Management</h1>
          <div className="rightWithButton">
            {/* <div>
              <Link
                to={`${process.env.REACT_APP_BASE_URL}/Admin/AddNnewcarrier`}
              >
                <Button className="button_blueBorder">
                  <img
                    src={IMAGE_PATHS.phPlusBoldIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"16px"}
                  />
                  Add User
                </Button>
              </Link>
            </div> */}
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Payments</span>
          </div>
        </div>

        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email address</th>
                <th>Organisation Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {editUserDetails.allUsers.length ? (
                editUserDetails.allUsers.map(
                  ({
                    userId,
                    firstName,
                    lastName,
                    userEmail,
                    agentEntity,
                    status,
                  }) => (
                    <tr key={userId}>
                      <td>{firstName + " " + lastName}</td>
                      <td>{userEmail}</td>
                      <td>{agentEntity.organisationName}</td>
                      <td>
                        <span
                          className={
                            status === 1
                              ? "active_green cursorPointer"
                              : "pending_red cursorPointer"
                          }
                          onClick={() => handleShow(userId, status)}
                        >
                          {status === 1 ? "Active" : "InActive"}
                        </span>
                      </td>
                      <td>
                        <span className="action_icon_container">
                          <span
                            onClick={() =>
                              handleEditShow(userId, agentEntity, status)
                            }
                          >
                            <img
                              src={IMAGE_PATHS.edit_icon_table}
                              loading="lazy"
                              alt="Logo"
                              width={"20px"}
                            />
                          </span>

                          <span onClick={() => deleteUserOnClick(userId)}>
                            <img
                              src={IMAGE_PATHS.DeleteIconGray}
                              loading="lazy"
                              alt="Logo"
                              height={"18px"}
                            />
                          </span>
                        </span>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>No Data Found</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          <Modal
            show={show}
            onHide={handleClose}
            className="switchModal"
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h3>
                Are you sure,
                <br />
                you want to inactive user?{" "}
              </h3>
              <div className="wrap">
                <label htmlFor="switchA1" className="switch-item">
                  <input
                    type="checkbox"
                    name="editIsActive"
                    className="control"
                    defaultChecked={editUserDetails.initialCheckboxState}
                    onChange={(event) =>
                      editActiveInActiveOnChangeHandle(event)
                    }
                  />
                  <span className="label"></span>
                  <span className="label_L"></span>
                </label>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <Modal
          show={editShow}
          onHide={handleEditShowClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="modal_top">
                <div className="left">
                  <h1>Edit User</h1>
                </div>
                <div className="right">
                  <img
                    src={IMAGE_PATHS.modalCloseIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"29px"}
                    onClick={handleEditShowClose}
                    className="modalCloseIcon"
                  />
                </div>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">
                    Organisation Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    placeholder={editUserDetails.organisationName}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">First Name</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    name="firstName"
                    value={editUserDetails.firstName}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={editUserDetails.lastName}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    value={editUserDetails.email}
                    onChange={(event) => editUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button"
                  onClick={() => handleSubmitEditButtonOnClick()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {editUserDetails.allUsers.length ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={editUserDetails.count}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default UserManagement;
