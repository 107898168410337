import _ from "lodash";

export const sortArray = (arr, name, order = "asc") => {
  if (!name || !arr?.length) {
    return [];
  }
  try {
    let sortedArr = _.orderBy(arr, [name], [order]);
    return sortedArr;
  } catch (error) {
    return [];
  }
};
