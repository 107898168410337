import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import IMAGE_PATHS from "../../../constants";
import Modal from "react-bootstrap/Modal";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { PolicyDownload } from "../../../components/PolicyDownload/Policydownload";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import { scrollToTop } from "../../../components/ScrollTop/ScrollTop.";
import { filteredAnswer } from "../../../components/FunctionHelper";

const AdminDashboardSearch = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);
  const [recentStates, setRecentStates] = useState([]);

  const [show2, setShow2] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  const [stateShow, setStateShow] = useState(false);

  let data = sessionStorage.getItem("agentDashboardSearchDetails");
  let searchData = JSON.parse(data);

  const [searchDetails, setSearchDetails] = useState({
    customerName: searchData?.customerName,
    product: searchData?.product,
    state: searchData?.state,
    filteredQuestionAnswer: [],
    productList: [],
    allStates: [],
    likedQuestion: {
      carrierId: 0,
      userId: 0,
      like: [
        {
          likeQuestionsId: 0,
          likeAnswer: 1,
        },
      ],
    },
    matchQuestions: [],
    matchCriteriaLogo: "",
    matchCriteriaPercentage: 0,
    dislikeLogo: "",
    dislikedQuestions: [],
    carrierLikeQuestionsDetails: [],
    allSearchedDetails: {},
  });

  const handleClose2 = (button) => {
    const filteredArray = searchDetails.dislikedQuestions.filter(
      (item) => item.dislikeAnswer === 1 || item.dislikeAnswer === 2
    );

    let dislikeQuestionPayload = {
      carrierId: searchDetails.carrierId,
      adminId: JSON.parse(sessionStorage.getItem("profileDetails")).adminId,
      like: filteredArray,
    };

    if (button === "confirm") {
      setisLoader(true);

      http
        .post("/saveCarrierDislike", dislikeQuestionPayload)
        .then(() => {
          searchCarrierWithQuestion(searchDetails.filteredQuestionAnswer);
        })
        .catch((error) => {
          setisLoader(false);
          isLoginAgent(error, navigate, Swal);
        });
    }
    setShow2(false);
  };

  const handleClose3 = () => setShow3(false);
  const handleShow3 = (questionDetails, carrierLogo, rating) => {
    if (!questionDetails) return;
    setSearchDetails({
      ...searchDetails,
      matchCriteriaLogo: carrierLogo,
      matchCriteriaPercentage: rating,
      matchQuestions: questionDetails,
    });
    setShow3(true);
  };

  const { http } = ApiClient();

  const handleShow = (carrierId) => {
    getAllCarrierLikeQuestionsDetails(carrierId);
    getCarrierLikedQuestion(carrierId);

    setShow(true);
  };

  // likeQuestion apis

  const getAllCarrierLikeQuestionsDetails = (carrierId) => {
    setisLoader(true);
    http
      .get("/getAllCarrierLikeQuestions", {})
      .then((res) => {
        setSearchDetails((prevSearchDetails) => ({
          ...prevSearchDetails,
          carrierLikeQuestionsDetails: res.data,
          carrierId: carrierId,
        }));
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  const getCarrierLikedQuestion = (carrierId) => {
    setisLoader(true);
    http
      .get(`/getCarrierLike/${aesUtil.encrypt(JSON.stringify(carrierId))}`, {})
      .then((res) => {
        setSearchDetails((prevSearchDetails) => ({
          ...prevSearchDetails,
          likedQuestion: {
            carrierId: carrierId,
            isConnect: res.data?.isConnect,
            carrierLogo: res.data?.carrierLogo,
            like: [
              {
                likeQuestionsId:
                  res.data.carrierLikeResponses.length &&
                  res.data.carrierLikeResponses[0].likeQuestionsId,
                likeAnswer:
                  res.data.carrierLikeResponses.length &&
                  res.data.carrierLikeResponses[0].likeAnswer,
              },
            ],
          },
        }));
        setisLoader(false);
      })
      .catch((error) => {
        isLoginAgent(error, navigate, Swal);
      })
      .finally(() => setisLoader(false));
  };

  // likeQuestion apis end

  const handleShow2 = (carrierId) => {
    getDislikedQuestion(carrierId);
    setShow2(true);
  };

  //  dislikeQuestion  apis
  const getDislikedQuestion = (carrierId) => {
    setisLoader(true);
    http
      .get(`/getCarrierDisLike/${aesUtil.encrypt(JSON.stringify(carrierId))}`)
      .then((res) => {
        const mergedArray =
          searchDetails.allSearchedDetails?.productQuestion.map((item2) => {
            const matchedItem = res.data.carrierDislikeResponses.find(
              (item1) => item1.productQuestionId === item2.productQuestionId
            );
            return matchedItem ? { ...item2, ...matchedItem } : item2;
          });
        setSearchDetails({
          ...searchDetails,
          dislikedQuestions: mergedArray,
          carrierId: carrierId,
          dislikeLogo: res.data.carrierLogo,
        });
        setisLoader(false);
      })
      .catch((error) => {
        isLoginAgent(error, navigate, Swal);
      })
      .finally(() => setisLoader(false));
  };

  // dislikeQuestion apis end

  const handleClose = (button) => {
    let likeQuestionPayload = {
      carrierId: searchDetails.carrierId,
      adminId: JSON.parse(sessionStorage.getItem("profileDetails")).adminId,
      like: [
        {
          likeQuestionsId: +searchDetails.likedQuestion.like[0].likeQuestionsId,
          likeAnswer: 1,
        },
      ],
    };

    if (
      searchDetails.likedQuestion.like[0].likeQuestionsId === 0 &&
      button === "confirm"
    ) {
      return Swal.fire({
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    if (
      searchDetails.likedQuestion.like[0].likeQuestionsId &&
      button === "confirm"
    ) {
      setisLoader(true);

      http
        .post("/saveCarrierLike", likeQuestionPayload)
        .then(() => {
          searchCarrierWithQuestion(searchDetails.filteredQuestionAnswer);
        })
        .catch((error) => {
          isLoginAgent(error, navigate, Swal);
        });
    }
    setShow(false);
  };

  const handleShowState = () => {
    getAllState();

    getAllStatesWithRecent();
    setStateShow(true);
  };
  const handleCloseState = () => {
    setStateShow(false);
  };

  const stateModalOnClickHandle = () => {
    setStateShow(false);
  };

  const carrierLikeDislikeOnChangeHandle = (event) => {
    let { name, value } = event.target;

    if (name === "likeQuestion") {
      setSearchDetails((prevSearchDetails) => ({
        ...prevSearchDetails,
        likedQuestion: {
          ...prevSearchDetails.likedQuestion,
          like: [
            {
              ...prevSearchDetails.likedQuestion.like[0],
              likeQuestionsId: +value,
              likeAnswer: 1,
            },
          ],
        },
      }));
    }
  };

  const likeDislikeOnClickHandle = (type, productQuestionId) => {
    setSearchDetails((prevSearchDetails) => {
      const updatedDislikedQuestion = prevSearchDetails.dislikedQuestions.map(
        (item) => {
          if (item.productQuestionId === productQuestionId) {
            if (type === "like") {
              return {
                ...item,
                dislikeAnswer: item.dislikeAnswer === 1 ? 0 : 1,
              };
            } else if (type === "dislike") {
              return {
                ...item,
                dislikeAnswer: item.dislikeAnswer === 2 ? 0 : 2,
              };
            }
          }
          return item;
        }
      );
      return {
        ...prevSearchDetails,
        dislikedQuestions: updatedDislikedQuestion,
      };
    });
  };

  const getAllStatesWithRecent = () => {
    setisLoader(true);
    http
      .get("/getAllSearchState")
      .then((res) => {
        if (res.status === 200) {
          setRecentStates(res.data);
        }

        setisLoader();
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      })
      .finally(() => setisLoader(false));
  };

  const handleCarrierSearchOnChange = (event) => {
    let { name, value, id } = event.target;
    if (name === "customerName") {
      setSearchDetails({ ...searchDetails, [name]: value });
    }
    if (name === "state") {
      setSearchDetails({
        ...searchDetails,
        state: { stateName: value, stateId: +id },
      });
    }
    if (name === "product") {
      setSearchDetails({
        ...searchDetails,
        product: { proName: value, proId: +event.target.selectedOptions[0].id },
      });
    }
  };

  const searchCarrierWithQuestion = (filteredArray) => {
    let productWiseCarriersPayload = {
      customerName: searchDetails.customerName,
      productId: searchDetails.product.proId,
      stateId: searchDetails.state.stateId,
      questions: filteredArray,
    };

    setisLoader(true);
    http
      .post("/searchCarrier", productWiseCarriersPayload)
      .then((res) => {
        setSearchDetails((prevSearchDetails) => {
          return {
            ...prevSearchDetails,
            allSearchedDetails: res.data,
          };
        });
        setisLoader(false);

        scrollToTop();
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      })
      .finally(() => setisLoader(false));
  };

  const productQuestionOnChangeHandle = (event, questionId, type) => {
    let { value } = event.target;
    if (!value) return;
    let filteredArray = filteredAnswer(value, type, questionId);
    setSearchDetails((prevSearchDetails) => {
      return {
        ...prevSearchDetails,
        filteredQuestionAnswer: filteredArray,
      };
    });
    searchCarrierWithQuestion(filteredArray);
  };

  const handleSearchCarrierButtonOnClick = () => {
    let searchedDetails = {
      customerName: searchDetails.customerName,
      product: searchDetails.product,
      state: searchDetails.state,
    };

    sessionStorage.setItem(
      "agentDashboardSearchDetails",
      JSON.stringify(searchedDetails)
    );
    window.location.reload(true);
    // saveProAndState();
    // getAllProduct();
  };

  const downloadPdf = (carrierId, carrierName) => {
    setisLoader(true);
    http
      .get(`/getPolicy/${aesUtil.encrypt(JSON.stringify(carrierId))}`, {})
      .then((res) => {
        PolicyDownload(res.data.carrierPolicy, carrierName);

        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  const getAllState = () => {
    setisLoader(true);
    http
      .get("/allState", {})
      .then((res) => {
        setSearchDetails({
          ...searchDetails,
          allStates: res.data,
        });
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  const saveProAndState = () => {
    let searchCarrierPayload = {
      customerName: searchDetails.customerName,
      productId: searchDetails.product?.proId,
      stateId: searchDetails.state?.stateId,
    };
    setisLoader(true);

    http
      .post("/searchCarrier", searchCarrierPayload)
      .then((res) => {
        setSearchDetails({
          ...searchDetails,
          allSearchedDetails: res.data,
        });
      })
      .catch((error) => {
        isLoginAgent(error, navigate, Swal);
      })
      .finally(() => setisLoader(false));
  };

  const getAllProduct = () => {
    setisLoader(true);
    http
      .get("/getAllConnectedProduct", {})
      .then((res) => {
        setSearchDetails({
          ...searchDetails,
          productList: res.data,
        });
        // setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllProduct();
    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchDetails.productList.length > 0) {
      saveProAndState();
    }
    return () => {};
    // eslint-disable-next-line
  }, [searchDetails.productList]);
  console.log(searchDetails, "DETIAL");

  return (
    <Layout sideBarActive={1}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer">
        <div className="searchBar">
          <Form>
            <ul>
              <li>Searching for Carriers</li>
              <li>
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Customer Name"
                    value={searchDetails.customerName}
                    name="customerName"
                    onChange={(event) => handleCarrierSearchOnChange(event)}
                  />
                </Form.Group>
              </li>
              <li>
                <Form.Select
                  aria-label="Default select example"
                  name="product"
                  value={searchDetails.product?.proName}
                  onChange={(event) => handleCarrierSearchOnChange(event)}
                >
                  <option hidden>Select Product</option>

                  {searchDetails.productList.length ? (
                    searchDetails.productList.map(
                      ({ productId, productName }) => (
                        <option
                          key={productId}
                          value={productName}
                          id={productId}
                        >
                          {productName}
                        </option>
                      )
                    )
                  ) : (
                    <option>No Data Found</option>
                  )}
                </Form.Select>
              </li>
              <li>
                <Form.Select
                  aria-label="Default select example"
                  name="state"
                  onClick={handleShowState}
                >
                  <option hidden>
                    {searchDetails.state?.stateName
                      ? searchDetails.state.stateName
                      : "Select State"}
                  </option>
                </Form.Select>
              </li>
              <li>
                <Button
                  className="searchButtonBorderTransparentBg"
                  onClick={() => handleSearchCarrierButtonOnClick()}
                >
                  Start New Search
                </Button>
                {/* </Link> */}
              </li>
            </ul>
          </Form>
        </div>
        <div className="head_bread adminDasHead">
          <div className="ReportDate">
            <h1>Select answer from below questions</h1>
          </div>
        </div>

        <div className="AgentDashLeftRight">
          <Row>
            <Col xs="" lg="4" className="AgentDashLeft">
              <div className="white_data_box">
                {Object.keys(searchDetails.allSearchedDetails).length > 1 ? (
                  searchDetails.allSearchedDetails.productQuestion.length ? (
                    searchDetails.allSearchedDetails.productQuestion?.map(
                      ({
                        masterFieldType,
                        productQuestionId,
                        questionLabel,
                        questionLabelCode,
                        isCurrentYearField,
                        optionType,
                      }) =>
                        masterFieldType.fieldType === "Text" ? (
                          <div
                            className="formBox01"
                            key={productQuestionId}
                            style={{ width: "100%" }}
                          >
                            <div className="topPart">
                              <h3
                                className="heading01"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {questionLabel}
                                {isCurrentYearField === 2 && (
                                  <img
                                    width={24}
                                    src={IMAGE_PATHS.tooltip_icon}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    title="Current Year"
                                  />
                                )}
                              </h3>
                            </div>
                            <div className="">
                              <Form.Group
                                className="width125"
                                controlId="formBasicEmail"
                                style={{ width: "55%" }}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder={`Enter ${questionLabel}`}
                                  name={questionLabelCode}
                                  className="form_control_2"
                                  onBlur={(event) =>
                                    productQuestionOnChangeHandle(
                                      event,
                                      productQuestionId,
                                      "Text"
                                    )
                                  }
                                />
                              </Form.Group>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="formBox01"
                            key={productQuestionId}
                            style={{ width: "100%" }}
                          >
                            <div className="topPart">
                              <h3 className="heading01">{questionLabel}</h3>
                            </div>
                            <div className="borderBoxCheckBox">
                              <fieldset className="checkbox-group">
                                {optionType.map((optionValues, ind) => (
                                  <div key={ind} className="checkbox">
                                    <label className="checkbox-wrapper">
                                      <input
                                        type="radio"
                                        className="checkbox-input"
                                        name={questionLabelCode}
                                        value={optionValues}
                                        onChange={(event) =>
                                          productQuestionOnChangeHandle(
                                            event,
                                            productQuestionId,
                                            "Option"
                                          )
                                        }
                                      />
                                      <span className="checkbox-tile">
                                        <span className="checkbox-label">
                                          {optionValues}
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </fieldset>
                            </div>
                          </div>
                        )
                    )
                  ) : (
                    <div>No Data Found</div>
                  )
                ) : (
                  <div>No Data Found</div>
                )}
              </div>
            </Col>
            <Col xs="" lg="8" className="AgentDashRight">
              <div className="AgentDashRightContainer">
                {Object.keys(searchDetails.allSearchedDetails).length &&
                searchDetails.allSearchedDetails.productQuestion.length ? (
                  <>
                    <h3 className="heading03 devider_02">
                      Full Match Carriers
                    </h3>
                    <div className="white_data_box2-Wrapper">
                      {searchDetails?.allSearchedDetails?.allCarrier
                        ?.matchedCarrier?.length > 0 ? (
                        searchDetails?.allSearchedDetails?.allCarrier?.matchedCarrier?.map(
                          ({
                            carrier,
                            like,
                            dislike,
                            rating,
                            questionDetails,
                          }) => (
                            <div
                              className="white_data_box2"
                              key={carrier.carrierId}
                            >
                              <div className="twoPartCommon align-items-center">
                                <div>
                                  <img
                                    src={carrier.carrierLogo}
                                    loading="lazy"
                                    alt="Logo"
                                    width={"148px"}
                                    height={"43px"}
                                  />
                                </div>
                                {carrier.carrierName}

                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleShow3(
                                      questionDetails,
                                      carrier.carrierLogo,
                                      rating
                                    )
                                  }
                                >
                                  <div
                                    role="progressbar"
                                    aria-valuenow="67"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ "--value": 100 }}
                                  ></div>
                                </div>

                                <div className="logo_like_button">
                                  <div>
                                    <Button
                                      className={`${
                                        like > 0 ? "active" : ""
                                      } thumb_icon_like`}
                                      onClick={() =>
                                        handleShow(carrier.carrierId)
                                      }
                                    />
                                    <span className="devider_01">|</span>
                                    <Button
                                      className={`${
                                        dislike > 0 ? "active" : ""
                                      } thumb_icon_dislike`}
                                      onClick={() =>
                                        handleShow2(carrier.carrierId)
                                      }
                                    />
                                  </div>
                                  <Button
                                    className="button_blueBorder_small"
                                    onClick={() =>
                                      downloadPdf(
                                        carrier.carrierId,
                                        carrier.carrierName
                                      )
                                    }
                                  >
                                    Underwriting Guidelines
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <div
                          className="white_data_box2"
                          style={{ textAlign: "center" }}
                        >
                          {/* <div className="twoPartCommon align-items-center"> */}
                          No Data Found
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                    <br />
                    <h3 className="heading03">Partial Match Carriers</h3>
                    <div className="white_data_box2-Wrapper">
                      {searchDetails?.allSearchedDetails?.allCarrier
                        ?.parsedMatchedCarrier?.length > 0 ? (
                        searchDetails?.allSearchedDetails?.allCarrier?.parsedMatchedCarrier?.map(
                          ({
                            carrier,
                            like,
                            dislike,
                            rating,
                            questionDetails,
                          }) => (
                            <div
                              className="white_data_box2"
                              key={carrier.carrierId}
                            >
                              <div className="twoPartCommon align-items-center">
                                <div>
                                  <img
                                    src={carrier.carrierLogo}
                                    loading="lazy"
                                    alt="Logo"
                                    width={"148px"}
                                    height={"43px"}
                                  />
                                </div>
                                {carrier.carrierName}
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleShow3(
                                      questionDetails,
                                      carrier.carrierLogo,
                                      rating
                                    )
                                  }
                                >
                                  <div
                                    role="progressbar"
                                    aria-valuenow="67"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ "--value": rating }}
                                  ></div>
                                </div>
                                <div className="logo_like_button">
                                  <div>
                                    <Button
                                      className={`${
                                        like > 0 ? "active" : ""
                                      } thumb_icon_like`}
                                      onClick={() =>
                                        handleShow(carrier.carrierId)
                                      }
                                    />
                                    <span className="devider_01">|</span>
                                    <Button
                                      className={`${
                                        dislike > 0 ? "active" : ""
                                      } thumb_icon_dislike`}
                                      onClick={() =>
                                        handleShow2(carrier.carrierId)
                                      }
                                    />
                                  </div>
                                  <Button
                                    className="button_blueBorder_small"
                                    onClick={() =>
                                      downloadPdf(
                                        carrier.carrierId,
                                        carrier.carrierName
                                      )
                                    }
                                  >
                                    Underwriting Guidelines
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <div
                          className="white_data_box2"
                          style={{ textAlign: "center" }}
                        >
                          {/* <div className="twoPartCommon align-items-center"> */}
                          No Data Found
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    className="white_data_box2"
                    style={{ textAlign: "center", width: "352px" }}
                  >
                    No Data Found
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>

        {/* like modal start */}

        <Modal
          show={show}
          onHide={handleClose}
          className="LikeModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="orangeRadio">
            <div className="twoPartCommon align-items-center">
              <img
                src={searchDetails.likedQuestion.carrierLogo}
                loading="lazy"
                alt="Logo"
                width={"148px"}
                height={"43px"}
              />
              {searchDetails.likedQuestion.isConnect ? (
                <img
                  src={IMAGE_PATHS.switch_active}
                  loading="lazy"
                  alt="Logo"
                  width={"30px"}
                  height={"15px"}
                />
              ) : (
                <img
                  src={IMAGE_PATHS.switch_deactive}
                  loading="lazy"
                  alt="Logo"
                  width={"30px"}
                  height={"15px"}
                />
              )}
            </div>
            <span>Great..!!!! Share Your Thoughts</span>

            <form>
              {searchDetails.carrierLikeQuestionsDetails.length &&
                searchDetails.carrierLikeQuestionsDetails.map(
                  ({ carrierLikeQuestionsId, carrierLikeQuestion }) => (
                    <div
                      className="formBox01"
                      key={carrierLikeQuestionsId}
                      style={{ width: "100%" }}
                    >
                      <input
                        type="radio"
                        id={carrierLikeQuestion}
                        name="likeQuestion"
                        value={carrierLikeQuestionsId}
                        onChange={(event) =>
                          carrierLikeDislikeOnChangeHandle(event)
                        }
                        checked={
                          carrierLikeQuestionsId ===
                          searchDetails.likedQuestion.like[0].likeQuestionsId
                        }
                      />
                      <label htmlFor={carrierLikeQuestion}>
                        {carrierLikeQuestion}
                      </label>
                    </div>
                  )
                )}
            </form>
            <div className="buttn_row mt_30">
              <Button
                className="button_blue_100"
                onClick={() => handleClose("confirm")}
              >
                Confirm
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {/* like modal end */}

        {/* dislike modal start */}
        <Modal
          show={show2}
          onHide={handleClose2}
          className="disLikeModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="orangeRadio">
            <p className="imageArea">
              <img
                src={searchDetails.dislikeLogo}
                loading="lazy"
                alt="Logo"
                width={"148px"}
                height={"43px"}
              />
            </p>
            <p className="headingArea">Please tell us what wrong or right</p>

            <form action="#">
              {searchDetails.dislikedQuestions?.map(
                ({ productQuestionId, questionLabel, dislikeAnswer }) => (
                  <div
                    className="formBox01"
                    key={productQuestionId}
                    style={{ width: "100%" }}
                  >
                    <p className="subHeadArea">{questionLabel}</p>
                    <div className="d-flex align-items-center">
                      <Button
                        className={`thumb_icon_like ${
                          dislikeAnswer === 1 && "active"
                        }`}
                        onClick={() =>
                          likeDislikeOnClickHandle("like", productQuestionId)
                        }
                      />
                      <span className="devider_01">|</span>
                      <Button
                        className={`thumb_icon_dislike ${
                          dislikeAnswer === 2 && "active"
                        }`}
                        onClick={() =>
                          likeDislikeOnClickHandle("dislike", productQuestionId)
                        }
                      />
                    </div>
                  </div>
                )
              )}
            </form>
            <div className="buttn_row mt_30">
              <Button
                className="button_blue_100"
                onClick={() => handleClose2("confirm")}
              >
                Confirm
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {/* dislike modal end */}

        {/* Full Match Carriers modal start */}
        <Modal
          show={show3}
          onHide={handleClose3}
          className="disLikeModal greenRedBoxModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="orangeRadio">
            <p className="imageArea">
              <img
                src={searchDetails.matchCriteriaLogo}
                loading="lazy"
                alt="Logo"
                width={"148px"}
                height={"43px"}
              />
            </p>
            <p className="headingArea">
              Match criteria {searchDetails.matchCriteriaPercentage || 0}%
            </p>
            {searchDetails.matchQuestions.length ? (
              searchDetails.matchQuestions.map(({ question, match }, ind) => (
                <div key={ind}>
                  <div
                    className={match === 1 ? "greenBoxModal" : "redBoxModal"}
                  >
                    {question}
                  </div>
                </div>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>No Data Found</div>
            )}
          </Modal.Body>
        </Modal>
        {/* Full Match Carriers modal end */}

        <Modal
          show={stateShow}
          onHide={handleCloseState}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="row">
                <div className="twoPartCommon align-items-center mb-3">
                  <div className="left" style={{ fontWeight: 600 }}>
                    Change States
                  </div>
                  <div className="right">
                    <img
                      src={IMAGE_PATHS.Close_modal}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      onClick={handleCloseState}
                      className="cursorPointer"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {isLoader && <Loader />}
                {recentStates?.length ? (
                  <div>Recent search states</div>
                ) : (
                  <div>No State Found</div>
                )}
                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group newStateModalOne">
                      {recentStates?.map(({ stateId, stateCode }) => (
                        <div className="checkbox" key={stateId}>
                          <label className="checkbox-wrapper">
                            <input
                              type="radio"
                              className="checkbox-input"
                              name="state"
                              id={stateId}
                              value={stateCode}
                              onChange={(event) =>
                                handleCarrierSearchOnChange(event)
                              }
                            />
                            <span className="checkbox-tile">
                              <span className="checkbox-label">
                                {stateCode}
                              </span>
                            </span>
                          </label>
                        </div>
                      ))}
                    </fieldset>
                  </div>
                </div>
                <div>Other states</div>
                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group newStateModalOne">
                      {searchDetails.allStates.length &&
                        searchDetails.allStates.map(
                          ({ stateId, stateCode }) => (
                            <div
                              style={{
                                display: recentStates?.find((obj) => {
                                  return obj?.stateCode === stateCode;
                                })
                                  ? "none"
                                  : "block",
                              }}
                              className="checkbox"
                              key={stateId}
                            >
                              <label className="checkbox-wrapper">
                                <input
                                  type="radio"
                                  className="checkbox-input"
                                  name="state"
                                  id={stateId}
                                  value={stateCode}
                                  onChange={(event) =>
                                    handleCarrierSearchOnChange(event)
                                  }
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">
                                    {stateCode}
                                  </span>
                                </span>
                              </label>
                            </div>
                          )
                        )}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="submit"
                  className="login sign_in_button"
                  onClick={() => stateModalOnClickHandle()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default AdminDashboardSearch;
