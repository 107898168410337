const PolicyDownload = (carrierPolicy, carrierName) => {
  let data = carrierPolicy.split(",");
  const byteCharacters = atob(data[1]);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${carrierName}_guildeline.pdf` || "download.pdf";
  link.click();
};

export { PolicyDownload };
