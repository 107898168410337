import React, { useEffect, useState } from "react";
import ApiClient from "../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "./Loader/Loader";

const DebitBalanace = () => {
  const [isLoader, setisLoader] = useState(false);
  const { http } = ApiClient();
  useEffect(() => {
    const debitBalance = async () => {
      try {
        setisLoader(true);
        await http.get("/getAllCharge");

        setisLoader(false);
      } catch (error) {
        setisLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message || "Sorry, Something went wrong",
          confirmButtonText: "CANCEL",
        });
      }
    };
    debitBalance();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {isLoader && <Loader />}
      <div></div>
    </div>
  );
};

export default DebitBalanace;
