import React, { useEffect } from "react";
import { useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
const allTabs = ["All", "Connected", "Not Connected"];

const CarriersAgent = (state) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const { http } = ApiClient();
  const [carrierdetails, setcarrierdetails] = useState({
    tab: sessionStorage.getItem("tab") || "All",
    allState: [],
    stateId: [],
    isConnect: 0,
    carrierId: 0,
    carrierLists: [],
  });
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (carrierId, isConnect) => {
    setIsLoader(true);
    http
      .get("/allState", {})
      .then((res) => {
        setcarrierdetails({
          ...carrierdetails,
          allState: res.data,
          stateId: [],
          isConnect: isConnect,
          carrierId: carrierId,
        });

        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
    setShow(true);
  };

  const stateOnClickHandle = () => {
    if (!carrierdetails.stateId.length) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please, select state!",
        confirmButtonText: "CANCEL",
      });
    }

    let connectPayload = {
      carrierId: carrierdetails.carrierId,
      statesId: carrierdetails.stateId,
    };

    setIsLoader(true);
    http
      .post("/saveConnectedCarrier", connectPayload)
      .then(() => {
        if (carrierdetails.tab === "All") {
          getAllCarriers();
        } else {
          notConnectCarrier();
        }
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });

    handleClose();
  };

  const disconnectOnChangeHandle = (carrierId) => {
    setIsLoader(true);
    http
      .delete(
        `/disconnectCarrier/${aesUtil.encrypt(JSON.stringify(carrierId))}`,
        {}
      )
      .then(() => {
        if (carrierdetails.tab === "All") {
          getAllCarriers();
        } else {
          connectedCarriers();
        }
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const carrierdetailsOnChangeHandle = (event) => {
    let { name, value } = event.target;
    if (value === "All") {
      sessionStorage.removeItem("tab");
      getAllCarriers();
    } else if (value === "Connected") {
      sessionStorage.removeItem("tab");
      connectedCarriers();
    } else if (value === "Not Connected") {
      sessionStorage.removeItem("tab");
      notConnectCarrier();
    } else if (name === "state") {
      const items = carrierdetails.stateId;
      const index = items.indexOf(+value);

      if (index !== -1) {
        items.splice(index, 1);
      } else {
        items.push(+value);
      }
      setcarrierdetails({
        ...carrierdetails,
        stateId: items,
      });
    }
    if (name !== "state") {
      setcarrierdetails({ ...carrierdetails, [name]: value });
    }
  };

  const connectedCarriers = () => {
    setIsLoader(true);

    http
      .get(`/connected`, {})
      .then((res) => {
        setcarrierdetails((prevCarriers) => ({
          ...prevCarriers,
          carrierLists: res.data,
        }));
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  const notConnectCarrier = () => {
    setIsLoader(true);
    http
      .get(`/notConnected`, {})
      .then((res) => {
        setcarrierdetails((prevCarriers) => ({
          ...prevCarriers,
          carrierLists: res.data,
        }));
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  const getAllCarriers = () => {
    console.log(carrierdetails.tab, "T");
    setIsLoader(true);
    http
      .get(`/getAllCarrier`, {})
      .then((res) => {
        setIsLoader(false);
        setcarrierdetails((prevCarriers) => ({
          ...prevCarriers,
          carrierLists: res.data,
        }));
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  useEffect(() => {
    if (carrierdetails.tab === "Connected") {
      connectedCarriers();
    } else {
      getAllCarriers();
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutAgent sideBarActive={23}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer carriersAgentPage">
        <div className="head_bread">
          <h1>Carriers</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}>
              Dashboard
            </Link>
            <span>/ Carriers</span>
          </div>
        </div>
        <div className="Container_body">
          <div className="twoPart">
            <div className="left">
              <div className="orangeActiveDeactive">
                <fieldset className="checkbox-group twoOptions">
                  {allTabs.map((tab, ind) => (
                    <div className="checkbox" key={ind}>
                      <label className="checkbox-wrapper">
                        <input
                          type="radio"
                          className="checkbox-input"
                          name="tab"
                          value={tab}
                          checked={tab === carrierdetails.tab}
                          onChange={(event) =>
                            carrierdetailsOnChangeHandle(event)
                          }
                        />
                        <span className="checkbox-tile">
                          <span className="checkbox-label">{tab}</span>
                        </span>
                      </label>
                    </div>
                  ))}
                </fieldset>
              </div>
            </div>
            {/* <div className="right">
              <Button className="button_grayBorder" onClick={handleShow2}>
                <img
                  src={IMAGE_PATHS.priority_icon}
                  loading="lazy"
                  alt="Logo"
                  width={"23px"}
                />
                Priority Carrier List
              </Button>
            </div> */}
          </div>
          <div className="carriers_item">
            <ul>
              {carrierdetails.carrierLists.length ? (
                carrierdetails.carrierLists.map(({ carrier, isConnect }) => (
                  <li key={carrier.carrierId}>
                    <div className="carriers_item_switch active">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={isConnect}
                          onChange={
                            isConnect === 0
                              ? () => handleShow(carrier.carrierId, isConnect)
                              : () =>
                                  disconnectOnChangeHandle(carrier.carrierId)
                          }
                        />
                      </div>
                    </div>
                    <div className="image_container">
                      <img
                        src={carrier.carrierLogo}
                        loading="lazy"
                        alt="Logo"
                        width="100%"
                      />
                    </div>
                    <div className="carriers_item_name">
                      {carrier.carrierName}
                    </div>
                  </li>
                ))
              ) : (
                <div
                  className="white_data_box2"
                  style={{ textAlign: "center", width: "352px" }}
                >
                  No Data Found
                </div>
              )}
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Body>
                  <div className="modal_body">
                    <div className="row">
                      <div className="twoPartCommon align-items-center mb-4">
                        <div className="left heading02">Select states</div>
                        <div className="left">
                          <img
                            src={IMAGE_PATHS.modal_normal_close}
                            loading="lazy"
                            alt="Logo"
                            width={"15px"}
                            onClick={() => handleClose()}
                            className="cursorPointer"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="SelectedStateRadio SelectedState mt-2">
                        <div className="row">
                          <fieldset className="checkbox-group">
                            {carrierdetails.allState.length ? (
                              carrierdetails.allState.map(
                                ({ stateId, stateCode }) => (
                                  <div className="checkbox" key={stateId}>
                                    <label className="checkbox-wrapper">
                                      <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        name="state"
                                        value={stateId}
                                        checked={carrierdetails.stateId.includes(
                                          stateId
                                        )}
                                        onChange={(event) =>
                                          carrierdetailsOnChangeHandle(event)
                                        }
                                      />
                                      <span className="checkbox-tile">
                                        <span className="checkbox-label">
                                          {stateCode}
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                )
                              )
                            ) : (
                              <div>No Data Found</div>
                            )}
                          </fieldset>
                        </div>
                      </div>
                    </div>

                    <div className="buttn_row mt_20">
                      <Button
                        variant="primary"
                        type="button"
                        className="login sign_in_button"
                        onClick={() => stateOnClickHandle()}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </ul>
          </div>
        </div>
      </div>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="priorityClear_list_modal"
      >
        <div className="modal_body">
          <div className="row">
            <div className="twoPartCommon align-items-center mb-4">
              <div className="left heading02">Select states</div>
              <div className="left">
                <img
                  src={IMAGE_PATHS.modal_normal_close}
                  loading="lazy"
                  alt="Logo"
                  width={"15px"}
                  onClick={handleClose2}
                  className="cursorPointer"
                />
              </div>
            </div>
          </div>
          <Modal.Body>
            <div className="box twoPartCommon">
              <div>
                <img
                  src={IMAGE_PATHS.logo_01}
                  loading="lazy"
                  alt="Logo"
                  height={"43px"}
                />
              </div>
              <div>
                <img
                  src={IMAGE_PATHS.up_down_icon}
                  loading="lazy"
                  alt="Logo"
                  width={"19px"}
                />
              </div>
            </div>
            <div className="box twoPartCommon">
              <div>
                <img
                  src={IMAGE_PATHS.logo_02}
                  loading="lazy"
                  alt="Logo"
                  height={"43px"}
                />
              </div>
              <div>
                <img src={IMAGE_PATHS.up_down_icon} loading="lazy" alt="Logo" />
              </div>
            </div>
            <div className="box twoPartCommon">
              <div>
                <img
                  src={IMAGE_PATHS.logo_03}
                  loading="lazy"
                  alt="Logo"
                  height={"43px"}
                />
              </div>
              <div>
                <img src={IMAGE_PATHS.up_down_icon} loading="lazy" alt="Logo" />
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </LayoutAgent>
  );
};

export default CarriersAgent;
