import IMAGE_PATHS from "../../constants";

const editDeleteIcon = [
  {
    editIcon: IMAGE_PATHS.EditIconGray,
  },
  {
    deleteIcon: IMAGE_PATHS.DeleteIconGray,
  },
  {
    shortIcon: IMAGE_PATHS.ShortIconGray,
  },
];
export default editDeleteIcon;
