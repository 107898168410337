import React, { useEffect, useState } from "react";
import LayoutMain from "../UI/LayoutMain/LayoutMain";
import Accordion from "react-bootstrap/Accordion";
import { MainPostCalls } from "../../../ApiClient/PostCall";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";

const FAQMain = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [faqDetails, setfaqDetails] = useState({
    allFAQ: [],
    selectedTab: "Subscription",
  });
  const { http } = ApiClient();
  const getAllCarrier = async () => {
    let data = await MainPostCalls.getAllFAQ(http, setIsLoader, Swal);

    setfaqDetails((prevDetails) => ({
      ...prevDetails,
      allFAQ: data,
    }));
  };
  useEffect(() => {
    getAllCarrier();

    return () => {};
  }, []);

  return (
    <LayoutMain sideBarActive={6}>
      {isLoader && <Loader />}
      <div className="FAQMain_outer">
        <div className="FAQMain_topBg"></div>
        <div className="FAQMain_contentPart">
          <div className="container">
            <h3>FAQ</h3>
            <div className="FAQ_content">
              <h4>Select a topic from below</h4>
              <div className="grayActiveDeactive">
                <fieldset className="checkbox-group twoOptions">
                  {faqDetails.allFAQ.length ? (
                    faqDetails.allFAQ.map(({ topic }, indx) => (
                      <div className="checkbox" key={indx}>
                        <label className="checkbox-wrapper">
                          <input
                            type="radio"
                            className="checkbox-input"
                            name="isActive"
                            defaultChecked={topic === faqDetails.selectedTab}
                            onChange={() =>
                              setfaqDetails({
                                ...faqDetails,
                                selectedTab: topic,
                              })
                            }
                          />
                          <span className="checkbox-tile">
                            <span className="checkbox-label">{topic}</span>
                          </span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <div>No Data Found</div>
                  )}
                </fieldset>
              </div>
              <div className="FAQMainAccordionContent">
                <Accordion>
                  {faqDetails.allFAQ.length ? (
                    faqDetails.allFAQ.map(({ topic, questionAnswers }) =>
                      topic === faqDetails.selectedTab
                        ? questionAnswers.length
                          ? questionAnswers.map(
                              ({ question, answer }, indy) => (
                                <Accordion.Item eventKey={question} key={indy}>
                                  <Accordion.Header>
                                    {question}
                                  </Accordion.Header>
                                  <Accordion.Body>{answer}</Accordion.Body>
                                </Accordion.Item>
                              )
                            )
                          : null
                        : null
                    )
                  ) : (
                    <div>No Data Found</div>
                  )}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default FAQMain;
