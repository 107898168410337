import React, { Fragment } from "react";
import HeaderMain from "../HeaderMain/HeaderMain";
import FooterMain from "../FooterMain/FooterMain";

const LayoutMain = (props) => {
  return (
    <div className="container-fluid">
      <Fragment>
        <HeaderMain sideBarActive={props.sideBarActive} />
        {props.children}
        <FooterMain />
      </Fragment>
    </div>
  );
};

export default LayoutMain;
