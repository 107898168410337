import React, { Fragment } from "react";
import HeaderAgent from "../HeaderAgent/HeaderAgent";
import LeftMenuAgent from "../LeftMenuAgent/LeftMenuAgent";

const LayoutAgent = (props) => {
  return (
    <Fragment>
      <div className="container-fluid AdminContainer">
        <div className="left">
          <LeftMenuAgent sideBarActive={props.sideBarActive} />
        </div>
        <div className="right">
          <HeaderAgent />
          {props.children}
        </div>
      </div>
    </Fragment>
  );
};

export default LayoutAgent;
