import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import IMAGE_PATHS from "../../constants";
import { Button } from "react-bootstrap";
import ApiClient from "../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";
import { isLogin } from "../IsLogin/IsLogin";
import { useNavigate } from "react-router-dom";

const StateModal = ({
  stateShow,
  setStateShow,
  stateModalOnClickHandle,
  stateModalOnChangeHandle,
  selectedState,
}) => {
  const [allStates, setallStates] = useState([]);
  const [isLoader, setisLoader] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setStateShow(false);
  };

  const { http } = ApiClient();

  const getAllState = () => {
    setisLoader(true);
    http
      .get("/allState", {})
      .then((res) => {
        setallStates(res.data);

        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };
  useEffect(() => {
    getAllState();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <Modal
      show={stateShow}
      onHide={handleClose}
      className="AddNewCategoryModal"
      keyboard={false}
    >
      <Modal.Body>
        <div className="modal_body">
          <div className="row">
            <div className="twoPartCommon align-items-center mb-3">
              <div className="left"></div>
              <div className="right">
                <img
                  src={IMAGE_PATHS.Close_modal}
                  loading="lazy"
                  alt="Logo"
                  width={"31px"}
                  onClick={handleClose}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="twoPartCommon align-items-center">
              <div className="left">Add states</div>
              <div className="left">
                <Button className="button_blueBorder_small">Select All</Button>
              </div>
            </div>
          </div>

          <div className="row">
            {isLoader && <Loader />}

            <div className="SelectedStateRadio SelectedState mt-2">
              <div className="row">
                <fieldset className="checkbox-group">
                  {allStates.length &&
                    allStates.map(({ stateId, stateCode }) => (
                      <div className="checkbox" key={stateId}>
                        <label className="checkbox-wrapper">
                          <input
                            id={stateId}
                            type="checkbox"
                            className="checkbox-input"
                            name="state"
                            value={stateCode}
                            onChange={(event) =>
                              stateModalOnChangeHandle(event)
                            }
                            checked={selectedState === stateId}
                          />
                          <span className="checkbox-tile">
                            <span className="checkbox-label">{stateCode}</span>
                          </span>
                        </label>
                      </div>
                    ))}
                </fieldset>
              </div>
            </div>
          </div>

          <div className="buttn_row mt_20">
            <Button
              variant="primary"
              type="submit"
              className="login sign_in_button"
              onClick={() => stateModalOnClickHandle()}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StateModal;
