import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { useNavigate } from "react-router-dom";
import ShowActivePage from "../../../components/ShowActivePage/ShowActivePage";
import ApiClient from "../../../ApiClient/ApiClient";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";

const AgentDbSuccess = () => {
  const navigate = useNavigate();
  const [isLoader, setisLoader] = useState(false);
  let { http } = ApiClient();
  const savePaymentDetails = async () => {
    try {
      setisLoader(true);

      await http.post(
        "/savePayementDetails",
        JSON.parse(localStorage.getItem("paymentData"))
      );
      setisLoader(false);
    } catch (error) {
      setisLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };
  useEffect(() => {
    savePaymentDetails();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div className="loginBoxOuter">
      {isLoader && <Loader />}
      <div className="container-fluid container_outer_02">
        <div className="container">
          <div className="AgentUpdateProfile_top">
            <img
              src={IMAGE_PATHS.risker_logo_white}
              loading="lazy"
              alt="Logo"
              width={"151px"}
            />
          </div>
          <div className="AgentUpdateProfile_bottom">
            <ShowActivePage pageValue={"AgentDbSuccess"} />
            <div className="right">
              <div className="top"></div>
              <div className="middle">
                <div className="formContainer">
                  <div className="login_top">
                    <h3>&nbsp;</h3>
                  </div>
                  <div className="SuccessBlueTicIcon_outer">
                    <div className="SuccessBlueTicIcon">
                      <img
                        src={IMAGE_PATHS.SuccessBlueTicIcon}
                        loading="lazy"
                        alt="Logo"
                        width={"31px"}
                      />
                      <p>Payment successfully processed!</p>
                      <div className="row w-100">
                        <div className="buttn_row mt_20">
                          <Button
                            variant="primary"
                            type="submit"
                            className="login sign_in_button"
                            onClick={() =>
                              navigate(
                                `${process.env.REACT_APP_BASE_URL}/AgentDashboard`
                              )
                            }
                          >
                            Go to Dashboard
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentDbSuccess;
