const downloadCsv = (base64Data) => {
  // Step 1: Decode Base64 Data
  const decodedData = atob(base64Data);

  // Step 2: Create CSV Blob
  const blob = new Blob([decodedData], { type: "text/csv" });

  // Step 3: Create Download Link
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "month_wise_product.csv";

  // Trigger download
  document.body.appendChild(a);
  a.click();

  // Cleanup
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
export { downloadCsv };
