import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import IMAGE_PATHS from "../../constants";
import ApiClient from "../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  LabelList,
  CartesianGrid,
} from "recharts";
import {
  calculateTotalWithPercentage,
  getAllChartUsers,
  getMonthAbbreviation,
} from "../../components/ChartsUtils/ChartsUtils";
import { isLogin } from "../../components/IsLogin/IsLogin";
import moment from "moment";
import { PostCallsAdmin } from "../../ApiClient/PostCall";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [recentStates, setRecentStates] = useState([]);

  const [reportLists, setReportLists] = useState({
    monthWise: [],
    productWise: [],
    userWise: [],
    users: [],
    paymentsWise: [],
  });

  const [isLoader, setIsLoader] = useState(false);
  const [stateShow, setStateShow] = useState(false);
  const [searchdetails, setsearchdetails] = useState({
    customerName: "",
    allProducts: [],
    allCountData: {},
    state: {},
    product: {},
    stateId: [],
    allStates: [],
  });
  const { http } = ApiClient();

  const handleStateShow = () => {
    getAllState();
    getAllStatesWithRecent();
    setStateShow(true);
  };
  const handleStateClose = () => {
    setStateShow(false);
  };

  const handleCarrierSearchOnChange = (event) => {
    let { name, value, id } = event.target;
    if (name === "customerName") {
      setsearchdetails({ ...searchdetails, [name]: value });
    }
    if (name === "state") {
      setsearchdetails({
        ...searchdetails,
        state: { stateName: value, stateId: +id },
      });
    }
    if (name === "product") {
      setsearchdetails({
        ...searchdetails,
        product: { proName: value, proId: +event.target.selectedOptions[0].id },
      });
    }
  };

  // const selectAllStateHandleOnClick = () => {
  //   const arrayOfIds = searchdetails.allStates.map((state) => state.stateId);

  //   const arrayOfState = searchdetails.allStates.map(
  //     (state) => state.stateCode
  //   );
  //   setsearchdetails({
  //     ...searchdetails,
  //     stateId: arrayOfIds,
  //     state: arrayOfState,
  //   });
  // };

  const handleSearchCarrierButtonOnClick = () => {
    if (
      !searchdetails.customerName ||
      !searchdetails.product.proName ||
      !searchdetails.state.stateName
    ) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }
    let agentDashboardSearchDetails = {
      customerName: searchdetails.customerName,
      product: searchdetails.product,
      state: searchdetails.state,
    };
    sessionStorage.setItem(
      "agentDashboardSearchDetails",
      JSON.stringify(agentDashboardSearchDetails)
    );

    navigate(`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboardSearch`);
  };

  const stateModalOnClickHandle = () => {
    setStateShow(false);
  };

  const getAllState = async () => {
    try {
      setIsLoader(true);
      let res = await http.get("/allState");
      setsearchdetails({
        ...searchdetails,
        allStates: res.data,
        state: [],
        stateId: [],
      });
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };

  const getAllStatesWithRecent = () => {
    setIsLoader(true);
    http
      .get("/getAllSearchState", {})
      .then((res) => {
        if (res.status === 200) {
          setRecentStates(res.data);
        }
      })
      .catch((error) => {
        isLogin(error, navigate, Swal);
      })
      .finally(() => setIsLoader(false));
  };

  const getAllAllData = async () => {
    setIsLoader(true);
    http
      .get("/getNumberofAgentsSubscribersCarriersSearch")
      .then((res) => {
        setsearchdetails((prevSearchDetails) => ({
          ...prevSearchDetails,
          allCountData: res.data,
        }));

        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  // chart showing start

  const getMonthWiseSearch = async () => {
    try {
      setIsLoader(true);
      const res = await http.post(`/searchAdminByMonth`);

      setReportLists((prevLists) => ({
        ...prevLists,
        monthWise: res.data,
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };
  const getProductWiseSearch = async () => {
    try {
      setIsLoader(true);
      const res = await http.post(`/searchAdminByProduct`);
      setReportLists((prevLists) => ({
        ...prevLists,
        productWise: res.data,
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };
  const getPaymetsBySearch = async () => {
    try {
      setIsLoader(true);
      const res = await http.get(`/agentPaymentByMonthAdmin`);
      setReportLists((prevLists) => ({
        ...prevLists,
        paymentsWise: res.data,
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };
  const getMonthWiseUserSearch = async () => {
    try {
      setIsLoader(true);
      const res = await http.post(`/searchAllUserAdminByMonthWise`);
      setReportLists((prevLists) => ({
        ...prevLists,
        userWise: calculateTotalWithPercentage(res.data),
        users: getAllChartUsers(res.data),
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };

  // chart showing end

  const getAllProduct = async () => {
    let data = await PostCallsAdmin.getAllConnectedProducts(
      http,
      setIsLoader,
      navigate,
      Swal
    );

    setsearchdetails((prevViewAgencyDetails) => ({
      ...prevViewAgencyDetails,
      allProducts: data,
    }));
  };
  const getLastSearch = async () => {
    try {
      setIsLoader(true);
      let res = await http.get("/getLastSearchDetails");
      setsearchdetails((prevLists) => ({
        ...prevLists,
        // customerName: res.data[0]?.searchEntity?.customerName,
        state: {
          stateName: res.data[0]?.masterState?.stateCode,
          stateId: res.data[0]?.masterState?.stateId,
        },
        product: {
          proName: res.data[0]?.searchEntity?.productBuilder?.productName,
          proId: res.data[0]?.searchEntity?.productBuilder?.productId,
        },
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getMonthWiseSearch();
    getProductWiseSearch();
    getPaymetsBySearch();
    getMonthWiseUserSearch();
    getAllAllData();
    getAllProduct();
    getLastSearch();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={1}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer">
        <div className="searchBar">
          <Form>
            <ul>
              <li>Searching for Carriers</li>
              <li>
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Customer Name"
                    value={searchdetails.customerName || ""}
                    name="customerName"
                    onChange={(event) => handleCarrierSearchOnChange(event)}
                  />
                </Form.Group>
              </li>
              <li>
                <Form.Select
                  aria-label="Default select example"
                  name="product"
                  onChange={(event) => handleCarrierSearchOnChange(event)}
                >
                  <option hidden>
                    {searchdetails?.product?.proName || "Select Product"}
                  </option>
                  {searchdetails.allProducts?.length > 0 ? (
                    searchdetails.allProducts.map(
                      ({ productId, productName }) => (
                        <option
                          key={productId}
                          value={productName}
                          id={productId}
                        >
                          {productName}
                        </option>
                      )
                    )
                  ) : (
                    <option>No Data Found</option>
                  )}
                </Form.Select>
              </li>

              <li>
                <Form.Select
                  aria-label="Default select example"
                  name="state"
                  onClick={handleStateShow}
                >
                  <option hidden>
                    {searchdetails?.state?.stateName
                      ? searchdetails?.state?.stateName
                      : "Select State"}
                  </option>
                </Form.Select>
              </li>
              <li>
                <Button
                  className="searchButton"
                  onClick={() => handleSearchCarrierButtonOnClick()}
                >
                  Next
                </Button>
              </li>
            </ul>
          </Form>
        </div>
        {/* <Modal
          show={stateShow}
          onHide={handleStateClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="row">
                <div className="twoPartCommon align-items-center mb-3">
                  <div className="left"></div>
                  <div className="right">
                    <img
                      src={IMAGE_PATHS.Close_modal}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      onClick={handleStateClose}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="twoPartCommon align-items-center">
                  <div className="left">Add states</div>
                  <div className="left">
                    <Button
                      className="button_blueBorder_small"
                      onClick={() => selectAllStateHandleOnClick()}
                    >
                      Select All
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row">
                {isLoader && <Loader />}

                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group">
                      {searchdetails.allStates.length ? (
                        searchdetails.allStates.map(
                          ({ stateId, stateCode }) => (
                            <div className="checkbox" key={stateId}>
                              <label className="checkbox-wrapper">
                                <input
                                  id={stateId}
                                  type="checkbox"
                                  className="checkbox-input"
                                  name="state"
                                  value={stateCode}
                                  onChange={(event) =>
                                    handleCarrierSearchOnChange(event)
                                  }
                                  checked={searchdetails.stateId.includes(
                                    stateId
                                  )}
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">
                                    {stateCode}
                                  </span>
                                </span>
                              </label>
                            </div>
                          )
                        )
                      ) : (
                        <div>No Data Found</div>
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="submit"
                  className="login sign_in_button"
                  onClick={() => stateModalOnClickHandle()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal> */}
        <div className="head_bread mt-4 mb-3">
          <div className="ReportDate">
            <h1>Dashboard</h1>
          </div>
        </div>
        <div className="AdminDashboardFourBox">
          <ul>
            <li
              className="whiteBoxShadow"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${process.env.REACT_APP_BASE_URL}/Admin/UserManagement`
                );
              }}
            >
              <div className="twoPartCommon">
                <div>
                  <ul className="AdminDashboardFourUL">
                    <li className="AdminDashboardFourBoxHead">
                      Number of Agents
                    </li>
                    <li className="AdminDashboardFourBoxNumber">
                      {Object.keys(searchdetails.allCountData).length > 0
                        ? searchdetails.allCountData.agents
                        : 0}
                    </li>
                  </ul>
                </div>
                <div>
                  <img
                    src={IMAGE_PATHS.na_icon_01}
                    loading="lazy"
                    alt="Logo"
                    width={"60px"}
                  />
                </div>
              </div>
            </li>
            <li
              className="whiteBoxShadow"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`${process.env.REACT_APP_BASE_URL}/Admin/Payments`);
              }}
            >
              <div className="twoPartCommon">
                <div>
                  <ul className="AdminDashboardFourUL">
                    <li className="AdminDashboardFourBoxHead">
                      Active Subscribers
                    </li>
                    <li className="AdminDashboardFourBoxNumber">
                      {Object.keys(searchdetails.allCountData).length > 0
                        ? searchdetails.allCountData.activeSubscribers
                        : 0}
                    </li>
                  </ul>
                </div>
                <div>
                  <img
                    src={IMAGE_PATHS.as_icon_02}
                    loading="lazy"
                    alt="Logo"
                    width={"60px"}
                  />
                </div>
              </div>
            </li>
            <li
              className="whiteBoxShadow"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`${process.env.REACT_APP_BASE_URL}/Admin/Carriers`);
              }}
            >
              <div className="twoPartCommon">
                <div>
                  <ul className="AdminDashboardFourUL">
                    <li className="AdminDashboardFourBoxHead">
                      Added Carriers
                    </li>
                    <li className="AdminDashboardFourBoxNumber">
                      {Object.keys(searchdetails.allCountData).length > 0
                        ? searchdetails.allCountData.addedCarriers
                        : 0}
                    </li>
                  </ul>
                </div>
                <div>
                  <img
                    src={IMAGE_PATHS.ac_icon_03}
                    loading="lazy"
                    alt="Logo"
                    width={"60px"}
                  />
                </div>
              </div>
            </li>
            <li
              className="whiteBoxShadow"
              style={{ cursor: "pointer" }}
              onClick={() => {
                sessionStorage.setItem(
                  "todaySearch",
                  JSON.stringify({
                    date: [
                      moment().format("YYYY-MM-DD"),
                      moment().format("YYYY-MM-DD"),
                    ],
                  })
                );

                navigate(
                  `${process.env.REACT_APP_BASE_URL}/Admin/SearchHistory`
                );
              }}
            >
              <div className="twoPartCommon">
                <div>
                  <ul className="AdminDashboardFourUL">
                    <li className="AdminDashboardFourBoxHead">
                      Today's Search
                    </li>
                    <li className="AdminDashboardFourBoxNumber">
                      {Object.keys(searchdetails.allCountData).length > 0
                        ? searchdetails.allCountData.todaySearch
                        : 0}
                    </li>
                  </ul>
                </div>
                <div>
                  <img
                    src={IMAGE_PATHS.ts_icon_04}
                    loading="lazy"
                    alt="Logo"
                    width={"60px"}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="AdminDashboardThreeBox">
          <div className="whiteBoxShadow">
            <h3>Month Wise Payments</h3>
            {reportLists.paymentsWise.length ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={reportLists.paymentsWise}
                  // barSize={20}
                >
                  <XAxis
                    dataKey="month"
                    interval={"preserveStartEnd"}
                    style={{
                      fontSize: "10px",
                      // fontWeight: "bold",
                    }}
                    tickFormatter={getMonthAbbreviation}
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontSize: "10px",
                      // fontWeight: "bold",
                    }}
                    domain={[0, reportLists.paymentsWise.length + 8]}
                  />
                  {/* <Tooltip content={<CutomizedToolTip />} /> */}
                  {/* <Legend /> */}
                  <CartesianGrid vertical={false} />

                  <Bar
                    dataKey="count"
                    fill="#82ACD3"
                    // name="Month"
                    // legendType="rect"
                    maxBarSize={20}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
          <div className="whiteBoxShadow">
            <h3>Month Wise Search</h3>
            {reportLists.monthWise.length ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={reportLists.monthWise}
                  // barSize={20}
                >
                  <XAxis
                    dataKey="month"
                    interval={"preserveStartEnd"}
                    style={{
                      fontSize: "10px",
                      // fontWeight: "bold",
                    }}
                    tickFormatter={getMonthAbbreviation}
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontSize: "10px",
                      // fontWeight: "bold",
                    }}
                    domain={[0, reportLists.monthWise.length + 8]}
                  />
                  {/* <Tooltip content={<CutomizedToolTip />} /> */}
                  {/* <Legend /> */}
                  <CartesianGrid vertical={false} />

                  <Bar
                    dataKey="count"
                    fill="#82ACD3"
                    // name="Month"
                    // legendType="rect"
                    maxBarSize={20}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
          <div className="whiteBoxShadow">
            <h3>Product Wise Search</h3>
            {reportLists.productWise.length ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={reportLists.productWise}
                  // barSize={20}
                >
                  <XAxis
                    dataKey="productName"
                    interval={"preserveStartEnd"}
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontSize: "10px",
                      // fontWeight: "bold",
                    }}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontSize: "10px",
                    }}
                    domain={[0, reportLists.productWise.length + 8]}
                  />
                  <CartesianGrid vertical={false} />

                  <Bar
                    dataKey="count"
                    fill="#8884d8"
                    // name="Product"
                    // legendType="rect"
                  >
                    {reportLists.productWise.map((entry, ind) => (
                      <Cell fill={entry.color} key={ind} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
        </div>
        <div className="whiteBoxShadow mt-3">
          <h3>Month Wise User Search</h3>
          {/* <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/MonthlySwarchHistory`}> */}
          {reportLists.userWise.length ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={reportLists.userWise} layout="vertical">
                <XAxis
                  type="number"
                  interval={"preserveStartEnd"}
                  style={{
                    fontSize: "10px",
                  }}
                  axisLine={false}
                  tickLine={false}
                  domain={[0, reportLists.userWise.length + 8]}
                />
                <YAxis
                  dataKey="month"
                  type="category"
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={getMonthAbbreviation}
                  style={{
                    fontSize: "10px",
                  }}
                />
                {reportLists.users.map((user) => (
                  <Bar
                    key={user}
                    dataKey={user}
                    stackId="a"
                    fill={`#${Math.floor(Math.random() * 16777215).toString(
                      16
                    )}`}
                  >
                    <LabelList
                      dataKey={user}
                      position="middle"
                      style={{ fill: "white", fontSize: "10" }}
                      formatter={(value) => `${value}%`}
                    />
                  </Bar>
                ))}
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div>No Data Found</div>
          )}
          {/* </Link> */}
        </div>
        <Modal
          show={stateShow}
          onHide={handleStateClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body newStateModal">
              <div className="row">
                <div className="twoPartCommon align-items-center mb-3">
                  <div className="left" style={{ fontWeight: 600 }}>
                    Change States
                  </div>
                  <div className="right">
                    <img
                      src={IMAGE_PATHS.Close_modal}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      onClick={handleStateClose}
                      className="cursorPointer"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {isLoader && <Loader />}
                {recentStates?.length ? (
                  <div>Recent search states</div>
                ) : (
                  <div>No Data Found</div>
                )}
                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group newStateModalOne">
                      {recentStates?.map(({ stateId, stateCode }) => (
                        <div className="checkbox" key={stateId}>
                          <label className="checkbox-wrapper">
                            <input
                              type="radio"
                              className="checkbox-input"
                              name="state"
                              id={stateId}
                              value={stateCode}
                              onChange={(event) =>
                                handleCarrierSearchOnChange(event)
                              }
                            />
                            <span className="checkbox-tile">
                              <span className="checkbox-label">
                                {stateCode}
                              </span>
                            </span>
                          </label>
                        </div>
                      ))}
                    </fieldset>
                  </div>
                </div>
                <div>Other states</div>

                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group newStateModalOne">
                      {searchdetails.allStates.length ? (
                        searchdetails.allStates.map(
                          ({ stateId, stateCode }) => (
                            <div
                              className="checkbox"
                              key={stateId}
                              style={{
                                display: recentStates?.find((obj) => {
                                  return obj?.stateCode === stateCode;
                                })
                                  ? "none"
                                  : "block",
                              }}
                            >
                              <label className="checkbox-wrapper">
                                <input
                                  type="radio"
                                  className="checkbox-input"
                                  name="state"
                                  id={stateId}
                                  value={stateCode}
                                  onChange={(event) =>
                                    handleCarrierSearchOnChange(event)
                                  }
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">
                                    {stateCode}
                                  </span>
                                </span>
                              </label>
                            </div>
                          )
                        )
                      ) : (
                        <div>No Data Found</div>
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="submit"
                  className="login sign_in_button"
                  onClick={() => stateModalOnClickHandle()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
