import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/css/style.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Agent/Login/Login";
import SignUp from "./pages/Agent/SignUp/SignUp";
import AgentUpdateProfile from "./pages/AgentUpdateProfile/AgentUpdateProfile";
import SelectYourPlan from "./pages/Agent/SelectYourPlan/SelectYourPlan";
import SelectPaymentType from "./pages/Agent/SelectPaymentType/SelectPaymentType";
import SaveCard from "./pages/Agent/SaveCard/SaveCard";
import SaveBankAccount from "./pages/Agent/SaveBankAccount/SaveBankAccount";
import AgentDbSuccess from "./pages/Agent/AgentDbSuccess/AgentDbSuccess";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import Products from "./pages/AdminDashboard/Products/Products";
import HomePrpducts from "./pages/AdminDashboard/Products/HomePrpducts";

import AdminLogin from "./pages/Admin/Login/AdminLogin";
import AgentDashoard from "./pages/AgentDashboard/AgentDashoard";
import Carriers from "./pages/AdminDashboard/Carriers/Carriers";
import CarriersDetails from "./pages/AdminDashboard/Carriers/CarriersDetails";
import UnderwritingGuidelines from "./pages/AdminDashboard/Carriers/UnderwritingGuidelines";
import AddNnewcarrierPage from "./pages/AdminDashboard/Carriers/AddNnewcarrierPage";
import UnderGuide from "./pages/AdminDashboard/Carriers/UnderGuide";
import AddNewCarrierFinish from "./pages/AdminDashboard/Carriers/AddNewCarrierFinish";
import ManageSubscription from "./pages/AdminDashboard/ManageSubscription/ManageSubscription";
import Agency from "./pages/AdminDashboard/Agency/Agency";
import ViewAgency from "./pages/AdminDashboard/Agency/ViewAgency";
import SearchHistory from "./pages/AdminDashboard/SearchHistory/SearchHistory";
import Report from "./pages/AdminDashboard/Report/Report";
import MonthSearchHistory from "./pages/AdminDashboard/Report/MonthSearchHistory";
import Payments from "./pages/AdminDashboard/Payments/Payments";
import UserManagement from "./pages/AdminDashboard/UserManagement/UserManagement";
import AgentDashboardSearch from "./pages/AgentDashboard/AgentDashboardSearch/AgentDashboardSearch";
import UserManagementAgent from "./pages/AgentDashboard/UserManagementAgent/UserManagementAgent";
import AddUserAgent from "./pages/AgentDashboard/UserManagementAgent/AddUserAgent";
import SubscriptionAgent from "./pages/AgentDashboard/UserManagementAgent/SubscriptionAgent";
import FinishAgent from "./pages/AgentDashboard/UserManagementAgent/FinishAgent";
import CarriersAgent from "./pages/AgentDashboard/CarriersAgent/CarriersAgent";
import ReportAgent from "./pages/AgentDashboard/ReportAgent/ReportAgent";
import ReportDetailsAgent from "./pages/AgentDashboard/ReportAgent/ReportDetailsAgent";
import BillingManagement from "./pages/AgentDashboard/BillingManagement/BillingManagement";
import BillingManagementEdit from "./pages/AgentDashboard/BillingManagement/BillingManagementEdit";
import SearchHistoryAgent from "./pages/AgentDashboard/SearchHistoryAgent/SearchHistoryAgent";
import ProfileSettingsAdmin from "./pages/AdminDashboard/ProfileSettingsAdmin/ProfileSettingsAdmin";
import ProfileSettingsAgent from "./pages/AgentDashboard/ProfileSettingsAgent/ProfileSettingsAgent";
import ResetPasswordAgent from "./pages/AgentDashboard/ProfileSettingsAgent/ResetPasswordAgent";
import ResetPasswordAdmin from "./pages/AdminDashboard/ProfileSettingsAdmin/ResetPasswordAdmin";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import SetPassword from "./pages/AgentDashboard/UserManagementAgent/SetPassword";
import AdminDashboardSearch from "./pages/AdminDashboard/AdminDashboardSearch/AdminDashboardSearch";
import DebitBalanace from "./components/DebitBalanace";
import Home from "./pages/Main/Home/Home";
import StartATrial from "./pages/Main/StartATrial/StartATrial";
import LoginMain from "./pages/Main/LoginMain/LoginMain";
import RegistrationMain from "./pages/Main/RegistrationMain/RegistrationMain";
import BookADemo from "./pages/Main/BookADemo/BookADemo";
import CarriersMain from "./pages/Main/CarriersMain/CarriersMain";
import AgentMain from "./pages/Main/AgentMain/AgentMain";
import AboutMain from "./pages/Main/AboutMain/AboutMain";
import ProductsMain from "./pages/Main/ProductsMain/ProductsMain";
import FAQMain from "./pages/Main/FAQMain/FAQMain";
import ContactMain from "./pages/Main/ContactMain/ContactMain";
import FAQTopics from "./pages/AdminDashboard/FAQTopics/FAQTopics";
import FAQDetails from "./pages/AdminDashboard/FAQDetails/FAQDetails";
import ContactUs from "./pages/AdminDashboard/ContactUs/ContactUs";
import Reviews from "./pages/AdminDashboard/Reviews/Reviews";
import ReviewsAgent from "./pages/AgentDashboard/ReviewsAgent/ReviewsAgent";
import Teams from "./pages/AdminDashboard/Teams/Teams";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {process.env.REACT_APP_ENV === "PROD" ||
          process.env.REACT_APP_ENV === "staging" ? (
            <>
              <Route
                exact
                path="/"
                element={<Navigate to={process.env.REACT_APP_BASE_URL} />}
              />
              <Route
                path={process.env.REACT_APP_BASE_URL_MAIN}
                element={<Home />}
              />
            </>
          ) : (
            <Route
              path={`${process.env.REACT_APP_BASE_URL_MAIN}`}
              element={<Home />}
            />
          )}

          <Route
            path={`${process.env.REACT_APP_BASE_URL}`}
            element={<Login />}
          />

          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin`}
            element={<AdminLogin />}
          />

          <Route
            path={`${process.env.REACT_APP_BASE_URL}/SignUp`}
            element={<SignUp />}
          />

          <Route
            path={`${process.env.REACT_APP_BASE_URL}/AgentUpdateProfile`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AgentUpdateProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/SelectYourPlan`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SelectYourPlan />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/SelectPaymentType`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SelectPaymentType />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/SaveCard`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SaveCard />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/SaveBankAccount`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SaveBankAccount />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/setPassword/:flag/:id`}
            element={
              // <PrivateRoute allowedRoles={["1"]}>
              <SetPassword />
              // </PrivateRoute>
            }
          />

          <Route
            path={`${process.env.REACT_APP_BASE_URL}/AgentDbSuccess`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AgentDbSuccess />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboardSearch`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <AdminDashboardSearch />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/Products`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Products />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/:productName/:productId`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <HomePrpducts />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/Carriers`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Carriers />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/CarriersDetails/:id`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <CarriersDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/CarriersDetails/:carrierId/UnderwritingGuidelines/:productId`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <UnderwritingGuidelines />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/AddNnewcarrier`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <AddNnewcarrierPage />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/UnderGuide`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <UnderGuide />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/AddNewCarrierFinish`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <AddNewCarrierFinish />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/ManageSubscription`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ManageSubscription />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/ManageSubscription`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ManageSubscription />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/Agency`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Agency />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/ViewAgency`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ViewAgency />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/SearchHistory`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <SearchHistory />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/Report`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Report />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/MonthSearchHistory`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <MonthSearchHistory />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/Payments`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Payments />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/UserManagement`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <UserManagement />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/FAQTopics`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <FAQTopics />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/FAQDetails`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <FAQDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/ContactUs`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ContactUs />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/Reviews`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Reviews />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/Admin/Teams`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <Teams />
              </PrivateRoute>
            }
          />

          <Route
            path={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AgentDashoard />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/AgentDashboardSearch`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AgentDashboardSearch />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/UserManagementAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <UserManagementAgent />
              </PrivateRoute>
            }
          />

          <Route
            path={`${process.env.REACT_APP_BASE_URL}/AddUserAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <AddUserAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/SubscriptionAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SubscriptionAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/FinishAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <FinishAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/CarriersAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <CarriersAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/ReportAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ReportAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/ReportDetailsAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ReportDetailsAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/BillingManagement`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <BillingManagement />
              </PrivateRoute>
            }
          />

          <Route
            path={`${process.env.REACT_APP_BASE_URL}/BillingManagementEdit`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <BillingManagementEdit />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/SearchHistoryAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <SearchHistoryAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/ReviewsAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ReviewsAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/ProfileSettingsAdmin`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ProfileSettingsAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/ProfileSettingsAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ProfileSettingsAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/ResetPasswordAgent`}
            element={
              <PrivateRoute allowedRoles={["1", "2"]}>
                <ResetPasswordAgent />
              </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/DebitBalance`}
            element={
              // <PrivateRoute allowedRoles={["1", "2"]}>
              <DebitBalanace />
              // </PrivateRoute>
            }
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL}/ResetPasswordAdmin`}
            element={
              <PrivateRoute allowedRoles={["3"]}>
                <ResetPasswordAdmin />
              </PrivateRoute>
            }
          />

          {/* <Route
            path={`${process.env.REACT_APP_BASE_URL}`}
            element={<Home />}
          /> */}
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/StartATrial`}
            element={<StartATrial />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/LoginMain`}
            element={<LoginMain />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/RegistrationMain`}
            element={<RegistrationMain />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/BookADemo`}
            element={<BookADemo />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/CarriersMain`}
            element={<CarriersMain />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/AgentMain`}
            element={<AgentMain />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/AboutMain`}
            element={<AboutMain />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/ProductsMain`}
            element={<ProductsMain />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/FAQMain`}
            element={<FAQMain />}
          />
          <Route
            path={`${process.env.REACT_APP_BASE_URL_MAIN}/ContactMain`}
            element={<ContactMain />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
