const getMonthAbbreviation = (value) => {
  switch (value) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "July";
    case 8:
      return "Aug";
    case 9:
      return "Sept";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    default:
      return "Dec";
  }
};

const calculateTotalWithPercentage = (data) => {
  // Calculate the total except for the "month" key
  const result = data.map((item) => {
    const total = Object.keys(item).reduce((acc, key) => {
      return key !== "month" ? acc + item[key] : acc;
    }, 0);

    // Calculate percentage for each user
    const newData = {};
    Object.keys(item).forEach((key) => {
      if (key !== "month" && key !== "total") {
        const percentage = (item[key] * 100) / total;
        newData[key] = percentage.toFixed(2); // Round to 2 decimal places
      } else {
        newData[key] = item[key];
      }
    });

    // Set the total value and percentages inside the array of objects
    return { ...item, total, ...newData };
  });

  return result;
};

const getAllChartUsers = (data) => {
  if (data.length) {
    const users = Array.from(
      new Set(
        data.flatMap((entry) =>
          Object.keys(entry).filter((key) => key !== "month")
        )
      )
    );
    return users;
  }
};

export { calculateTotalWithPercentage, getMonthAbbreviation, getAllChartUsers };
