import React, { Fragment, useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CarriersLeftMenu from "../../../components/CarriersLeftMenu/CarriersLeftMenu";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";
import { PolicyDownload } from "../../../components/PolicyDownload/Policydownload";
import { secondLastLocation } from "../../../Utils/Locations";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import moment from "moment";
import IMAGE_PATHS from "../../../constants";
import {
  ConvertedDependents,
  getData,
  setDependentCurrentYear,
} from "../../../components/ConvertedDependents/ConvertedDependents";
// changes from here

const UnderGuide = () => {
  const location = useLocation();
  const [carrierPolicy, setcarrierPolicy] = useState("");

  const [isLoader, setisLoader] = useState(false);
  const navigate = useNavigate();
  const [underguideLinedetails, setunderguideLinedetails] = useState({
    productBuilder: [],
    currentYearChecked: false,
    logoFile: "",
    logoFileTitle: "",
    stateIds: [],
    carrierPolicy: "",
    questionAnswer: [],
    carrierQuestionAnswer: { productBuilder: null, questinAnswerSatate: [] },
    allCarrier: location?.state?.allCarrier,
  });

  const [dependableDetails, setDependableDetails] = useState({});

  const { http } = ApiClient();

  const underGuideLineOnChangeHandleState = (id) => {
    const items = underguideLinedetails.stateIds;
    const index = items.indexOf(+id);

    if (index !== -1) {
      items.splice(index, 1);
    } else {
      items.push(+id);
    }
    setunderguideLinedetails({
      ...underguideLinedetails,
      stateIds: items,
    });
  };
  const underGuideLineOnChangeHandleLogo = (event) => {
    const file = event.target.files[0];
    let value = event.target.value.split("\\");

    if (file) {
      if (!file.type.includes("pdf")) {
        Swal.fire({
          icon: "error",
          // title: "Invalid File Type",
          text: "Please upload only PDF files.",
          confirmButtonText: "OK",
        });
        // Clear the file input
        event.target.value = null;
        return;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        setunderguideLinedetails({
          ...underguideLinedetails,
          carrierPolicy: reader.result,
          logoFileTitle: value[value.length - 1],
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const underGuideLineOnChangeHandle = (event, type) => {
    let { name, value } = event.target;

    if (
      !underguideLinedetails.carrierQuestionAnswer.questinAnswerSatate.length
    ) {
      if (type.masterFieldType.fieldType === "Option") {
        if (!underguideLinedetails[name]) {
          setunderguideLinedetails({
            ...underguideLinedetails,
            [name]: [value],
          });
        } else {
          let items = underguideLinedetails[name];
          let index = items.indexOf(value);
          if (index !== -1) {
            items.splice(index, 1);
          } else {
            items.push(value);
          }
          setunderguideLinedetails({
            ...underguideLinedetails,
            [name]: items,
          });
        }
      }

      if (type.masterFieldType.fieldType === "Text") {
        setunderguideLinedetails({
          ...underguideLinedetails,
          [name]: value,
        });
      }
    } else {
      const newUnderGuideLineDetails = { ...underguideLinedetails };
      type.forEach(({ optionType }) => {
        if (optionType === "Text") {
          newUnderGuideLineDetails[name] = value;
          newUnderGuideLineDetails[name] = value;
        }
      });
      setunderguideLinedetails(newUnderGuideLineDetails);
    }
  };

  // dependable code
  const handleDependableOnChange = (event) => {
    let { name } = event.target;

    dependableDetails[name].push({});
    setDependableDetails({
      ...dependableDetails,
    });
  };

  // Function to handle addition of select boxes
  const handleAddMoreDependent = (productQuestionEntity) => {
    console.log(productQuestionEntity, "E");
    let updatedDetails = dependableDetails[
      `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
    ].push({});

    console.log(updatedDetails, "D");

    setDependableDetails({
      ...dependableDetails,
    });
  };

  const dependentQuestionTextOptionOnChangeHandle = (
    event,
    details,
    type,
    ind
  ) => {
    let { value, name } = event.target;
    console.log(name, value, details, "N V D");
    let questionDetails = details;

    if (type === "min") {
      questionDetails.minValue = value;
    } else if (type === "max") {
      questionDetails.maxValue = value;
    } else {
      const updatedItems = questionDetails.optionValue?.map((elem) => {
        if (elem[value] !== undefined) {
          return {
            ...elem,
            [value]: elem[value] ? 0 : 1,
          };
        }
        return elem;
      });
      questionDetails.optionValue = updatedItems;
    }

    console.log(questionDetails, "Q");

    let updatedDependableDetails = [...dependableDetails[`${name}`]];
    updatedDependableDetails[ind] = questionDetails;

    setDependableDetails({
      ...dependableDetails,
      [name]: updatedDependableDetails,
    });
  };

  const handleDependableQuestionOnChange = async (event, questionId, ind) => {
    let { value } = event.target;
    let url = location.pathname;
    let urlArray = url.split("/");
    let productId = urlArray[urlArray.length - 1];
    let carrierId = urlArray[urlArray.length - 3];

    let value_id = value.split("_");
    let questionValue = `${value_id[0]}_${questionId}`;

    let payload = {
      carrierId: +aesUtil.decrypt(carrierId),
      productId: +aesUtil.decrypt(productId),
      questionId: +questionId,
      dependentQuestionId: +value_id[1],
      stateIds: underguideLinedetails.stateIds,
    };

    setisLoader(true);
    try {
      let response = await http.post(`/getDependentQuestionAnswer`, payload);

      let updatedDependableDetails = [
        ...dependableDetails[`${questionValue}1`],
      ];
      updatedDependableDetails[ind] = response.data;

      setDependableDetails({
        ...dependableDetails,
        [questionValue]: response.data,
        [`${questionValue}1`]: updatedDependableDetails,
      });
      setisLoader(false);
    } catch (error) {
      if (error?.response?.data?.isLogin) {
        setisLoader(false);
        isLogin(error, navigate, Swal);
        return;
      }
    }
  };
  console.log(dependableDetails, underguideLinedetails, "De[emd", "under");
  //dependable code

  const handleCheckboxOnChange = (event, productQuestionEntity, type) => {
    let maxValue = `${productQuestionEntity.questionLabelCode}2`;
    let { name, checked } = event.target;
    if (type === "dependent") {
      if (checked) {
        productQuestionEntity.maxValue = null;
        setDependableDetails({
          ...dependableDetails,
          [name]: 2,
        });
      } else {
        productQuestionEntity.maxValue = moment().year();

        setDependableDetails({
          ...dependableDetails,
          [name]: 0,
        });
      }
    } else {
      if (checked) {
        setunderguideLinedetails({
          ...underguideLinedetails,
          [name]: 2,
          [maxValue]: null,
        });
      } else {
        setunderguideLinedetails({
          ...underguideLinedetails,
          [name]: 0,
          [maxValue]: moment().year(),
        });
      }
    }
  };

  const underGuideLineOnChangeHandleForOption = (event) => {
    let { name, value } = event.target;
    const updatedItems = underguideLinedetails[name].map((elem) => {
      if (elem[value] !== undefined) {
        return {
          ...elem,
          [value]: elem[value] ? 0 : 1,
        };
      }

      return elem;
    });
    setunderguideLinedetails({
      ...underguideLinedetails,
      [name]: updatedItems,
    });
  };

  const underGuideLineButtonOnClickHandle = () => {
    let url = location.pathname;
    let urlArray = url.split("/");
    let productId = aesUtil.decrypt(urlArray[urlArray.length - 1]);
    // let carrierId = aesUtil.decrypt(urlArray[urlArray.length - 3]);

    //hello
    const selectedProduct =
      underguideLinedetails?.allCarrier?.productAndQuestion?.find(
        (product) => product.productBuilder.productId === +productId
      );

    const selectedProductQuestions = selectedProduct?.questionEntities;

    const selectedStates = underguideLinedetails.stateIds.map(
      (selectedStateId) => ({
        stateId: selectedStateId,
        questionAnswer: selectedProductQuestions.map((question) => {
          const convertedAnswers = ConvertedDependents(
            dependableDetails[
              `${question.questionLabel}_${question.productQuestionId}1`
            ]
          );

          let answer = null;
          if (question.masterFieldType.fieldType === "Text") {
            answer = {
              questionId: question.productQuestionId,
              optionType: "Text",
              minValue:
                underguideLinedetails[`${question.questionLabelCode}1`] || "",
              maxValue:
                underguideLinedetails[`${question.questionLabelCode}2`] || null,
              depenedentQuestionRequests: convertedAnswers,
            };
          } else if (question.masterFieldType.fieldType === "Option") {
            const selectedCheckboxes = (
              underguideLinedetails[question.questionLabelCode] || []
            ).filter((checkbox) => checkbox[Object.keys(checkbox)[0]] === 1);
            answer = {
              questionId: question.productQuestionId,
              optionType: "Option",
              optionValue: selectedCheckboxes.map(
                (checkbox) => Object.keys(checkbox)[0]
              ),

              depenedentQuestionRequests: convertedAnswers,
            };
          }

          return answer;
        }),
      })
    );

    var payloadReq = {
      productId: productId,
      carrierPolicy: carrierPolicy,
      carrierId: underguideLinedetails.allCarrier.carrier.carrierId,
      productQuestionState: selectedStates,
    };

    setisLoader(true);
    http
      .post("/saveCarrierQuestionAnswer", payloadReq)
      .then(() => {
        navigate(`${process.env.REACT_APP_BASE_URL}/Admin/AddNewCarrierFinish`);
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    const getAllProductQuestion = async () => {
      setisLoader(true);
      let url = location.pathname;
      let urlArray = url.split("/");
      let productId = urlArray[urlArray.length - 1];
      let carrierId = urlArray[urlArray.length - 3];

      let payload = {
        carrierId: aesUtil.decrypt(carrierId),
        productId: aesUtil.decrypt(productId),
      };

      try {
        let res = await http.post(`/getAllCarrierQuestionAnswer`, {
          requestData: aesUtil.encrypt(JSON.stringify(payload)),
        });

        const allStateIds = res.data.questinAnswerSatate.flatMap(
          (item) => item.states.stateId
        );

        try {
          let response = await http.get(`/getPolicy/${carrierId}`);
          setcarrierPolicy(response?.data?.carrierPolicy);
          setisLoader(false);
        } catch (error) {
          if (error.response.data?.isLogin) {
            setisLoader(false);
            isLogin(error, navigate, Swal);
            return;
          }
          setcarrierPolicy("");
        }
        let newUnderGuideLineDetails = {
          ...underguideLinedetails,
          carrierQuestionAnswer: res.data,
          productBuilder: res.data.productBuilder,
          questionAnswer: res.data.questinAnswerSatate,
          stateIds: allStateIds,
        };

        if (Object.keys(res.data).length > 1) {
          let answers = res.data.questinAnswerSatate[0].answers;

          answers.forEach(
            ({
              optionType,
              optionValue,
              minValue,
              maxValue,
              productQuestionEntity,
              dependentAnswers,
            }) => {
              let filterDependentArray = getData(dependentAnswers);
              const dynamicKey = `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`;
              dependableDetails[dynamicKey] = filterDependentArray;
              setDependentCurrentYear(dependentAnswers);

              if (optionType === "Text") {
                newUnderGuideLineDetails[
                  `${productQuestionEntity.questionLabelCode}1`
                ] = minValue;
                newUnderGuideLineDetails[
                  `${productQuestionEntity.questionLabelCode}2`
                ] = maxValue;
                if (productQuestionEntity.isCurrentYearField) {
                  newUnderGuideLineDetails[
                    `${productQuestionEntity.questionLabelCode}3`
                  ] = 2;
                } else {
                  newUnderGuideLineDetails[
                    `${productQuestionEntity.questionLabelCode}3`
                  ] = 0;
                }

                if (filterDependentArray.length) {
                  dependableDetails[
                    productQuestionEntity.questionLabelCode
                  ] = 1;
                } else {
                  dependableDetails[
                    productQuestionEntity.questionLabelCode
                  ] = 0;
                }
              }

              if (optionType === "Option") {
                newUnderGuideLineDetails[
                  `${productQuestionEntity.questionLabelCode}`
                ] = optionValue;
                if (filterDependentArray.length) {
                  dependableDetails[
                    productQuestionEntity.questionLabelCode
                  ] = 1;
                } else {
                  dependableDetails[
                    productQuestionEntity.questionLabelCode
                  ] = 0;
                }
              }
            }
          );

          setunderguideLinedetails(newUnderGuideLineDetails);
        }

        setisLoader(false);
      } catch (error) {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      }
    };
    getAllProductQuestion();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={3}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer unerGuide">
        <div className="head_bread">
          <h1>Add New Carrier</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/Carriers`}>
              Carriers
            </Link>
            <span>/ Add New Carrier</span>
          </div>
        </div>
        <div className="AdminDashboardWhiteBody twoPartCommon pb-0 overflow-hidden">
          <CarriersLeftMenu pageValue={"UnderGuide"} />
          <div className="left carriersRight">
            <div className="modal_body">
              <h1 style={{ textAlign: "center" }}>
                <img
                  src={location?.state?.carrierLogo}
                  alt="carrier_logo"
                  width={100}
                />
              </h1>
              <h2 className="headText">Underwriting Guidelines</h2>
              <p className="headingBottomText">Selected Products</p>

              <div className="tab_area">
                <div className="Container357">
                  <Tabs
                    id="uncontrolled-tab-example"
                    className="mb-3 underGuideTab"
                  >
                    <Tab
                      eventKey={
                        underguideLinedetails.productBuilder.productName
                      }
                      title={
                        underguideLinedetails.productBuilder.productName || ""
                      }
                    >
                      <div className="SelectedState">
                        <label className="SelectedStateLabel">
                          Selected State
                        </label>
                        <div className="SelectedStateRadio">
                          <fieldset className="checkbox-group">
                            {location?.state?.carrierStates?.length > 0 &&
                              location.state.carrierStates.map((states) => (
                                <div className="checkbox" key={states.stateId}>
                                  <label className="checkbox-wrapper">
                                    <input
                                      type="checkbox"
                                      className="checkbox-input"
                                      name="state"
                                      value={states.stateCode}
                                      onChange={() =>
                                        underGuideLineOnChangeHandleState(
                                          states.stateId
                                        )
                                      }
                                      checked={underguideLinedetails.stateIds.includes(
                                        states.stateId
                                      )}
                                    />
                                    <span className="checkbox-tile">
                                      <span className="checkbox-label">
                                        {states.stateCode}
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              ))}
                          </fieldset>
                        </div>
                      </div>

                      <div>
                        {underguideLinedetails.questionAnswer.length &&
                          underguideLinedetails.questionAnswer[0].answers.map(
                            ({ optionType, productQuestionEntity }) => (
                              <div
                                key={productQuestionEntity.productQuestionId}
                                className="formBox01"
                              >
                                <div className="topPart">
                                  <h2>
                                    {productQuestionEntity.questionLabel}
                                    {productQuestionEntity.isCurrentYearField ===
                                    2
                                      ? "(Year)"
                                      : null}
                                  </h2>
                                </div>
                                {optionType === "Text"
                                  ? productQuestionEntity.isDelete === 0 && (
                                      <div className="bottomPart">
                                        <Form.Group
                                          className="width125"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Control
                                            type="text"
                                            className="form_control_2"
                                            name={`${productQuestionEntity.questionLabelCode}1`}
                                            placeholder="min-value"
                                            value={
                                              underguideLinedetails[
                                                `${productQuestionEntity.questionLabelCode}1`
                                              ] || ""
                                            }
                                            onChange={(event) =>
                                              underGuideLineOnChangeHandle(
                                                event,
                                                underguideLinedetails
                                                  .carrierQuestionAnswer
                                                  .questinAnswerSatate[0]
                                                  .answers
                                              )
                                            }
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          className="width125"
                                          controlId="formBasicEmail"
                                          style={
                                            underguideLinedetails[
                                              `${productQuestionEntity.questionLabelCode}3`
                                            ] === 2 &&
                                            underguideLinedetails[
                                              `${productQuestionEntity.questionLabelCode}2`
                                            ] === null
                                              ? { display: "none" }
                                              : { display: "block" }
                                          }
                                        >
                                          <Form.Control
                                            type="text"
                                            className="form_control_2"
                                            name={`${productQuestionEntity.questionLabelCode}2`}
                                            placeholder="max-value"
                                            value={
                                              underguideLinedetails[
                                                `${productQuestionEntity.questionLabelCode}2`
                                              ] || ""
                                            }
                                            onChange={(event) =>
                                              underGuideLineOnChangeHandle(
                                                event,
                                                underguideLinedetails
                                                  .carrierQuestionAnswer
                                                  .questinAnswerSatate[0]
                                                  .answers
                                              )
                                            }
                                          />
                                        </Form.Group>

                                        {productQuestionEntity.isCurrentYearField ===
                                          2 && (
                                          <>
                                            <input
                                              style={{
                                                width: "20px",
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                              }}
                                              type="checkbox"
                                              name={`${productQuestionEntity.questionLabelCode}3`}
                                              checked={
                                                underguideLinedetails[
                                                  `${productQuestionEntity.questionLabelCode}2`
                                                ] === null
                                              }
                                              onChange={(event) =>
                                                handleCheckboxOnChange(
                                                  event,
                                                  productQuestionEntity,
                                                  ""
                                                )
                                              }
                                            />
                                            <span
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              Current Year
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    )
                                  : productQuestionEntity.isDelete === 0 && (
                                      <div className="borderBoxCheckBox">
                                        <fieldset className="checkbox-group">
                                          {underguideLinedetails[
                                            productQuestionEntity
                                              .questionLabelCode
                                          ]?.map((elem, ind) => (
                                            <div className="checkbox" key={ind}>
                                              {Object.entries(elem).map(
                                                ([key, value]) => (
                                                  <label
                                                    className="checkbox-wrapper"
                                                    key={key}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      className="checkbox-input"
                                                      name={
                                                        productQuestionEntity.questionLabelCode
                                                      }
                                                      value={key} // Assuming 'key' is the label for checkbox
                                                      onChange={(event) =>
                                                        underGuideLineOnChangeHandleForOption(
                                                          event
                                                        )
                                                      }
                                                      checked={value} // Assuming 'value' is the checked state
                                                    />
                                                    <span className="checkbox-tile">
                                                      <span className="checkbox-label">
                                                        {key}
                                                      </span>
                                                    </span>
                                                  </label>
                                                )
                                              )}
                                            </div>
                                          ))}
                                        </fieldset>
                                      </div>
                                    )}
                                <div
                                  className="SelectedState"
                                  style={{
                                    marginTop: "10px",
                                    alignItems: "center",
                                    display:
                                      dependableDetails[
                                        `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
                                      ].length > 0
                                        ? "none"
                                        : "flex",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "15px",
                                    }}
                                  >
                                    Any dependend question?
                                  </label>

                                  <div className="SelectedStateRadio">
                                    <fieldset className="checkbox-group">
                                      <div className="checkbox">
                                        <label className="checkbox-wrapper">
                                          <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            name={`${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`}
                                            onChange={(event) =>
                                              handleDependableOnChange(event)
                                            }
                                          />
                                          <span className="checkbox-tile plus_blue">
                                            <span className="checkbox-label">
                                              +
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>

                                {dependableDetails[
                                  `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
                                ].length > 0 ? (
                                  <div>
                                    {dependableDetails[
                                      `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
                                    ]?.map((dependendAns, ind) => (
                                      <Fragment key={ind}>
                                        <div
                                          style={{
                                            marginBottom: "10px",
                                            marginTop: "5px",
                                          }}
                                          key={ind}
                                          className="d-flex align-items-center"
                                        >
                                          <Form.Select
                                            aria-label="Default select example"
                                            onChange={(event) =>
                                              handleDependableQuestionOnChange(
                                                event,
                                                `${productQuestionEntity?.productQuestionId}`,
                                                ind
                                              )
                                            }
                                          >
                                            <option hidden>
                                              {dependendAns
                                                ?.productQuestionEntity
                                                ?.questionLabel
                                                ? dependendAns
                                                    ?.productQuestionEntity
                                                    ?.questionLabel
                                                : "Select Questions"}
                                            </option>

                                            {underguideLinedetails
                                              .questionAnswer.length &&
                                              underguideLinedetails.questionAnswer[0].answers.map(
                                                (answer) =>
                                                  productQuestionEntity.questionLabel !==
                                                    answer.productQuestionEntity
                                                      .questionLabel && (
                                                    <option
                                                      key={
                                                        answer
                                                          .productQuestionEntity
                                                          .productQuestionId
                                                      }
                                                      // value={`${answer.productQuestionEntity.questionLabelCode}`}
                                                      value={`${productQuestionEntity.questionLabel}_${answer.productQuestionEntity.productQuestionId}`}
                                                    >
                                                      {
                                                        answer
                                                          .productQuestionEntity
                                                          .questionLabel
                                                      }
                                                    </option>
                                                  )
                                              )}
                                          </Form.Select>

                                          <span
                                            className="ps-2"
                                            onClick={() => {
                                              const updatedArray = [
                                                ...dependableDetails[
                                                  `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`
                                                ],
                                              ];
                                              updatedArray.splice(ind, 1);
                                              setDependableDetails({
                                                ...dependableDetails,
                                                [`${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`]:
                                                  updatedArray,
                                              });
                                            }}
                                          >
                                            <img
                                              src={IMAGE_PATHS.DeleteIconGray}
                                              loading="lazy"
                                              alt="Logo"
                                              width={"16px"}
                                            />
                                          </span>
                                        </div>

                                        {Object.keys(dependendAns).length ? (
                                          dependendAns.optionType === "Text" ? (
                                            <div className="bottomPart">
                                              <Form.Group
                                                className="width125"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  className="form_control_2"
                                                  name={`${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`}
                                                  placeholder="min-value"
                                                  value={
                                                    dependendAns.minValue || ""
                                                  }
                                                  onChange={(event) =>
                                                    dependentQuestionTextOptionOnChangeHandle(
                                                      event,
                                                      dependendAns,
                                                      "min",
                                                      ind
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                              <Form.Group
                                                className="width125"
                                                controlId="formBasicEmail"
                                                style={
                                                  dependendAns
                                                    .productQuestionEntity
                                                    .isCurrentYearField === 2 &&
                                                  dependendAns.maxValue === null
                                                    ? { display: "none" }
                                                    : { display: "block" }
                                                }
                                              >
                                                <Form.Control
                                                  type="text"
                                                  className="form_control_2"
                                                  name={`${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`}
                                                  placeholder="max-value"
                                                  value={
                                                    dependendAns.maxValue || ""
                                                  }
                                                  onChange={(event) =>
                                                    dependentQuestionTextOptionOnChangeHandle(
                                                      event,
                                                      dependendAns,
                                                      "max",
                                                      ind
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                              {dependendAns
                                                .productQuestionEntity
                                                .isCurrentYearField === 2 && (
                                                <>
                                                  <input
                                                    style={{
                                                      width: "20px",
                                                      cursor: "pointer",
                                                      marginLeft: "10px",
                                                    }}
                                                    type="checkbox"
                                                    name={`${productQuestionEntity.questionLabelCode}_${dependendAns.productQuestionEntity.productQuestionId}`}
                                                    checked={
                                                      dependendAns.maxValue ===
                                                      null
                                                    }
                                                    onChange={(event) =>
                                                      handleCheckboxOnChange(
                                                        event,
                                                        dependendAns,
                                                        "dependent"
                                                      )
                                                    }
                                                  />
                                                  <span
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    Current Year
                                                  </span>
                                                </>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="borderBoxCheckBox">
                                              <fieldset className="checkbox-group">
                                                {dependendAns.optionValue?.map(
                                                  (elem, indx) => (
                                                    <div
                                                      className="checkbox"
                                                      key={indx}
                                                    >
                                                      {Object.entries(elem).map(
                                                        ([key, value]) => (
                                                          <label
                                                            className="checkbox-wrapper"
                                                            key={key}
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              className="checkbox-input"
                                                              name={`${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}1`}
                                                              value={key} // Assuming 'key' is the label for checkbox
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                dependentQuestionTextOptionOnChangeHandle(
                                                                  event,
                                                                  dependendAns,
                                                                  "",
                                                                  ind
                                                                )
                                                              }
                                                              checked={value} // Assuming 'value' is the checked state
                                                            />
                                                            <span className="checkbox-tile">
                                                              <span className="checkbox-label">
                                                                {key}
                                                              </span>
                                                            </span>
                                                          </label>
                                                        )
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                              </fieldset>
                                            </div>
                                          )
                                        ) : null}
                                      </Fragment>
                                    ))}

                                    <Button
                                      className="button_blueBorder mt-2"
                                      onClick={() =>
                                        handleAddMoreDependent(
                                          productQuestionEntity
                                        )
                                      }
                                    >
                                      <img
                                        src={IMAGE_PATHS.phPlusBoldIcon}
                                        loading="lazy"
                                        alt="Logo"
                                        width={"10px"}
                                      />
                                      Add More Dependend
                                    </Button>
                                  </div>
                                ) : null}
                              </div>
                            )
                          )}
                      </div>

                      <div className="formBox01 noBorder">
                        <div className="twoPart">
                          <div className="left">
                            <p className="head">Underwriting Guidelines</p>
                            <p className="smallTest">
                              {underguideLinedetails.logoFileTitle}

                              {carrierPolicy &&
                              !underguideLinedetails.logoFileTitle ? (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    PolicyDownload(
                                      carrierPolicy,
                                      `${secondLastLocation(location)}_policy`
                                    )
                                  }
                                >
                                  VIEW
                                </span>
                              ) : null}
                            </p>
                          </div>
                          <div className="right">
                            <div className="upload_btn_wrapper">
                              <input
                                type="file"
                                onChange={(event) =>
                                  underGuideLineOnChangeHandleLogo(event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  // className={`login sign_in_button ${
                  //   underguideLinedetails.stateIds.length > 0 && carrierPolicy
                  //     ? ""
                  //     : "deactive"
                  // }`}
                  // disabled={
                  //   underguideLinedetails.stateIds.length === 0 ||
                  //   !carrierPolicy
                  // }

                  className={`login sign_in_button ${
                    underguideLinedetails.stateIds.length > 0 ? "" : "deactive"
                  }`}
                  disabled={underguideLinedetails.stateIds.length === 0}
                  onClick={() => underGuideLineButtonOnClickHandle()}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UnderGuide;
{
  /* {dependableDetails[
                                                `${productQuestionEntity.questionLabel}_${productQuestionEntity.productQuestionId}`
                                              ].productQuestionEntity
                                                .isCurrentYearField === 2 && (
                                                
                                              )} */
}
