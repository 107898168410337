import React, { Fragment } from "react";
import Header from "./Header";
import LeftMenu from "./LeftMenu";

const Layout = (props) => {
  return (
    <Fragment>
      <div className="container-fluid AdminContainer">
        <div className="left">
          <LeftMenu sideBarActive={props.sideBarActive} />
        </div>
        <div className="right">
          <Header />
          {props.children}
          {/* <Footer /> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
