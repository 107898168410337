const ConvertedDependents = (dependentAnswers) => {
  const convertedAnswers = dependentAnswers.map((answer) => {
    const { productQuestionEntity, ...rest } = answer;
    const transformedAnswer = {
      ...rest,
      questionId: productQuestionEntity.productQuestionId,
    };

    if (
      transformedAnswer.optionType === "Option" &&
      transformedAnswer.optionValue
    ) {
      const optionValueKeys = transformedAnswer.optionValue
        .filter((obj) => Object.values(obj)[0] === 1)
        .map((obj) => Object.keys(obj)[0]);
      transformedAnswer.optionValue = optionValueKeys;
    }

    return transformedAnswer;
  });
  return convertedAnswers;
};

const getData = (dependentAnswers) => {
  const filteredDependentAnswers = dependentAnswers.filter((answer) => {
    if (answer.optionType === "Text") {
      if (
        answer.minValue !== null &&
        answer.minValue !== 0 &&
        answer.minValue !== ""
      ) {
        return answer;
      }
    } else {
      if (answer?.optionValue?.length) {
        if (answer.optionValue.some((obj) => Object.values(obj).includes(1))) {
          return answer;
        }
      }
      return;
    }
  });
  return filteredDependentAnswers;
};

const setDependentCurrentYear = (dependentAnswers) => {
  const filteredDependentAnswers = dependentAnswers.filter((answer) => {
    if (answer.optionType === "Text") {
      if (
        answer.minValue !== null &&
        answer.minValue !== 0 &&
        answer.minValue !== ""
      ) {
        return answer;
      }
    } else {
      if (answer?.optionValue?.length) {
        if (answer.optionValue.some((obj) => Object.values(obj).includes(1))) {
          return answer;
        }
      }
      return;
    }
  });
  return filteredDependentAnswers;
};

export { ConvertedDependents, getData, setDependentCurrentYear };
