import {
  isLogin,
  isLoginAgent,
  isLoginMain,
} from "../components/IsLogin/IsLogin";

const MainPostCalls = {
  getAllState: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getAllRiskerState");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getReview: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getReviewDetails");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getProduct: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getAllRiskerProduct");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getAllCarriers: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getRiskerCarriers");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  searchCarrier: async (http, setIsLoader, Swal, searchText) => {
    try {
      setIsLoader(true);
      const res = await http.get(`/page/searchCarrierByRandom/${searchText}`);
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  saveDemo: async (http, setIsLoader, Swal, payload) => {
    try {
      setIsLoader(true);
      const res = await http.post("/page/saveDemo", payload);
      setIsLoader(false);

      return res.data;
    } catch (error) {
      console.log(error, "E");
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getAllValues: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get(`/page/getAllValues`);
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getAllTeams: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get(`/page/getTeamDetails`);
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getAllFAQ: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("page/getAllFAQ");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getContact: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/getAllRiskerContact");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  getSubscription: async (http, setIsLoader, Swal) => {
    try {
      setIsLoader(true);
      const res = await http.get("/page/riskerAllSubscriptionPlan");
      setIsLoader(false);
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },

  saveTextUs: async (http, setIsLoader, Swal, payload) => {
    try {
      setIsLoader(true);
      const res = await http.post("/page/saveCustomer", payload);
      setIsLoader(false);

      return res.data;
    } catch (error) {
      console.log(error, "E");
      setIsLoader(false);
      isLoginMain(error, Swal);
    }
  },
};

const PostCallsAgent = {
  getAllConnectedProducts: async (http, setIsLoader, navigate, Swal) => {
    try {
      const res = await http.get("/getAllConnectedProduct");
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  },
};

const PostCallsAdmin = {
  getAllConnectedProducts: async (http, setIsLoader, navigate, Swal) => {
    try {
      const res = await http.get("/getAllConnectedProduct");
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  },
};

const PostCallsCommon = {
  getAllConnectedProducts: async (http, setIsLoader, navigate, Swal) => {
    try {
      const res = await http.get("/getAllConnectedProduct");
      return res.data;
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  },
};

export { MainPostCalls, PostCallsAgent, PostCallsAdmin, PostCallsCommon };
