import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import IMAGE_PATHS from "../../../constants";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { useNavigate } from "react-router-dom";

const ManageSubscription = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [isLoader, setisLoader] = useState(false);

  const [editManageSubsDetails, setEditManageSubsDetails] = useState({
    monthlyAm: "",
    yearlyAm: "",
    trialPeriods: "",
    manageSubsLists: [],
  });

  const { http } = ApiClient();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const editManageSubsHandleOnChange = (event) => {
    let { value, name } = event.target;
    const isPositiveNumber = /^[+]?\d+(\.\d+)?$/.test(value);
    const isWithinFourDigits = value.length <= 4;

    if ((isPositiveNumber && isWithinFourDigits) || value === "") {
      setEditManageSubsDetails({ ...editManageSubsDetails, [name]: value });
    }
  };

  const editManageSubsHandleOnClick = () => {
    let { monthlyAm, yearlyAm, trialPeriods } = editManageSubsDetails;

    if (!monthlyAm || !yearlyAm || !trialPeriods) {
      return Swal.fire({
        title: "Oops...",
        icon: "warning",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    const subscriptionPlanDetails = [
      {
        subscriptionPlanId: 1,
        masterEntity: {
          masterEntityId: 1,
        },
        subscriptionPlanType: 1,
        subscriptionPlanName: "Monthly",
        subscriptionAmount: editManageSubsDetails.monthlyAm,
        trialPeriods: editManageSubsDetails.trialPeriods,
      },
      {
        subscriptionPlanId: 2,
        masterEntity: {
          masterEntityId: 1,
        },
        subscriptionPlanType: 2,
        subscriptionPlanName: "Yearly",
        subscriptionAmount: editManageSubsDetails.yearlyAm,
        trialPeriods: editManageSubsDetails.trialPeriods,
      },
    ];
    setisLoader(true);
    http
      .put("/updateSubscriptionPlan", subscriptionPlanDetails)
      .then((res) => {
        getSubscriptionPlan();
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });

    handleClose();
  };

  const getSubscriptionPlan = () => {
    setisLoader(true);
    http
      .get("/allSubscriptionPlan", {})
      .then((res) => {
        setEditManageSubsDetails({
          ...editManageSubsDetails,
          manageSubsLists: res.data,
          monthlyAm: res.data[0].subscriptionAmount,
          yearlyAm: res.data[1].subscriptionAmount,
          trialPeriods: res.data[0].trialPeriods,
        });
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };
  useEffect(() => {
    getSubscriptionPlan();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={4}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer">
        <div className="head_bread">
          <h1>Manage Subscription</h1>
        </div>
        <div className="sideBySideBox mt_200">
          <div className="shadowBox330 agent_box">
            <div className="top borer_b_E4E4E4">
              <div className="left">For Agent</div>
              <div className="right">
                <img
                  src={IMAGE_PATHS.edit_icon}
                  loading="lazy"
                  alt="Logo"
                  onClick={() => handleShow()}
                  className="hand"
                />
              </div>
            </div>
            <div className="bottom">
              <div className="left opacity07">Monthly Amount ($)</div>
              <div className="right colorBlue">
                {editManageSubsDetails.manageSubsLists?.[0]?.subscriptionAmount}
              </div>
            </div>

            <div className="bottom">
              <div className="left opacity07">Yearly Amount ($)</div>
              <div className="right colorBlue">
                {editManageSubsDetails.manageSubsLists?.[1]?.subscriptionAmount}
              </div>
            </div>
            <div className="bottom">
              <div className="left opacity07">Trial Period (Days)</div>
              <div className="right colorBlue">
                {editManageSubsDetails.manageSubsLists?.[0]?.trialPeriods}
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            className="AddNewCategoryModal"
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Body>
              <div className="modal_body pad20">
                <h2 className="headText">For Agent</h2>
                <img
                  src={IMAGE_PATHS.Close_modal_normal}
                  loading="lazy"
                  alt="Logo"
                  width={"15px"}
                  onClick={handleClose}
                  className="modalCrossNormal hand"
                />
                <div className="row ">
                  <Form.Group
                    className="mb_12 twoPartCommon align-items-center"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="label_text">
                      Monthly Amount ($)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="monthlyAm"
                      className="input70"
                      value={editManageSubsDetails.monthlyAm || ""}
                      onChange={(event) => editManageSubsHandleOnChange(event)}
                    />
                  </Form.Group>
                </div>
                <div className="row ">
                  <Form.Group
                    className="mb_12 twoPartCommon align-items-center"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="label_text">
                      Yearly Amount ($)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="yearlyAm"
                      className="input70"
                      value={editManageSubsDetails.yearlyAm || ""}
                      onChange={(event) => editManageSubsHandleOnChange(event)}
                    />
                  </Form.Group>
                </div>

                <div className="row ">
                  <Form.Group
                    className="mb_12 twoPartCommon align-items-center"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="label_text">
                      Trial Period (Days)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="trialPeriods"
                      className="input70"
                      value={editManageSubsDetails.trialPeriods || ""}
                      onChange={(event) => editManageSubsHandleOnChange(event)}
                    />
                  </Form.Group>
                </div>

                <div className="buttn_row mt_20">
                  <Button
                    variant="primary"
                    type="submit"
                    className="login sign_in_button"
                    onClick={() => editManageSubsHandleOnClick()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default ManageSubscription;
