import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { DateFormat } from "../../../components/DateFormat/DateFormat";
import Paginations from "../../../components/Pagination";

const Payments = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [paymentsData, setpaymentsData] = useState({
    allLists: [],
    count: 1,
    receivedPendingData: {},
  });
  const [loading, setLoading] = useState(false);
  const { http } = ApiClient();

  const handleDownloadClick = (invoice) => {
    if (invoice) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);

      window.location.href = invoice;
    } else {
      Swal.fire({
        title: "Oops...",
        icon: "warning",
        text: "Invoice not available!",
        confirmButtonText: "OK",
      });
    }
  };

  const recPendData = () => {
    setLoading(true);
    http
      .get("/getPayementDetails")
      .then((res) => {
        setpaymentsData((prevData) => ({
          ...prevData,
          receivedPendingData: res.data,
        }));
        // setLoading(false);
      })
      .catch((error) => {
        isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    setLoading(true);
    http
      .get(`/getUserPayment/${page}`)
      .then((res) => {
        setpaymentsData((prevData) => ({
          ...prevData,
          allLists: res.data.data,
          count: res.data.count,
        }));
      })
      .catch((error) => {
        isLogin(error, navigate, Swal);
      })
      .finally(() => {
        setLoading(false);
      });
    recPendData();
    // eslint-disable-next-line
  }, [page]);
  return (
    <Layout sideBarActive={8}>
      {loading && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>Payments</h1>
          <div className="r">
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Payments</span>
          </div>
        </div>
        <div className="paymentThreeBox">
          <div className="whiteBoxShadow">
            <p className="smallGrayText">Overall Received</p>
            {paymentsData?.receivedPendingData ? (
              <p className="bigBlueText">
                {paymentsData.receivedPendingData.overAllReceived}
              </p>
            ) : (
              <p>0.0</p>
            )}
          </div>
          <div className="whiteBoxShadow">
            <p className="smallGrayText">Monthly Received</p>
            {paymentsData?.receivedPendingData ? (
              <p className="bigBlueText">
                {paymentsData.receivedPendingData.monthlyReceived}
              </p>
            ) : (
              <p>0.0</p>
            )}
          </div>
          <div className="whiteBoxShadow">
            <p className="smallGrayText">Pending</p>
            {paymentsData?.receivedPendingData ? (
              <p className="bigBlueText">
                {paymentsData.receivedPendingData.pending}
              </p>
            ) : (
              <p>0.0</p>
            )}
          </div>
        </div>
        <div className="head_bread">
          <h1>Payment History</h1>
        </div>
        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>ID Invoice</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Email</th>
                <th>Service Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentsData.allLists.length ? (
                paymentsData.allLists.map(
                  ({
                    paymentId,
                    invoiceId,
                    amount,
                    email,
                    serviceType,
                    date,
                    invoiceDownload,
                    payementStatus,
                  }) => (
                    <tr key={paymentId}>
                      <td>#{invoiceId}</td>
                      <td>{DateFormat(date)}</td>
                      <td>${amount}</td>
                      <td>{email}</td>
                      <td>{serviceType}</td>
                      <td>
                        <span className="active_green">{payementStatus}</span>
                      </td>
                      <td>
                        <div
                          className="colorBlueLink"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDownloadClick(invoiceDownload)}
                        >
                          Download Invoice
                        </div>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>

                  <td
                  // style={{
                  //   padding: "10px",
                  //   border: "1px solid black",
                  //   textAlign: "center",
                  // }}
                  >
                    No Data Found
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {paymentsData.allLists.length ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={paymentsData.count}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default Payments;
