import React from "react";
import { Pagination } from "react-bootstrap";

// let data = { page, totalPage, setPage,count };
// totalPage=count/20;

const Paginations = ({ page, setPage, count }) => {
  let totalPage = Math.ceil(count / 20);
  return (
    <div className="bottomPagincation">
      <div className="totalNumber">Total number found: {count}</div>

      <Pagination>
        <Pagination.First
          onClick={() => {
            setPage(1);
          }}
        />
        <Pagination.Prev
          onClick={() => {
            setPage((p) => p - 1);
          }}
          disabled={page <= 1}
        />
        {page > 1 ? (
          <Pagination.Item
            disabled={page <= 1}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            {page - 1}
          </Pagination.Item>
        ) : null}
        <Pagination.Item active>{page}</Pagination.Item>
        {page >= totalPage ? null : (
          <Pagination.Item
            disabled={page >= totalPage}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            {page + 1}
          </Pagination.Item>
        )}
        <Pagination.Next
          disabled={page >= totalPage}
          onClick={() => {
            setPage((p) => p + 1);
          }}
        />
        <Pagination.Last
          onClick={() => {
            setPage(totalPage);
          }}
        />
      </Pagination>
    </div>
  );
};

export default Paginations;
