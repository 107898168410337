import React, { useState } from "react";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ShowActivePage from "../../../components/ShowActivePage/ShowActivePage";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import Loader from "../../../components/Loader/Loader";

function SaveBankAccount() {
  const navigate = useNavigate();
  const [isLoader, setisLoader] = useState(false);
  const [savebankaccdetails, setsavebanckaccdetails] = useState({
    accNumber: "",
    bankName: "",
    accHolderName: "",
    routingCode: "",
  });
  const { http } = ApiClient();

  const handlebankAccDetailsOnChange = (event) => {
    setsavebanckaccdetails({
      ...savebankaccdetails,
      [event.target.name]: event.target.value,
    });
  };
  const saveAccountOnClick = async () => {
    const { accNumber, bankName, accHolderName, routingCode } =
      savebankaccdetails;

    if (accNumber && bankName && accHolderName && routingCode) {
      try {
        let payload = {
          userEntity: {
            userId: +sessionStorage.getItem("userId"),
          },
          agentEntity: {
            entityId: 1,
          },
          masterSubscriptionPlan: {
            subscriptionPlanId: +sessionStorage.getItem("subscriptionPlanId"),
          },

          masterPaymentOptions: {
            paymentOptionsId: 1,
          },
        };
        setisLoader(true);
        await http.post("/savePlan", payload);
        // accountNo: "000123456789",
        // routingCode: "110000000",
        try {
          let res = await http.post("/savePaymentMethod", {
            accountNo: accNumber,
            routingCode: routingCode,
            state: "US",
            accountHolderName: accHolderName,
            subscriptionId: +sessionStorage.getItem("subscriptionPlanId"),
          });
          // localStorage.setItem("paymentData", JSON.stringify(res.data));

          try {
            await http.post("/savePayementDetails", res.data);

            Swal.fire({
              icon: "success",
              title: "Success",
              // text: "Bank details saved successfully!",
              text: "Payment successfully processed!",
              confirmButtonText: "OK",
            }).then(() => {
              navigate(`${process.env.REACT_APP_BASE_URL}/AgentDashboard`);
            });
            setisLoader(false);
          } catch (error) {
            setisLoader(false);
            isLoginAgent(error, navigate, Swal);
          }
        } catch (error) {
          setisLoader(false);
          isLoginAgent(error, navigate, Swal);
        }
      } catch (error) {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      }
      // /savePlan=>/savePaymentMethod=>/savePayementDetails(payload will be in savePaymentMethod)
    } else {
      return Swal.fire({
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }
  };

  return (
    <>
      {isLoader && <Loader />}
      <div className="loginBoxOuter">
        <div className="container-fluid container_outer_02">
          <div className="container">
            <div className="AgentUpdateProfile_top">
              <img
                src={IMAGE_PATHS.risker_logo_white}
                loading="lazy"
                alt="Logo"
                width={"151px"}
              />
            </div>
            <div className="AgentUpdateProfile_bottom">
              <ShowActivePage pageValue={"SaveBankAccount"} />
              <div className="right">
                <div className="top"></div>
                <div className="middle">
                  <div className="formContainer">
                    <div className="login_top">
                      <h3>Save Your Bank Account</h3>
                    </div>
                    <Form>
                      <div className="row">
                        <Form.Group className="mb_12">
                          <Form.Label
                            className="label_text"
                            htmlFor="accountNumber"
                          >
                            Bank Account Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="accountNumber"
                            placeholder="Enter account number"
                            value={savebankaccdetails.accNumber}
                            name="accNumber"
                            onChange={(event) =>
                              handlebankAccDetailsOnChange(event)
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group className="mb_12">
                          <Form.Label className="label_text" htmlFor="bankName">
                            Bank Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="bankName"
                            placeholder="Enter bank name"
                            value={savebankaccdetails.bankName}
                            name="bankName"
                            onChange={(event) =>
                              handlebankAccDetailsOnChange(event)
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group className="mb_12">
                          <Form.Label
                            className="label_text"
                            htmlFor="acHolderName"
                          >
                            Account Holder Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="acHolderName"
                            placeholder="Enter account holder name"
                            value={savebankaccdetails.accHolderName}
                            name="accHolderName"
                            onChange={(event) =>
                              handlebankAccDetailsOnChange(event)
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group className="mb_12">
                          <Form.Label className="label_text" htmlFor="routCode">
                            Routing Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="routCode"
                            placeholder="Enter routing code"
                            value={savebankaccdetails.routingCode}
                            name="routingCode"
                            onChange={(event) =>
                              handlebankAccDetailsOnChange(event)
                            }
                          />
                        </Form.Group>
                      </div>

                      <div className="row">
                        <div className="buttn_row mt_20">
                          {/* <Link to="/"> */}
                          <Button
                            variant="primary"
                            type="button"
                            className="login sign_in_button"
                            onClick={saveAccountOnClick}
                          >
                            Submit
                          </Button>
                          {/* </Link> */}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SaveBankAccount;
