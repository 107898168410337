import React, { useEffect, useState } from "react";
import IMAGE_PATHS from "../../constants";

const CarriersLeftMenu = ({ pageValue }) => {
  const [classesname, setclassesname] = useState({
    basicInfo: "",
    underGuideLine: "",
    addNewCarrierFinish: "",
  });
  const [activeCompleteImages, setactiveCompleteImages] = useState({
    // selectYourPlan: IMAGE_PATHS.SelectPlanActiveIcon,
    basicInfo: IMAGE_PATHS.SelectPlanActiveIcon,
    underGuideLine: IMAGE_PATHS.underGuidDective,
    addNewCarrierFinish: IMAGE_PATHS.finishIconDeactive,
  });
  useEffect(() => {
    const setClassesForPages = () => {
      if (pageValue === "BasicInfo") {
        setclassesname({ ...classesname, basicInfo: "active" });
      } else if (pageValue === "UnderGuide") {
        setclassesname({
          ...classesname,
          basicInfo: "completed",
          underGuideLine: "active",
        });
        setactiveCompleteImages({
          ...activeCompleteImages,
          basicInfo: IMAGE_PATHS.blueCirleTick,
          underGuideLine: IMAGE_PATHS.underGuidActive,
        });
      } else {
        setclassesname({
          ...classesname,
          basicInfo: "completed",
          underGuideLine: "completed",
          addNewCarrierFinish: "active",
        });
        setactiveCompleteImages({
          ...activeCompleteImages,
          basicInfo: IMAGE_PATHS.blueCirleTick,
          underGuideLine: IMAGE_PATHS.blueCirleTick,
          addNewCarrierFinish: IMAGE_PATHS.finishIconActive,
        });
      }
    };
    setClassesForPages();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <div className="left carriersLeft">
      <div className="select_plan_process">
        <ul className="displayCarriersInnerLeft">
          <li className={classesname.basicInfo}>
            <div>
              <img
                src={activeCompleteImages.basicInfo}
                loading="lazy"
                alt="Logo"
                width={"31px"}
              />
              <span>Basic Info</span>
            </div>
          </li>
          <li className={classesname.underGuideLine}>
            <div>
              <img
                src={activeCompleteImages.underGuideLine}
                loading="lazy"
                alt="Logo"
                width={"31px"}
              />
              <span>Underwriting Guidelines</span>
            </div>
          </li>
          <li className={classesname.addNewCarrierFinish}>
            <div>
              <img
                src={activeCompleteImages.addNewCarrierFinish}
                loading="lazy"
                alt="Logo"
                width={"31px"}
              />
              <span>Finish</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CarriersLeftMenu;
