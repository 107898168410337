import React, { useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import IMAGE_PATHS from "../../../constants";
import { Link } from "react-router-dom";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";

const AddUserAgent = () => {
  const [addUserDetails, setaddUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const navigate = useNavigate();
  const { http } = ApiClient();
  const [isLoader, setisLoader] = useState(false);
  const addUserOnChangeHandle = (event) => {
    setaddUserDetails({
      ...addUserDetails,
      [event.target.name]: event.target.value,
    });
  };
  const addUserHandleOnClick = () => {
    let { firstName, lastName, email } = addUserDetails;
    if (!firstName || !lastName || !email) {
      return Swal.fire({
        title: "Oops...",
        text: "All the Fields are Mandatory!",
        confirmButtonText: "OK",
      });
    }

    let addUserPayload = {
      firstName,
      lastName,
      userEmail: email,
    };

    setisLoader(true);
    http
      .post("/addStafUser", addUserPayload)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem(
            "staffDetails",
            JSON.stringify({
              ...addUserPayload,
              userId: res.data.userId,
            })
          );
          navigate(`${process.env.REACT_APP_BASE_URL}/SubscriptionAgent`);
        }
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };
  return (
    <LayoutAgent sideBarActive={24}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Add a User</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/UserManagementAgent`}>
              User Management
            </Link>
            <span>/ Add Sub-Agent</span>
          </div>
        </div>
        <div className="AdminDashboardWhiteBody twoPartCommon pb-0 overflow-hidden">
          <div className="carriersLeft">
            <div className="select_plan_process">
              <ul className="displayCarriersInnerLeft">
                <li className="active">
                  <div>
                    <img
                      src={IMAGE_PATHS.add_user_agent_active}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                    />
                    <span>Add User</span>
                  </div>
                </li>
                <li className="">
                  <div>
                    <img
                      src={IMAGE_PATHS.subscription_agent_deactive}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                    />
                    <span>Subscription</span>
                  </div>
                </li>
                <li className="">
                  <div>
                    <img
                      src={IMAGE_PATHS.finish_agent_deactive}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                    />
                    <span>Finish</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="carriersRight unerGuide">
            <div className="modal_body">
              <h2 className="headText">Basic Information</h2>
              <p className="headingBottomText mb-5">
                Please enter following details for sub agent
              </p>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    name="firstName"
                    value={addUserDetails.firstName}
                    onChange={(event) => addUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={addUserDetails.lastName}
                    onChange={(event) => addUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email ID"
                    name="email"
                    value={addUserDetails.email}
                    onChange={(event) => addUserOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button "
                  onClick={addUserHandleOnClick}
                >
                  Create Account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutAgent>
  );
};

export default AddUserAgent;
