import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import ShowActivePage from "../../../components/ShowActivePage/ShowActivePage";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import Swal from "sweetalert2";

export default function SelectYourPlan() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const { http } = ApiClient();

  // for temp strip

  const handleSelectYourPlanOnClick = async (planId) => {
    let payload = {
      userEntity: {
        userId: +sessionStorage.getItem("userId"),
      },
      agentEntity: {
        entityId: 1,
      },
      masterSubscriptionPlan: {
        subscriptionPlanId: planId,
      },

      masterPaymentOptions: {
        paymentOptionsId: 2,
      },
    };

    try {
      setLoading(true);

      await http.post("/savePlan", payload);

      // const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PK);

      let stripePayload = {
        subscriptionId: planId,
        successUrl: `${process.env.REACT_APP_MAIN_PATH}${process.env.REACT_APP_BASE_URL}/AgentDbSuccess`,
        cancelUrl: `${process.env.REACT_APP_MAIN_PATH}${process.env.REACT_APP_BASE_URL}/SelectYourPlan`,
      };
      try {
        const response = await http.post("/subscription", stripePayload);
        localStorage.setItem("paymentData", JSON.stringify(response.data));
        navigate(`${process.env.REACT_APP_BASE_URL}/AgentDbSuccess`);

        // setTimeout(() => {
        //   stripe.redirectToCheckout({
        //     sessionId: response.data.id,
        //   });
        //   // setLoading(false);
        // }, 2000);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        isLoginAgent(error, navigate, Swal);
      }
    } catch (error) {
      setLoading(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  // for temp strip

  useEffect(() => {
    setLoading(true);
    http
      .get("/allSubscriptionPlan")
      .then((res) => {
        if (res.status === 200) {
          setPlans(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        isLoginAgent(error, navigate, Swal);
      });
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="loginBoxOuter">
      <div className="container-fluid container_outer_02">
        <div className="container">
          <div className="AgentUpdateProfile_top">
            <img
              src={IMAGE_PATHS.risker_logo_white}
              loading="lazy"
              alt="Logo"
              width={"151px"}
            />
          </div>
          <div className="AgentUpdateProfile_bottom">
            <ShowActivePage pageValue={"SelectYourPlan"} />
            <div className="right">
              <div className="top"></div>
              <div className="middle">
                <div className="formContainer">
                  <div className="login_top">
                    <h3>Select Your Plan</h3>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p> */}
                  </div>
                </div>
                <div className="twoPlan">
                  {plans?.map((plan) => {
                    return (
                      <div
                        key={plan?.subscriptionPlanId}
                        className={
                          plan?.subscriptionPlanName === "Monthly"
                            ? "monthly_box planBox redBox"
                            : "yearly_box planBox purpleBox"
                        }
                      >
                        <h2>{plan?.subscriptionPlanName}</h2>
                        <p className="smallText">
                          {plan?.trialPeriods} days trial
                        </p>
                        <p className="amount">${plan?.subscriptionAmount}</p>
                        <Link
                          // for perm stripe
                          // onClick={() => {
                          //   sessionStorage.setItem(
                          //     "subscriptionPlanId",
                          //     plan?.subscriptionPlanId
                          //   );
                          // }}
                          // to={`${process.env.REACT_APP_BASE_URL}/SelectPaymentType`}
                          // for perm stripe

                          // for temp stripe

                          onClick={() => {
                            handleSelectYourPlanOnClick(
                              plan?.subscriptionPlanId
                            );
                          }}

                          // for
                          // temp
                          // stripe
                        >
                          <Button>Get Started</Button>
                        </Link>
                      </div>
                    );
                  })}
                  {/* <div className="monthly_box planBox redBox">
                    <h2>Monthly</h2>
                    <p className="smallText">10 days trial</p>
                    <p className="amount">$5</p>
                    <Link
                      to={`${process.env.REACT_APP_BASE_URL}/SelectPaymentType`}
                    >
                      <Button>Get Started</Button>
                    </Link>
                  </div>
                  <div className="yearly_box planBox purpleBox">
                    <h2>Yearly</h2>
                    <p className="smallText">10 days trial</p>
                    <p className="amount">$10</p>
                    <Link
                      to={`${process.env.REACT_APP_BASE_URL}/SelectPaymentType`}
                    >
                      <Button>Get Started</Button>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
