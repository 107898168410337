import React, { useEffect, useState } from "react";
import LayoutMain from "../UI/LayoutMain/LayoutMain";
import { Row, Col } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { MainPostCalls } from "../../../ApiClient/PostCall";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";

const CarriersMain = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [carrierDetails, setcarrierDetails] = useState({
    allStates: [],
    allCarrier: [],
    searchedText: "",
    searchedState: "",
  });
  const { http } = ApiClient();

  const getAllStates = async () => {
    let data = await MainPostCalls.getAllState(http, setIsLoader, Swal);

    setcarrierDetails((prevDetails) => ({
      ...prevDetails,
      allStates: data,
    }));
  };

  const searchCarrierOnClickHandle = async (searchedValue, type) => {
    if (!searchedValue) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Search value cannot be empty!",
        confirmButtonText: "CANCEL",
      });
    }

    if (type === "search") {
      setcarrierDetails((prevDetails) => ({
        ...prevDetails,
        searchedState: "",
      }));
    }
    let data = await MainPostCalls.searchCarrier(
      http,
      setIsLoader,
      Swal,
      searchedValue
    );
    setcarrierDetails((prevDetails) => ({
      ...prevDetails,
      allCarrier: data,
    }));
  };

  const searchByStateOnChangeHandle = async (stateCode) => {
    setcarrierDetails({
      ...carrierDetails,
      searchedState: stateCode,
      searchedText: "",
    });
    searchCarrierOnClickHandle(stateCode, "");
  };

  const getAllCarrier = async () => {
    let data = await MainPostCalls.getAllCarriers(http, setIsLoader, Swal);
    getAllStates();
    setcarrierDetails((prevDetails) => ({
      ...prevDetails,
      allCarrier: data,
    }));
  };

  useEffect(() => {
    getAllCarrier();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {isLoader && <Loader />}
      <LayoutMain sideBarActive={3}>
        <div className="mainBanner">
          <div className="carriersMainBg">
            <img
              src={IMAGE_PATHS.carriersMainBg}
              loading="lazy"
              alt="Logo"
              width={""}
            />
            <div className="container carriersMainContainer">
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <h1>Our Insurance Carriers</h1>
                  <p>
                    Class aptent taciti sociosqu ad litora torquent per conubia
                    nostra, per inceptos himeClass aptent taciti sociosqu
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="FindCarriersMain">
          <div className="sponsorsLogo">
            <div className="container">
              <ul className="carrierSearch">
                <li>
                  <label>Find your carriers</label>
                </li>
                <li>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={carrierDetails.searchedText}
                      placeholder="Search by state or product"
                      onChange={(event) =>
                        setcarrierDetails({
                          ...carrierDetails,
                          searchedText: event.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </li>
                <li>
                  <Button
                    onClick={() =>
                      searchCarrierOnClickHandle(
                        carrierDetails.searchedText,
                        "search"
                      )
                    }
                  >
                    Search
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="container">
            <div className="FindCarriersByState">
              <h2>
                <span>Find Carriers By State</span>
              </h2>
              <div className="SelectedStateRadio SelectedState mt-2">
                <fieldset className="checkbox-group">
                  {carrierDetails.allStates.length ? (
                    carrierDetails.allStates.map(({ stateCode, stateId }) => (
                      <div className="checkbox" key={stateId}>
                        <label className="checkbox-wrapper">
                          <input
                            // type="checkbox"
                            type="radio"
                            name="radioBox"
                            className="checkbox-input"
                            checked={carrierDetails.searchedState === stateCode}
                            onChange={() =>
                              searchByStateOnChangeHandle(stateCode, "")
                            }
                          />
                          <span className="checkbox-tile">
                            <span className="checkbox-label">{stateCode}</span>
                          </span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <div style={{ fontWeight: "bold" }}>No Data Found</div>
                  )}
                </fieldset>
              </div>
            </div>
            <div className="SomeOfOurCarriers">
              <h2>Some of our carriers</h2>
              <div className="carriers_item">
                <ul>
                  {carrierDetails.allCarrier.length ? (
                    carrierDetails.allCarrier.map(
                      ({ carrierId, carrierName, carrierLogo }) => (
                        <li key={carrierId}>
                          <div className="image_container">
                            <img
                              src={carrierLogo}
                              loading="lazy"
                              alt="Logo"
                              width="100%"
                            />
                          </div>
                          <div className="carriers_item_name">
                            {carrierName}
                          </div>
                        </li>
                      )
                    )
                  ) : (
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Found
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </LayoutMain>
    </div>
  );
};

export default CarriersMain;
