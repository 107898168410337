import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import RiskerLogo from "../../../components/RiskerLogo/RiskerLogo";
import IMAGE_PATHS from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import ApiClient from "../../../ApiClient/ApiClient";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import { lastLocation, secondLastLocation } from "../../../Utils/Locations";
import Loader from "../../../components/Loader/Loader";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";

const SetPassword = () => {
  const [resetPassDetails, setResetPassDetails] = useState({
    password: "",
    confPassword: "",
  });

  const location = useLocation();
  const [isLoader, setisLoader] = useState(false);
  const navigate = useNavigate();
  const { http } = ApiClient();
  const handleResetPassDetails = (event) => {
    setResetPassDetails({
      ...resetPassDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleResetPassOnClick = async () => {
    let { password, confPassword } = resetPassDetails;
    if (!password || !confPassword) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "OK",
      });
    }
    if (password !== confPassword) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Password doesn't match!",
        confirmButtonText: "OK",
      });
    }

    try {
      setisLoader(true);
      let res = {};
      if (secondLastLocation(location) === "reset") {
        res = await http.put(`/resetUserPassword`, {
          userId: lastLocation(location),
          password: resetPassDetails.password,
        });
      } else {
        res = await http.put(`/forgotUserPassword`, {
          userId: lastLocation(location),
          password: resetPassDetails.password,
        });
      }

      Swal.fire({
        icon: `${res?.data?.isReset === 1 ? "warning" : "success"}`,
        // title: "Oops...",
        text:
          res?.data?.isReset === 1
            ? "Password already reset!"
            : "Password saved successfully!",
        confirmButtonText: "OK",
      }).then(() => {
        navigate(`${process.env.REACT_APP_BASE_URL}`);
      });
      setisLoader(false);
    } catch (error) {
      setisLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  return (
    <>
      {isLoader && <Loader />}
      <div className="loginBoxOuter">
        <div className="container-fluid container_outer_01">
          <div className="container">
            <div className="loginBox">
              <div className="left">
                <div className="logo_login">
                  <div className="login_top">
                    <RiskerLogo />
                    <h1 style={{ marginTop: "20px", marginBottom: "5px" }}>
                      {secondLastLocation(location) === "reset"
                        ? "Reset"
                        : "Forgot"}{" "}
                      Password
                    </h1>
                  </div>

                  <Form>
                    <div className="row">
                      <Form.Group className="mb_12" controlId="formBasicEmail">
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={resetPassDetails.password}
                          name="password"
                          onChange={(event) => handleResetPassDetails(event)}
                        />
                      </Form.Group>
                    </div>
                    <div className="row password_div">
                      <Form.Group
                        className="mb_12"
                        controlId="formBasicPassword"
                      >
                        <div className="password_box">
                          <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            value={resetPassDetails.confPassword}
                            name="confPassword"
                            onChange={(event) => handleResetPassDetails(event)}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="row">
                      <div className="buttn_row">
                        <Button
                          variant="primary"
                          type="button"
                          className={`login sign_in_button`}
                          onClick={() => handleResetPassOnClick()}
                        >
                          Reset Password
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="right">
                <img
                  src={IMAGE_PATHS.login_img}
                  loading="lazy"
                  alt="Logo"
                  width={"100%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetPassword;
