import React, { useEffect, useState } from "react";
import LayoutMain from "../UI/LayoutMain/LayoutMain";
import IMAGE_PATHS from "../../../constants";
import { Button } from "react-bootstrap";

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useNavigate } from "react-router-dom";
import { MainPostCalls } from "../../../ApiClient/PostCall";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";
import { convertPngTosvg } from "../../../components/FunctionHelper";

const Home = () => {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [allDetails, setAllDetails] = useState({
    allCarriers: [],
    allProducts: [],
    allReviews: [],
  });
  const { http } = ApiClient();
  const getAllCarrier = async () => {
    let data = await MainPostCalls.getAllCarriers(http, setIsLoader, Swal);

    getAllReview();
    getAllProduct();

    setAllDetails((prevDetails) => ({
      ...prevDetails,
      allCarriers: data,
    }));
  };

  const getAllProduct = async () => {
    let data = await MainPostCalls.getProduct(http, setIsLoader, Swal);
    let product = convertPngTosvg(data);

    setAllDetails((prevDetails) => ({
      ...prevDetails,
      allProducts: product,
    }));
  };

  const getAllReview = async () => {
    let data = await MainPostCalls.getReview(http, setIsLoader, Swal);

    setAllDetails((prevDetails) => ({
      ...prevDetails,
      allReviews: data,
    }));
  };

  useEffect(() => {
    getAllCarrier();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {isLoader && <Loader />}
      <LayoutMain>
        <div>
          <div className="mainBanner">
            <img
              src={IMAGE_PATHS.main_banner_home}
              loading="lazy"
              alt="Logo"
              width={""}
            />
            <div className="container mainBannerContainer">
              <h1>Discover the Best Carrier for your Customer</h1>
              <Button
                className="Book_a_Demo"
                onClick={() =>
                  navigate(`${process.env.REACT_APP_BASE_URL_MAIN}/BookADemo`)
                }
              >
                Book a Demo
              </Button>
            </div>
          </div>

          <div className="OurCoverages">
            <div className="sponsorsLogo">
              <ul>
                {allDetails.allCarriers.length ? (
                  allDetails.allCarriers.map(
                    ({ carrierId, carrierLogo, carrierName }, ind) =>
                      ind < 6 && (
                        <li key={carrierId}>
                          <img
                            style={{ cursor: "pointer" }}
                            src={carrierLogo}
                            loading="lazy"
                            alt="Logo"
                            // width="40px"
                            height={"50px"}
                            onClick={() =>
                              navigate(
                                `${process.env.REACT_APP_BASE_URL_MAIN}/CarriersMain`
                              )
                            }
                          />
                          {/* <p>{carrierName}</p> */}
                        </li>
                      )
                  )
                ) : (
                  <div>No Data Found</div>
                )}

                {/* <li>
                  <img
                    src={IMAGE_PATHS.sponsore_logo_02}
                    loading="lazy"
                    alt="Logo"
                    width={""}
                  />
                </li>
                <li>
                  <img
                    src={IMAGE_PATHS.sponsore_logo_03}
                    loading="lazy"
                    alt="Logo"
                    width={""}
                  />
                </li>
                <li>
                  <img
                    src={IMAGE_PATHS.sponsore_logo_04}
                    loading="lazy"
                    alt="Logo"
                    width={""}
                  />
                </li>
                <li>
                  <img
                    src={IMAGE_PATHS.sponsore_logo_05}
                    loading="lazy"
                    alt="Logo"
                    width={""}
                  />
                </li>
                <li>
                  <img
                    src={IMAGE_PATHS.sponsore_logo_06}
                    loading="lazy"
                    alt="Logo"
                    width={""}
                  />
                </li> */}
              </ul>
            </div>
            <div className="container">
              <h2>Our Coverages</h2>
              <p>
                Curabitur accumsan eros sit amet feugiat lacinia. Cras luctus
                nulla eu erat hendreri
              </p>
              <div className="OurCoverages_4Ppart">
                {allDetails.allProducts.length ? (
                  allDetails.allProducts.map(
                    (
                      { productId, productName, productIcon, productBkColor },
                      ind
                    ) =>
                      ind < 4 && (
                        <ul
                          key={productId}
                          // className="ProductsMain_individualBox"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <li
                            onClick={() =>
                              navigate(
                                `${process.env.REACT_APP_BASE_URL_MAIN}/ProductsMain`
                              )
                            }
                            className="ProductsMain_individualBox_color"
                            style={{
                              backgroundColor: `${productBkColor}`,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={productIcon.productIcon}
                              loading="lazy"
                              alt="Logo"
                              width={""}
                              className="imageColorWhite"
                            />
                          </li>
                          <li className="head">{productName}</li>
                          <li className="bodyText">
                            Nulla auctor justo a consectetur porttitor. Orci
                            varius natoque penatibus et magnis
                          </li>
                        </ul>
                      )
                  )
                ) : (
                  <div>No Data Found</div>
                )}

                {/* <ul>
                  <li>
                    <img
                      src={IMAGE_PATHS.Auto_our_Coverages}
                      loading="lazy"
                      alt="Logo"
                      width={""}
                    />
                  </li>
                  <li className="head">Home</li>
                  <li className="bodyText">
                    Nulla auctor justo a consectetur porttitor. Orci varius
                    natoque penatibus et magnis
                  </li>
                </ul>
                <ul>
                  <li>
                    <img
                      src={IMAGE_PATHS.DwellingFire_our_Coverages}
                      loading="lazy"
                      alt="Logo"
                      width={""}
                    />
                  </li>
                  <li className="head">Home</li>
                  <li className="bodyText">
                    Nulla auctor justo a consectetur porttitor. Orci varius
                    natoque penatibus et magnis
                  </li>
                </ul>
                <ul>
                  <li>
                    <img
                      src={IMAGE_PATHS.ManufacturedHome_our_Coverages}
                      loading="lazy"
                      alt="Logo"
                      width={""}
                    />
                  </li>
                  <li className="head">Home</li>
                  <li className="bodyText">
                    Nulla auctor justo a consectetur porttitor. Orci varius
                    natoque penatibus et magnis
                  </li>
                </ul> */}
              </div>
              <div className="button_area">
                <Button
                  className="Find_out_more"
                  onClick={() =>
                    navigate(
                      `${process.env.REACT_APP_BASE_URL_MAIN}/ProductsMain`
                    )
                  }
                >
                  Find out more
                </Button>
              </div>
            </div>
          </div>

          <div className="Discover_why_outer">
            <div className="Discover_why_left">
              <div className="Discover_why_left_content_area">
                <h2>Discover why they choose to trust Risker</h2>
                <Button className="ReadAllReviews">Read All Reviews</Button>
              </div>
            </div>
            <div className="Discover_why_right">
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={20}
                slidesPerView={2.6}
                slidesPerGroup={2}
                // navigation
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                className="Discover_why_swiper"
                breakpoints={{
                  // when window width is >= 375
                  375: {
                    //width: 640,
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  // when window width is >= 375
                  414: {
                    //width: 414,
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  // when window width is >= 640px
                  640: {
                    //width: 640,
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    //width: 768,
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                  1024: {
                    //width: 768,
                    slidesPerView: 2.5,
                    slidesPerGroup: 2,
                  },
                }}
              >
                {allDetails.allReviews.length ? (
                  allDetails.allReviews.map(
                    ({
                      reviewEntityId,
                      reviewerName,
                      reviewMessage,
                      reviewNumber,
                      reviewDate,
                    }) => {
                      const nameWords = reviewerName.split(" ");
                      const initials = nameWords.map((word) => word[0]);
                      const abbreviatedName = initials.join("");

                      return (
                        <SwiperSlide key={reviewEntityId}>
                          <div className="SwiperSlideContent">
                            <ul>
                              <li>
                                <div className="purple">{abbreviatedName}</div>
                              </li>
                              <li>
                                <p className="name">{reviewerName}</p>
                                <p className="date">{reviewDate}</p>
                              </li>
                            </ul>
                            <p className="bodyText">{reviewMessage}</p>
                            <p>
                              {Array.from({ length: reviewNumber }, (v, i) => (
                                <img
                                  key={i}
                                  src={IMAGE_PATHS.star_fill}
                                  loading="lazy"
                                  alt="Star"
                                  width={24}
                                  height={24}
                                />
                              ))}
                            </p>
                          </div>
                        </SwiperSlide>
                      );
                    }
                  )
                ) : (
                  <div style={{ fontWeight: "bold" }}>No Data Found</div>
                )}
              </Swiper>
            </div>
          </div>

          <div className="map_area">
            <div className="container">
              <ul>
                <li>
                  <img
                    src={IMAGE_PATHS.map}
                    loading="lazy"
                    alt="Logo"
                    width={""}
                    className="map_image"
                  />
                </li>
                <li className="map_area_content">
                  <div>
                    <h3>States We Serve</h3>
                    <p>
                      Our team of knowledgeable sales agents across the country
                      are experts at finding the right coverage for your needs.
                    </p>
                    <Button
                      className="Find_out_more"
                      onClick={() =>
                        navigate(
                          `${process.env.REACT_APP_BASE_URL_MAIN}/CarriersMain`
                        )
                      }
                    >
                      Find out More
                    </Button>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="how_it_works">
            <div className="container">
              <div className="how_it_works_content_area">
                <div className="left">
                  <h3>How it works</h3>
                  <p>Aenean pharetra sem a nulla ultrices tincidunntesque </p>
                  <ul>
                    <li>
                      <span>1</span>
                    </li>
                    <li>
                      Aenean pharetra sem a nulla ultrices tincidunt.
                      Pellentesque habitant morbi{" "}
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>2</span>
                    </li>
                    <li>
                      Class aptent taciti sociosqu ad litora torquent per
                      conubiaodio viverr{" "}
                    </li>
                  </ul>
                  <ul className="last">
                    <li>
                      <span>3</span>
                    </li>
                    <li>
                      Maecenas facilisis urna non tortor molestie, vitae
                      fringilla nulla pretium
                    </li>
                  </ul>
                  <Button
                    className="Book_a_Demo"
                    onClick={() =>
                      navigate(
                        `${process.env.REACT_APP_BASE_URL_MAIN}/BookADemo`
                      )
                    }
                  >
                    Book a Demo
                  </Button>
                </div>
                <div className="right">
                  <img
                    src={IMAGE_PATHS.how_it_work_img}
                    loading="lazy"
                    alt="Logo"
                    width={""}
                    className="how_it_work_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutMain>
    </div>
  );
};

export default Home;
