import React, { useEffect, useState } from "react";
import LayoutAgent from "./LayoutAgent/LayoutAgent";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import IMAGE_PATHS from "../../constants";
import { Link, useNavigate } from "react-router-dom";
import ApiClient from "../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  CartesianGrid,
} from "recharts";
import { getMonthAbbreviation } from "../../components/ChartsUtils/ChartsUtils";
import { isLoginAgent } from "../../components/IsLogin/IsLogin";
import moment from "moment";

const AgentDashoard = () => {
  const [searchDetails, setSearchDetails] = useState({
    customerName: "",
    product: { proName: "", proId: "" },
    state: { stateName: "", stateId: "" },

    allStates: [],
    searchAndCarrierData: {},
    connectedCarrierList: [],
    productList: [],
  });
  const [reportLists, setReportLists] = useState({
    monthAndProductWise: [],
    productWise: [],
  });

  const [isLoader, setisLoader] = useState(false);
  const [stateShow, setStateShow] = useState(false);
  const [recentStates, setRecentStates] = useState([]);
  const navigate = useNavigate();
  const { http } = ApiClient();

  const handleClose = () => {
    setStateShow(false);
  };

  const stateModalOnClickHandle = () => {
    setStateShow(false);
  };

  const handleSearchCarrierButtonOnClick = () => {
    if (
      !searchDetails.customerName ||
      !searchDetails.product.proName ||
      !searchDetails.state.stateName
    ) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }
    let agentDashboardSearchDetails = {
      customerName: searchDetails.customerName,
      product: searchDetails.product,
      state: searchDetails.state,
    };
    sessionStorage.setItem(
      "agentDashboardSearchDetails",
      JSON.stringify(agentDashboardSearchDetails)
    );

    navigate(`${process.env.REACT_APP_BASE_URL}/AgentDashboardSearch`);
  };

  const handleCarrierSearchOnChange = (event) => {
    let { name, value, id } = event.target;
    if (name === "customerName") {
      setSearchDetails({ ...searchDetails, [name]: value });
    }
    if (name === "state") {
      setSearchDetails({
        ...searchDetails,
        state: { stateName: value, stateId: +id },
      });
    }
    if (name === "product") {
      setSearchDetails({
        ...searchDetails,
        product: { proName: value, proId: +event.target.selectedOptions[0].id },
      });
    }
  };

  const getAllState = () => {
    setisLoader(true);
    http
      .get("/allState", {})
      .then((res) => {
        setSearchDetails({
          ...searchDetails,
          allStates: res.data,
        });
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };
  const getAllStatesWithRecent = () => {
    setisLoader(true);
    http
      .get("/getAllSearchState", {})
      .then((res) => {
        if (res.status === 200) {
          setRecentStates(res.data);
        }
      })
      .catch((error) => {
        isLoginAgent(error, navigate, Swal);
      })
      .finally(() => setisLoader(false));
  };
  const handleShow = () => {
    getAllState();
    getAllStatesWithRecent();
    setStateShow(true);
  };

  const getLastSearch = async () => {
    try {
      setisLoader(true);
      let res = await http.get("/getLastSearchDetails");
      setSearchDetails((prevLists) => ({
        ...prevLists,
        // customerName: res.data[0]?.searchEntity?.customerName,
        state: {
          stateName: res.data[0]?.masterState?.stateCode,
          stateId: res.data[0]?.masterState?.stateId,
        },
        product: {
          proName: res.data[0]?.searchEntity?.productBuilder?.productName,
          proId: res.data[0]?.searchEntity?.productBuilder?.productId,
        },
      }));
      setisLoader(false);
    } catch (error) {
      setisLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  const getAllProduct = () => {
    setisLoader(true);
    http
      .get("/getAllConnectedProduct", {})
      .then((res) => {
        setSearchDetails((prevViewAgencyDetails) => ({
          ...prevViewAgencyDetails,
          productList: res.data,
        }));

        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  const getSearchCarriersNumber = () => {
    setisLoader(true);
    http
      .get("/getNumberOfSearchAndCarrier", {})
      .then((res) => {
        setSearchDetails((prevViewAgencyDetails) => ({
          ...prevViewAgencyDetails,
          searchAndCarrierData: res.data,
        }));

        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  const connectedCarriers = () => {
    setisLoader(true);
    http
      .get(`/connected`, {})
      .then((res) => {
        setSearchDetails((prevCarriers) => ({
          ...prevCarriers,
          connectedCarrierList: res.data,
        }));
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  // chart showing start

  const getMonthAndProductWiseSearch = async () => {
    try {
      setisLoader(true);
      const res = await http.get(`/searchByMonth`);

      setReportLists((prevLists) => ({
        ...prevLists,
        monthAndProductWise: res.data,
      }));
      setisLoader(false);
    } catch (error) {
      setisLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };
  const getProductWiseSearch = async (startDate, endDate) => {
    try {
      setisLoader(true);
      const res = await http.post(`/searchUserByProduct`);
      setReportLists((prevLists) => ({
        ...prevLists,
        productWise: res.data,
      }));
      setisLoader(false);
    } catch (error) {
      setisLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getMonthAndProductWiseSearch();
    getProductWiseSearch();

    return () => {};
    // eslint-disable-next-line
  }, []);

  // chart showing end

  useEffect(() => {
    sessionStorage.removeItem("agentDashboardSearchDetails");
    localStorage.removeItem("paymentData");

    connectedCarriers();
    getLastSearch();
    getAllProduct();
    getSearchCarriersNumber();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutAgent sideBarActive={21}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer">
        <div className="searchBar">
          <Form>
            <ul>
              <li>Searching for Carriers</li>
              <li>
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Customer Name"
                    value={searchDetails.customerName || ""}
                    name="customerName"
                    onChange={(event) => handleCarrierSearchOnChange(event)}
                  />
                </Form.Group>
              </li>
              <li>
                <Form.Select
                  aria-label="Default select example"
                  name="product"
                  onChange={(event) => handleCarrierSearchOnChange(event)}
                >
                  <option hidden>
                    {searchDetails?.product?.proName || "Select Product"}
                  </option>
                  {searchDetails.productList.length > 0 ? (
                    searchDetails.productList.map(
                      ({ productId, productName }) => (
                        <option
                          key={productId}
                          value={productName}
                          id={productId}
                        >
                          {productName}
                        </option>
                      )
                    )
                  ) : (
                    <option>No Data Found</option>
                  )}
                </Form.Select>
              </li>
              <li>
                <Form.Select
                  aria-label="Default select example"
                  name="state"
                  onClick={handleShow}
                >
                  <option hidden>
                    {searchDetails.state.stateName
                      ? searchDetails.state.stateName
                      : "Select State"}
                  </option>
                </Form.Select>
              </li>
              <li>
                <Button
                  className="searchButton"
                  onClick={() => handleSearchCarrierButtonOnClick()}
                >
                  Next
                </Button>
              </li>
            </ul>
          </Form>
        </div>
        <div className="head_bread mt-4 mb-3">
          <div className="ReportDate">
            <h1>Dashboard</h1>
          </div>
        </div>
        <div className="AdminDashboardFourBox">
          <ul>
            <li
              className="whiteBoxShadow"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`${process.env.REACT_APP_BASE_URL}/SearchHistoryAgent`)
              }
            >
              <div className="twoPartCommon">
                <div>
                  <ul className="AdminDashboardFourUL">
                    <li className="AdminDashboardFourBoxHead">
                      Number of Search
                    </li>
                    <li className="AdminDashboardFourBoxNumber">
                      {searchDetails?.searchAndCarrierData?.search || 0}
                    </li>
                  </ul>
                </div>
                <div>
                  <img
                    src={IMAGE_PATHS.na_icon_01}
                    loading="lazy"
                    alt="Logo"
                    width={"60px"}
                  />
                </div>
              </div>
            </li>
            <li
              className="whiteBoxShadow"
              style={{ cursor: "pointer" }}
              onClick={() => {
                sessionStorage.setItem("tab", "Connected");
                navigate(`${process.env.REACT_APP_BASE_URL}/CarriersAgent`);
              }}
            >
              <div className="twoPartCommon">
                <div>
                  <ul className="AdminDashboardFourUL">
                    <li className="AdminDashboardFourBoxHead">
                      Connected Carrier
                    </li>
                    <li className="AdminDashboardFourBoxNumber">
                      {searchDetails?.searchAndCarrierData?.connectedCarrier ||
                        0}
                    </li>
                  </ul>
                </div>
                <div>
                  <img
                    src={IMAGE_PATHS.as_icon_02}
                    loading="lazy"
                    alt="Logo"
                    width={"60px"}
                  />
                </div>
              </div>
            </li>
            <li
              className="whiteBoxShadow"
              style={{ cursor: "pointer" }}
              onClick={() => {
                sessionStorage.setItem(
                  "todaySearch",
                  JSON.stringify({
                    date: [
                      moment().format("YYYY-MM-DD"),
                      moment().format("YYYY-MM-DD"),
                    ],
                  })
                );

                navigate(
                  `${process.env.REACT_APP_BASE_URL}/SearchHistoryAgent`
                );
              }}
            >
              <div className="twoPartCommon">
                <div>
                  <ul className="AdminDashboardFourUL">
                    <li className="AdminDashboardFourBoxHead">
                      Today's Search
                    </li>
                    <li className="AdminDashboardFourBoxNumber">
                      {searchDetails?.searchAndCarrierData?.todaySearch || 0}
                    </li>
                  </ul>
                </div>
                <div>
                  <img
                    src={IMAGE_PATHS.ac_icon_03}
                    loading="lazy"
                    alt="Logo"
                    width={"60px"}
                  />
                </div>
              </div>
            </li>
            <li className="whiteBoxShadow">
              <div className="twoPartCommon">
                <div>
                  <ul className="AdminDashboardFourUL">
                    <li className="AdminDashboardFourBoxHead">
                      Rating Pending
                    </li>
                    <li className="AdminDashboardFourBoxNumber">0</li>
                  </ul>
                </div>
                <div>
                  <img
                    src={IMAGE_PATHS.ip_icon_05}
                    loading="lazy"
                    alt="Logo"
                    width={"60px"}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="AdminDashboardThreeBox">
          <div className="whiteBoxShadow">
            <h3>Month Wise Search</h3>
            {reportLists.monthAndProductWise.length ? (
              <Link to={`${process.env.REACT_APP_BASE_URL}/ReportDetailsAgent`}>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={reportLists.monthAndProductWise}>
                    <XAxis
                      dataKey="month"
                      tickFormatter={getMonthAbbreviation}
                      axisLine={false}
                      tickLine={false}
                    />
                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      domain={[0, reportLists.monthAndProductWise.length + 8]}
                    />
                    <CartesianGrid vertical={false} />
                    <Bar dataKey="count" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Link>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
          <div className="whiteBoxShadow">
            <h3>Product Wise Search</h3>
            {reportLists.productWise.length ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={reportLists.productWise}
                  // barSize={20}
                >
                  <XAxis
                    dataKey="productName"
                    interval={"preserveStartEnd"}
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontSize: "10px",
                    }}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontSize: "10px",
                    }}
                    domain={[0, reportLists.productWise.length + 8]}
                  />
                  <CartesianGrid vertical={false} />

                  <Bar dataKey="count">
                    {reportLists.productWise.map((entry, ind) => (
                      <Cell fill={entry.color} key={ind} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
          <div className="whiteBoxShadow">
            <div className="twoPartCommon align-items-center mb_12">
              <h3 className="mb-0">Connected Carriers</h3>
              {/* <Button className="button_blueBorder_small ">dd</Button> */}
            </div>
            <div className="cc_icons">
              {searchDetails?.connectedCarrierList?.map(
                ({ carrier }, ind) =>
                  ind < 6 && (
                    <div className="cc_icons_box" key={carrier.carrierId}>
                      <img
                        src={carrier.carrierLogo}
                        loading="lazy"
                        alt="Logo"
                        width={100}
                      />
                    </div>
                  )
              )}
              {searchDetails?.connectedCarrierList.length === 0 && (
                <div>No Data Found</div>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={stateShow}
          onHide={handleClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body newStateModal">
              <div className="row">
                <div className="twoPartCommon align-items-center mb-3">
                  <div className="left" style={{ fontWeight: 600 }}>
                    Change States
                  </div>
                  <div className="right">
                    <img
                      src={IMAGE_PATHS.Close_modal}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      onClick={handleClose}
                      className="cursorPointer"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {isLoader && <Loader />}
                {recentStates?.length ? (
                  <div>Recent search states</div>
                ) : (
                  <div>No Data Found</div>
                )}
                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group newStateModalOne">
                      {recentStates?.map(({ stateId, stateCode }) => (
                        <div className="checkbox" key={stateId}>
                          <label className="checkbox-wrapper">
                            <input
                              type="radio"
                              className="checkbox-input"
                              name="state"
                              id={stateId}
                              value={stateCode}
                              onChange={(event) =>
                                handleCarrierSearchOnChange(event)
                              }
                            />
                            <span className="checkbox-tile">
                              <span className="checkbox-label">
                                {stateCode}
                              </span>
                            </span>
                          </label>
                        </div>
                      ))}
                    </fieldset>
                  </div>
                </div>
                <div>Other states</div>

                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group newStateModalOne">
                      {searchDetails.allStates.length ? (
                        searchDetails.allStates.map(
                          ({ stateId, stateCode }) => (
                            <div
                              className="checkbox"
                              key={stateId}
                              style={{
                                display: recentStates?.find((obj) => {
                                  return obj?.stateCode === stateCode;
                                })
                                  ? "none"
                                  : "block",
                              }}
                            >
                              <label className="checkbox-wrapper">
                                <input
                                  type="radio"
                                  className="checkbox-input"
                                  name="state"
                                  id={stateId}
                                  value={stateCode}
                                  onChange={(event) =>
                                    handleCarrierSearchOnChange(event)
                                  }
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">
                                    {stateCode}
                                  </span>
                                </span>
                              </label>
                            </div>
                          )
                        )
                      ) : (
                        <div>No Data Found</div>
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="submit"
                  className="login sign_in_button"
                  onClick={() => stateModalOnClickHandle()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </LayoutAgent>
  );
};

export default AgentDashoard;
