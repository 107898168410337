import React from "react";
import Loader from "./Loader";
import "./Loader.css";

export default function MyLoader({ loading }) {
  return (
    <div style={{ display: loading ? "block" : "none" }}>
      <Loader />
    </div>
  );
}
