import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import ApiClient from "../../ApiClient/ApiClient";
import MyLoader from "../../components/Loader/myLoader";

export default function SelectOption({ setSelectedProduct }) {
  const [productsList, setProductsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const { http } = ApiClient();

  const getProducts = async () => {
    try {
      setLoading(true);
      let res = await http.get("/getAllProduct");
      setProductsList(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message || "Sorry, Something went wrong",
        confirmButtonText: "CANCEL",
      });
    }
  };

  useEffect(() => {
    getProducts();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <MyLoader loading={loading} />
      <Form.Select
        aria-label="Default select example"
        value={
          productsList.filter((el) => el?.productId === selected)[0]?.prouctId
        }
        name="category"
        onChange={(e) => {
          setSelectedProduct(Number(e.target.value));
          setSelected(Number(e.target.value));
        }}
      >
        <option hidden>Select Product</option>
        {productsList?.map((prod) => (
          <option
            // selected={prod?.productId === selected}
            key={prod?.productId}
            value={prod?.productId}
          >
            {prod?.productName}
          </option>
        ))}
        {/* <option>Home</option>
      <option>Dwelling Fire</option>
      <option>Manufactured Home</option> */}
      </Form.Select>
    </>
  );
}
