import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import RiskerLogo from "../../../components/RiskerLogo/RiskerLogo";
import LargeButton from "../../../components/Buttons/LargeButton";
import FbButton from "../../../components/Buttons/FbButton";
import GoogleButton from "../../../components/Buttons/GoogleButton";
import IMAGE_PATHS from "../../../constants";

const SignUp = () => {
  const [signupdetails, setsignupdetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    orgName: "",
    password: "",
    confPassword: "",
  });
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [isConfPasswordOpen, setIsConfPasswordOpen] = useState(false);

  const handleSignupDetailsOnChange = (event) => {
    event?.preventDefault();
    const { name, value } = event?.target;
    setsignupdetails({
      ...signupdetails,
      [name]: value,
    });
  };

  const eyeOpenCloseHandle = (type) => {
    if (type === "password") {
      setIsPasswordOpen((prev) => !prev);
    }
    if (type === "confPassword") {
      setIsConfPasswordOpen((prev) => !prev);
    }
  };

  return (
    <div className="loginBoxOuter">
      <div className="container-fluid container_outer_01">
        <div className="container">
          <div className="loginBox">
            <div className="left">
              <div className="logo_login">
                <div className="login_top">
                  <RiskerLogo />
                  <h3>Sign up</h3>
                  <p>Welcome & Join us by creating your account !</p>
                </div>
                <Form>
                  <div className="row">
                    <Form.Group className="mb_12">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        value={signupdetails.firstName}
                        name="firstName"
                        onChange={(event) => handleSignupDetailsOnChange(event)}
                      />
                    </Form.Group>
                  </div>
                  <div className="row">
                    <Form.Group className="mb_12">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        value={signupdetails.lastName}
                        name="lastName"
                        onChange={(event) => handleSignupDetailsOnChange(event)}
                      />
                    </Form.Group>
                  </div>
                  <div className="row">
                    <Form.Group className="mb_12">
                      <Form.Control
                        type="text"
                        placeholder="Email ID"
                        value={signupdetails.email}
                        name="email"
                        onChange={(event) => handleSignupDetailsOnChange(event)}
                      />
                    </Form.Group>
                  </div>
                  <div className="row">
                    <Form.Group className="mb_12">
                      <Form.Control
                        type="text"
                        placeholder="Organization Name"
                        value={signupdetails.orgName}
                        name="orgName"
                        onChange={(event) => handleSignupDetailsOnChange(event)}
                      />
                    </Form.Group>
                  </div>

                  <div className="row password_div">
                    <InputGroup className="mb_12">
                      <Form.Control
                        placeholder="Password"
                        aria-label="Password"
                        type={isPasswordOpen ? "text" : "password"}
                        aria-describedby="basic-addon2"
                        value={signupdetails.password}
                        name="password"
                        onChange={(event) => handleSignupDetailsOnChange(event)}
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon1"
                        onClick={() => eyeOpenCloseHandle("password")}
                      >
                        {isPasswordOpen ? (
                          <img
                            src={IMAGE_PATHS.eye_open}
                            loading="lazy"
                            alt="Logo"
                            width={""}
                          />
                        ) : (
                          <img
                            src={IMAGE_PATHS.eye_close}
                            loading="lazy"
                            alt="Logo"
                            width={""}
                          />
                        )}
                      </Button>
                    </InputGroup>
                  </div>

                  <div className="row password_div">
                    <InputGroup className="mb_12">
                      <Form.Control
                        placeholder="Confirm Password"
                        aria-label="Confirm Password"
                        aria-describedby="basic-addon2"
                        type={isConfPasswordOpen ? "text" : "password"}
                        value={signupdetails.confPassword}
                        name="confPassword"
                        onChange={(event) => handleSignupDetailsOnChange(event)}
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={() => eyeOpenCloseHandle("confPassword")}
                      >
                        {isConfPasswordOpen ? (
                          <img
                            src={IMAGE_PATHS.eye_open}
                            loading="lazy"
                            alt="Logo"
                            width={""}
                          />
                        ) : (
                          <img
                            src={IMAGE_PATHS.eye_close}
                            loading="lazy"
                            alt="Logo"
                            width={""}
                          />
                        )}
                      </Button>
                    </InputGroup>
                  </div>

                  <div className="row">
                    <div className="buttn_row">
                      <LargeButton
                        buttonLabel={"Create Account"}
                        authPayload={signupdetails}
                      />
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="cal-12">
                      <div className="or ">
                        <span className="light_gray_text">OR Sign in with</span>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="cal-12 fb_google">
                      <FbButton />
                      <GoogleButton socialLoginValue={"Sign up"} />
                    </div>
                  </div> */}
                  <div className="row">
                    <p className="register">
                      Don't have an account?{" "}
                      <Link
                        to={`${process.env.REACT_APP_BASE_URL}`}
                        className="blueLink"
                      >
                        Login
                      </Link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
            <div className="right">
              <img
                src={IMAGE_PATHS.login_img}
                loading="lazy"
                alt="Logo"
                width={"100%"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
