import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import MyLoader from "../../../components/Loader/myLoader";
import SelectOption from "../../SelectOption/ProductSelectOption";
import moment from "moment";
import { DatePicker } from "antd";
import { sortArray } from "../../../Utils/helper";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import Paginations from "../../../components/Pagination";
import { downloadCsv } from "../../../components/CsvDownload/CsvDownload";
// import CommonMonthlySearchHistory from "../../MonthlyReports/monthlyReports";

const { RangePicker } = DatePicker;

const MonthSearchHistory = () => {
  const [history, setHistory] = useState({ data: [], count: 0, csvFile: "" });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchdata, setSearchdata] = useState({
    customerName: "",
    startDate: "",
    endDate: "",
  });
  const [selectedProd, setSelectedProd] = useState(null);
  const [page, setPage] = useState(1);
  const [sortData, setSortData] = useState({ name: "", order: "" });

  const { http } = ApiClient();

  const getMonthllyHistoryData = async (payload = false) => {
    try {
      setLoading(true);

      let reqPayload = {
        productId: selectedProd,
        ...searchdata,
      };

      if (payload) {
        setPage(1);
      }

      const res = await http.post(
        `/getMonthWiseSearchHistoryDetailsAdmin/${payload ? 1 : page}`,
        payload ? reqPayload : null
      );

      setHistory({
        ...history,
        data: res?.data?.data?.map((el) => {
          return { ...el, userNameSpecial: el?.userName?.toLowerCase() };
        }),
        count: res?.data?.count,
        csvFile: res.data.csvFile,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      isLogin(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getMonthllyHistoryData();
    return () => {};
    // eslint-disable-next-line
  }, [page]);

  const sortHistoryData = (name, order) => {
    setHistory({
      ...history,
      data: sortArray(
        history.data,
        name === "userName" ? "userNameSpecial" : name,
        order
      ),
    });
  };

  useEffect(() => {
    if (sortData.name && sortData.order) {
      sortHistoryData(sortData.name, sortData.order);
    }
    return () => {};
    // eslint-disable-next-line
  }, [sortData]);

  return (
    <Layout sideBarActive={7}>
      <MyLoader loading={loading} />
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/Report`}>
              <img src={IMAGE_PATHS.ep_back} loading="lazy" alt="Logo" />
            </Link>
            Month Wise Search History
          </h1>
          <div className="r">
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Reports</span>
          </div>
        </div>

        <div className="mt-3 sideBySideBoxLeftRight">
          <div className="searchHistoryFormBar left">
            <div>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Customer Name"
                  name="customerName"
                  value={searchdata.customerName}
                  onChange={(e) => {
                    setSearchdata({
                      ...searchdata,
                      customerName: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </div>
            <div>
              <SelectOption setSelectedProduct={setSelectedProd} />
            </div>
            <div className="dateButtonContainer" style={{ width: "650px" }}>
              <RangePicker
                onChange={(event) => {
                  setSearchdata({
                    ...searchdata,
                    startDate: event
                      ? moment(event[0]["$d"]).format("YYYY-MM-DD")
                      : "",
                    endDate: event
                      ? moment(event[1]["$d"]).format("YYYY-MM-DD")
                      : "",
                  });
                }}
                className="AgentRangePicker"
              />
            </div>
            <div>
              <Button
                className="button_blue"
                onClick={() => {
                  getMonthllyHistoryData(true);
                }}
              >
                <img
                  aria-hidden="true"
                  src={IMAGE_PATHS.search_icon_white}
                  loading="lazy"
                  alt="Logo"
                  width={"13px"}
                />
                Search
              </Button>
            </div>
          </div>
          <div className="report_right_button right">
            {/* <Button className="redBorderButton">View</Button> */}
            <Button
              className="button_redBorder"
              onClick={() => downloadCsv(history.csvFile)}
            >
              View
            </Button>
          </div>
        </div>

        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>
                  <span className="shorting_container">
                    Customer Name
                    <span className="shorting">
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "customerName", order: "asc" });
                        }}
                        src={IMAGE_PATHS.up_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "customerName", order: "desc" });
                        }}
                        src={IMAGE_PATHS.down_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    Month
                    <span className="shorting">
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "month", order: "asc" });
                        }}
                        src={IMAGE_PATHS.up_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "month", order: "desc" });
                        }}
                        src={IMAGE_PATHS.down_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    User Name
                    <span className="shorting">
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "userName", order: "asc" });
                        }}
                        src={IMAGE_PATHS.up_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "userName", order: "desc" });
                        }}
                        src={IMAGE_PATHS.down_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    Created on
                    <span className="shorting">
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "createdOn", order: "asc" });
                        }}
                        src={IMAGE_PATHS.up_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "createdOn", order: "desc" });
                        }}
                        src={IMAGE_PATHS.down_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    Last Update
                    <span className="shorting">
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "lastUpdated", order: "asc" });
                        }}
                        src={IMAGE_PATHS.up_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "lastUpdated", order: "desc" });
                        }}
                        src={IMAGE_PATHS.down_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span className="shorting_container">
                    Product
                    <span className="shorting">
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "productName", order: "asc" });
                        }}
                        src={IMAGE_PATHS.up_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                      <img
                        aria-hidden="true"
                        onClick={() => {
                          setSortData({ name: "productName", order: "desc" });
                        }}
                        src={IMAGE_PATHS.down_arrow}
                        loading="lazy"
                        alt="Logo"
                        width={"14px"}
                      />
                    </span>
                  </span>
                </th>
                <th className="tdWidth100">
                  <span className="shorting_container">Matched Carriers</span>
                </th>
                <th>
                  <span className="shorting_container">Reviewed Carrier</span>
                </th>
                <th>
                  <span className="shorting_container">Confirmed Carrier</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {history?.data?.map((el, ind) => (
                <tr key={ind}>
                  <td>{el?.customerName}</td>
                  <td>{moment(`2000-${el?.month}-11`).format("MMMM")}</td>
                  <td>{el?.userName}</td>
                  <td>{moment(el?.createdOn).format("DD/MM/YYYY")}</td>
                  <td>{moment(el?.lastUpdated).format("DD/MM/YYYY")}</td>
                  <td>{el?.productName}</td>
                  <td className="tdCenter">{el?.matchedCarrier}</td>
                  <td>
                    {el?.reviwedCarrier?.length
                      ? el?.reviwedCarrier?.map((ele, idx) =>
                          idx === 0 ? `${ele}` : `, ${ele}`
                        )
                      : "NA"}
                  </td>
                  <td>{el?.confirmedCarrier[0] || "NA"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!history?.data?.length ? (
            <div style={{ textAlign: "center", marginTop: "5rem" }}>
              No Data Found
            </div>
          ) : null}
        </div>
        {history?.data?.length ? (
          <Paginations page={page} setPage={setPage} count={history.count} />
        ) : null}
      </div>
      {/* <CommonMonthlySearchHistory type="admin" /> */}
    </Layout>
  );
};

export default MonthSearchHistory;
