import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import IMAGE_PATHS from "../../../constants";
import editDeleteIcon from "../../../components/EditDelInsuranceIcon/EditDelInsuranceIcon";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { lastLocation, secondLastLocation } from "../../../Utils/Locations";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";

const HomePrpducts = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoader, setisLoader] = useState(false);

  const [addShow, setAddShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [homeInsurancedetails, sethomeinsurancedetails] = useState({
    buildYear: "",
    coverageLimit: "",
    roofType: [],
    roofYear: "",
    priorIns: [],
    question: "",
    answer: "Text",
    fixedAnswer: "Text",
    is_year_field: 0,
    isCurrentYearField: 0,
    options: [],
    productQuestsIds: 0,
    productQuestAns: [],
  });

  const handleClose = () => {
    setAddShow(false);
  };
  const handleShow = () => {
    sethomeinsurancedetails({
      ...homeInsurancedetails,
      question: "",
      answer: "Text",
      options: [],
      is_year_field: 0,
    });
    setAddShow(true);
  };

  const handleClose2 = () => {
    sethomeinsurancedetails({
      ...homeInsurancedetails,
      question: "",
      answer: "Text",
      options: [],
      is_year_field: 0,
    });

    setEditShow(false);
  };
  const { http } = ApiClient();

  const getProductQuestionOptionsById = (ids) => {
    http
      .get(
        `/getProductQuestionOption/${aesUtil.encrypt(JSON.stringify(ids))}`,
        {}
      )
      .then((res) => {
        sethomeinsurancedetails((prevProducts) => ({
          ...prevProducts,
          answer: "Option",
          options: res.data,
        }));

        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  const handleShow2 = (ids, optionsType) => {
    setisLoader(true);

    http
      .get(
        `/getProductQuestionById/${aesUtil.encrypt(JSON.stringify(ids))}`,
        {}
      )
      .then((res) => {
        sethomeinsurancedetails((prevProducts) => ({
          ...prevProducts,
          question: res.data.questionLabel,
          productQuestsIds: ids,
        }));
        if (optionsType !== "Text") {
          getProductQuestionOptionsById(ids);
        } else {
          sethomeinsurancedetails((prevProducts) => ({
            ...prevProducts,
            options: [],
            answer: "Text",
            is_year_field: res.data.isYearField,
            isCurrentYearField: res.data.isCurrentYearField,
          }));
        }

        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });

    setEditShow(true);
  };

  const handleHomeInsuranceOnChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    const items = homeInsurancedetails.roofType;
    const index = items.indexOf(value);

    if (name === "roofType") {
      if (index !== -1) {
        items.splice(index, 1);
      } else {
        items.push(value);
      }
    } else {
      sethomeinsurancedetails({
        ...homeInsurancedetails,
        [name]: value,
      });
    }
  };

  const getAllProductQuestion = () => {
    setisLoader(true);

    http
      .get(`/getProductQuestionByProductId/${lastLocation(location)}`, {})
      .then((res) => {
        sethomeinsurancedetails({
          ...homeInsurancedetails,
          productQuestAns: res.data,
        });
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  const handlehomeInsuranceAddNewCatergoryOnClick = () => {
    if (!homeInsurancedetails.question) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "OK",
      });
    }
    if (
      homeInsurancedetails.answer === "Option" &&
      !homeInsurancedetails.options.length
    ) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "OK",
      });
    }

    if (homeInsurancedetails.answer === "Text") {
      var addNewQuestRequest = {
        productId: aesUtil.decrypt(lastLocation(location)),
        fieldType: homeInsurancedetails.answer,
        questionLabel: homeInsurancedetails.question,
        isYearField: homeInsurancedetails.is_year_field,
        isCurrentYearField: homeInsurancedetails.isCurrentYearField,
      };
    } else {
      addNewQuestRequest = {
        productId: aesUtil.decrypt(lastLocation(location)),
        fieldType: homeInsurancedetails.answer,
        questionLabel: homeInsurancedetails.question,
        optionType: homeInsurancedetails.options,
      };
    }

    setisLoader(true);
    http
      .post("/saveProductQuestion", addNewQuestRequest)
      .then((res) => {
        sethomeinsurancedetails({
          ...homeInsurancedetails,
          productQuestAns: res.data,
        });
        getAllProductQuestion();
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });

    handleClose();
  };

  const addQuestionSelectOnChangeHandle = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    const items = homeInsurancedetails.options;
    const index = items.indexOf(value);

    if (event.key === "Enter" && value) {
      if (index === -1) {
        items.push(value);
      }
      event.target.value = "";
    }

    sethomeinsurancedetails({
      ...homeInsurancedetails,
      [name]: items,
    });
  };

  const deleteOptionOfAddSelectQuestion = (options) => {
    const items = homeInsurancedetails.options;
    const index = items.indexOf(options);

    if (index !== -1) {
      items.splice(index, 1);
    }
    sethomeinsurancedetails({
      ...homeInsurancedetails,
      roofType: items,
    });
  };

  const editNewCarrierHandleOnClick = () => {
    if (!homeInsurancedetails.question) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "OK",
      });
    }
    if (
      homeInsurancedetails.answer === "Option" &&
      !homeInsurancedetails.options.length
    ) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "OK",
      });
    }
    if (homeInsurancedetails.answer === "Text") {
      var updateNewQuestRequest = {
        productId: aesUtil.decrypt(lastLocation(location)),
        productQuestionId: homeInsurancedetails.productQuestsIds,
        fieldType: homeInsurancedetails.answer,
        questionLabel: homeInsurancedetails.question,
        isYearField: homeInsurancedetails.is_year_field,
        isCurrentYearField: homeInsurancedetails.isCurrentYearField,
      };
    } else {
      updateNewQuestRequest = {
        productId: aesUtil.decrypt(lastLocation(location)),
        productQuestionId: homeInsurancedetails.productQuestsIds,
        fieldType: homeInsurancedetails.answer,
        questionLabel: homeInsurancedetails.question,
        optionType: homeInsurancedetails.options,
      };
    }
    setisLoader(true);
    http
      .put("/updateProductQuestion", updateNewQuestRequest)
      .then(() => {
        getAllProductQuestion();
        handleClose2();
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  const handleDragEnd = async (results) => {
    if (!results.destination) return;
    let tempQuestAns = [...homeInsurancedetails.productQuestAns];
    let [selectedRow] = tempQuestAns.splice(results.source.index, 1);
    tempQuestAns.splice(results.destination.index, 0, selectedRow);

    const productQuestionIds = tempQuestAns.map(
      (item) => item.productQuestionId
    );

    try {
      setisLoader(true);
      await http.post("/orderBy", { data: productQuestionIds });
      setisLoader(false);
    } catch (error) {
      setisLoader(false);
      isLogin(error, navigate, Swal);
    }

    sethomeinsurancedetails({
      ...homeInsurancedetails,
      productQuestAns: tempQuestAns,
    });
  };

  const editDeleteQuestionOnClickHandle = (questionId, options, indy) => {
    if (indy === 0) {
      handleShow2(questionId, options);
    } else if (indy === 1) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setisLoader(true);
          http
            .delete(
              `/deleteProductQuestion/${aesUtil.encrypt(
                JSON.stringify(questionId)
              )}`,
              {}
            )
            .then((res) => {
              setisLoader(false);

              getAllProductQuestion();
            })
            .catch((error) => {
              setisLoader(false);
              isLogin(error, navigate, Swal);
            });
        }
      });
    }
  };

  const isThisYearOnChangeHandle = (event) => {
    let { name, value } = event.target;

    if (value === "age") {
      sethomeinsurancedetails({
        ...homeInsurancedetails,
        is_year_field: 1,
        isCurrentYearField: 0,
      });
    } else {
      sethomeinsurancedetails({
        ...homeInsurancedetails,
        is_year_field: 0,
        isCurrentYearField: 2,
      });
    }
  };

  useEffect(() => {
    getAllProductQuestion();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={2}>
      {isLoader && <Loader />}
      <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
        <div className="AdminDashboardContainer ProductsPage">
          <div className="head_bread">
            <h1>{secondLastLocation(location)} Insurance</h1>
            <div>
              <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/Products`}>
                Products
              </Link>{" "}
              <span>/ {secondLastLocation(location)} Insurance</span>
            </div>
          </div>
          <div className="AdminDashboardWhiteBody570">
            <div className="top">
              <h1>{secondLastLocation(location)} Insurance Questions</h1>
              <Button
                className="button_blueBorder_small"
                onClick={() => handleShow()}
              >
                <img
                  src={IMAGE_PATHS.phPlusBoldIcon}
                  loading="lazy"
                  alt="Logo"
                  width={"16px"}
                />
                Add New Question
              </Button>
            </div>
            <Droppable droppableId="homeProduct">
              {(provided) => (
                <div
                  className="middle"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {homeInsurancedetails.productQuestAns.length
                    ? homeInsurancedetails.productQuestAns.map((lists, indx) =>
                        lists.masterFieldType.fieldType === "Text" &&
                        !lists.isDelete ? (
                          <Draggable
                            draggableId={lists.questionLabel}
                            index={indx}
                            key={lists.questionLabel}
                          >
                            {(provided) => (
                              <div
                                className="formBox01"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {console.log(lists, "L")}
                                <div className="topPart">
                                  <h2>{lists.questionLabel}</h2>
                                  <div>
                                    {editDeleteIcon.map((icon, indy) => (
                                      <div key={indy}>
                                        {indy === 0 ? (
                                          <img
                                            loading="lazy"
                                            alt="Logo"
                                            width={"16px"}
                                            src={icon.editIcon}
                                            onClick={() =>
                                              editDeleteQuestionOnClickHandle(
                                                lists.productQuestionId,
                                                "Text",
                                                indy
                                              )
                                            }
                                          />
                                        ) : indy === 1 ? (
                                          <img
                                            loading="lazy"
                                            alt="Logo"
                                            width={"16px"}
                                            src={icon.deleteIcon}
                                            onClick={() =>
                                              editDeleteQuestionOnClickHandle(
                                                lists.productQuestionId,
                                                "Text",
                                                indy
                                              )
                                            }
                                          />
                                        ) : (
                                          <img
                                            loading="lazy"
                                            alt="Logo"
                                            width={"16px"}
                                            src={icon.shortIcon}
                                          />
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ) : lists.masterFieldType.fieldType === "Option" &&
                          lists.productBuilder.productId ===
                            +aesUtil.decrypt(lastLocation(location)) &&
                          !lists.isDelete ? (
                          <Draggable
                            draggableId={lists.questionLabel}
                            index={indx}
                            key={lists.questionLabel}
                          >
                            {(provided) => (
                              <div
                                className="formBox01 "
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="topPart">
                                  <h2>{lists.questionLabel}</h2>
                                  <div>
                                    {editDeleteIcon.map((icon, indy) => (
                                      <div
                                        key={indy}
                                        onClick={() =>
                                          editDeleteQuestionOnClickHandle(
                                            lists.productQuestionId,
                                            "Option",
                                            indy
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            indy === 0
                                              ? icon.editIcon
                                              : indy === 1
                                              ? icon.deleteIcon
                                              : icon.shortIcon
                                          }
                                          loading="lazy"
                                          alt="Logo"
                                          width={"16px"}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="borderBoxCheckBox">
                                  <fieldset className="checkbox-group">
                                    {lists.optionType.map((option, indans) => (
                                      <div className="checkbox" key={indans}>
                                        <label className="checkbox-wrapper">
                                          <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            name="roofType"
                                            value={option}
                                            onChange={(event) =>
                                              handleHomeInsuranceOnChange(event)
                                            }
                                          />
                                          <span className="checkbox-tile">
                                            <span className="checkbox-label">
                                              {option}
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                    ))}
                                  </fieldset>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ) : null
                      )
                    : null}

                  {provided.placeholder}
                  {homeInsurancedetails.productQuestAns.length === 0 && (
                    <div className="topPart">No Data Found</div>
                  )}
                </div>
              )}
            </Droppable>
            <Modal
              show={addShow}
              onHide={handleClose}
              className="AddNewCategoryModal"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body>
                <div className="modal_body">
                  <div className="modal_top">
                    <div className="left">
                      <h1>Add New Question</h1>
                    </div>
                    <div className="right">
                      <img
                        src={IMAGE_PATHS.modalCloseIcon}
                        loading="lazy"
                        alt="Logo"
                        width={"29px"}
                        onClick={handleClose}
                        className="modalCloseIcon"
                      />
                    </div>
                  </div>
                  <div className="modal_content">
                    <Form>
                      <div className="row">
                        <Form.Group
                          className="mb_25"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="label_text">
                            Question Label
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Input type"
                            name="question"
                            value={homeInsurancedetails.question}
                            onChange={(event) =>
                              handleHomeInsuranceOnChange(event)
                            }
                          />
                        </Form.Group>
                      </div>

                      <div className="row">
                        <Form.Label className="label_text">
                          Answer Type
                        </Form.Label>
                        <div className="borderBoxCheckBox mb_25">
                          <fieldset className="checkbox-group twoOptions">
                            <div className="checkbox">
                              <label className="checkbox-wrapper">
                                <input
                                  defaultChecked
                                  type="radio"
                                  className="checkbox-input"
                                  name="answer"
                                  value="Text"
                                  onChange={(event) =>
                                    handleHomeInsuranceOnChange(event)
                                  }
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">Text</span>
                                </span>
                              </label>
                            </div>
                            <div className="checkbox">
                              <label className="checkbox-wrapper">
                                <input
                                  type="radio"
                                  className="checkbox-input"
                                  name="answer"
                                  value="Option"
                                  onChange={(event) =>
                                    handleHomeInsuranceOnChange(event)
                                  }
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">Option</span>
                                </span>
                              </label>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      {homeInsurancedetails.answer === "Option" ? (
                        <>
                          <div className="row  mb_12">
                            <Form.Label className="label_text">
                              Option Values (comma separated)
                            </Form.Label>
                            <InputGroup className="">
                              <InputGroup.Text id="basic-addon1">
                                <img
                                  src={IMAGE_PATHS.plusIcon2}
                                  loading="lazy"
                                  alt="Logo"
                                  width={"19px"}
                                />
                              </InputGroup.Text>

                              <Form.Control
                                name="options"
                                onKeyDown={(event) =>
                                  addQuestionSelectOnChangeHandle(event)
                                }
                                placeholder="Add an option and press Enter"
                              />
                            </InputGroup>
                          </div>
                          <div className="row mb_25">
                            <div className="col">
                              <div className="options_box_outer">
                                {homeInsurancedetails.options.map(
                                  (option, ind) => (
                                    <div className="options_box" key={ind}>
                                      {option}
                                      <img
                                        src={IMAGE_PATHS.crossIcon2}
                                        loading="lazy"
                                        alt="Logo"
                                        width={"7px"}
                                        onClick={() =>
                                          deleteOptionOfAddSelectQuestion(
                                            option
                                          )
                                        }
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <label className="smallTest">
                            Is this year/age field?
                          </label>

                          <input
                            style={{
                              marginLeft: "10px",
                              marginRight: "2px",
                            }}
                            value="age"
                            name="is_year_field"
                            type="radio"
                            onChange={(event) =>
                              isThisYearOnChangeHandle(event)
                            }
                            // checked={homeInsurancedetails.is_year_field}
                          />
                          <label className="smallTest">Age</label>

                          <input
                            style={{
                              marginLeft: "10px",
                              marginRight: "2px",
                            }}
                            value="year"
                            name="is_year_field"
                            type="radio"
                            onChange={(event) =>
                              isThisYearOnChangeHandle(event)
                            }
                            // checked={homeInsurancedetails.is_year_field}
                          />
                          <label className="smallTest">Year</label>
                        </div>
                      )}

                      <div
                        className="buttn_row mt_20"
                        onClick={() =>
                          handlehomeInsuranceAddNewCatergoryOnClick()
                        }
                      >
                        <Button
                          variant="primary"
                          type="button"
                          className="login sign_in_button"
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={editShow}
              onHide={handleClose2}
              className="AddNewCategoryModal"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body>
                <div className="modal_body">
                  <div className="modal_top">
                    <div className="left">
                      <h1>Edit New Question</h1>
                    </div>
                    <div className="right">
                      <img
                        src={IMAGE_PATHS.modalCloseIcon}
                        loading="lazy"
                        alt="Logo"
                        width={"29px"}
                        onClick={handleClose2}
                        className="modalCloseIcon"
                      />
                    </div>
                  </div>
                  <div className="modal_content">
                    <Form>
                      <div className="row">
                        <Form.Group
                          className="mb_25"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="label_text">
                            Question Label
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Input type"
                            name="question"
                            value={homeInsurancedetails.question}
                            onChange={(event) =>
                              handleHomeInsuranceOnChange(event)
                            }
                          />
                        </Form.Group>
                      </div>

                      <div className="row">
                        <Form.Label className="label_text">
                          Answer Type
                        </Form.Label>
                        <div className="borderBoxCheckBox mb_25">
                          <fieldset className="checkbox-group twoOptions">
                            <div className="checkbox">
                              <label className="checkbox-wrapper">
                                <input
                                  checked={
                                    homeInsurancedetails.answer === "Text"
                                  }
                                  type="radio"
                                  className="checkbox-input"
                                  name="answer"
                                  value="Text"
                                  onChange={(event) =>
                                    handleHomeInsuranceOnChange(event)
                                  }
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">Text</span>
                                </span>
                              </label>
                            </div>
                            <div className="checkbox">
                              <label className="checkbox-wrapper">
                                <input
                                  checked={
                                    homeInsurancedetails.answer === "Option"
                                  }
                                  type="radio"
                                  className="checkbox-input"
                                  name="answer"
                                  value="Option"
                                  onChange={(event) =>
                                    handleHomeInsuranceOnChange(event)
                                  }
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">Option</span>
                                </span>
                              </label>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      {homeInsurancedetails.answer === "Option" ? (
                        <>
                          <div className="row  mb_12">
                            <Form.Label className="label_text">
                              Option Values (comma separated)
                            </Form.Label>
                            <InputGroup className="">
                              <InputGroup.Text id="basic-addon1">
                                <img
                                  src={IMAGE_PATHS.plusIcon2}
                                  loading="lazy"
                                  alt="Logo"
                                  width={"19px"}
                                />
                              </InputGroup.Text>
                              <Form.Control
                                name="options"
                                onKeyDown={(event) =>
                                  addQuestionSelectOnChangeHandle(event)
                                }
                                placeholder="Add an option and press Enter"
                              />
                            </InputGroup>
                          </div>
                          <div className="row mb_25">
                            <div className="col">
                              <div className="options_box_outer">
                                {homeInsurancedetails.options.map(
                                  (option, ind) => (
                                    <div className="options_box" key={ind}>
                                      {option}
                                      <img
                                        src={IMAGE_PATHS.crossIcon2}
                                        loading="lazy"
                                        alt="Logo"
                                        width={"7px"}
                                        onClick={() =>
                                          deleteOptionOfAddSelectQuestion(
                                            option
                                          )
                                        }
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <label className="smallTest">
                            Is this year/age field?
                          </label>

                          <input
                            style={{
                              marginLeft: "10px",
                              marginRight: "2px",
                            }}
                            value="age"
                            name="is_year_field"
                            type="radio"
                            onChange={(event) =>
                              isThisYearOnChangeHandle(event)
                            }
                            checked={homeInsurancedetails.is_year_field}
                          />
                          <label className="smallTest">Age</label>

                          <input
                            style={{
                              marginLeft: "10px",
                              marginRight: "2px",
                            }}
                            value="year"
                            name="is_year_field"
                            type="radio"
                            onChange={(event) =>
                              isThisYearOnChangeHandle(event)
                            }
                            checked={homeInsurancedetails.isCurrentYearField}
                          />
                          <label className="smallTest">Year</label>
                        </div>
                      )}

                      <div
                        className="buttn_row mt_20"
                        onClick={() => editNewCarrierHandleOnClick()}
                      >
                        <Button
                          variant="primary"
                          type="button"
                          className="login sign_in_button"
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </DragDropContext>
    </Layout>
  );
};

export default HomePrpducts;
