let allAnswers = [];

let filteredAnswer = (value, type, questionId) => {
  const existingAnswer = allAnswers.find(
    (answer) => answer.questionId === questionId
  );

  if (!existingAnswer) {
    allAnswers = [...allAnswers, { questionId, type, answer: value }];
  } else {
    existingAnswer.answer = value;
  }
  return allAnswers;
};

const convertPngTosvg = (data) => {
  let product = [...data];

  product.forEach((item) => {
    if (item.productIcon && item.productIcon.productIcon) {
      let data = item.productIcon.productIcon.split(",");
      data[0] = "data:image/svg+xml;base64";
      item.productIcon.productIcon = data.join(",");
    }
  });
  return product;
};

let uploadImage = (event, Swal, setImageDetails, imageDetails, name) => {
  let file = event.target.files[0];
  if (file) {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(file.name)) {
      Swal.fire({
        icon: "error",
        // title: "Invalid file type",
        text: "Please upload a valid image file (jpg, jpeg, png).",
      });
      event.target.value = "";
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageDetails({
        ...imageDetails,
        [name]: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }
};

export { filteredAnswer, convertPngTosvg, uploadImage };
