import React from "react";
import IMAGE_PATHS from "../../constants";

const RiskerLogo = () => {
  return (
    <img
      src={IMAGE_PATHS.risker_logo_admin}
      loading="lazy"
      alt="Logo"
      width={"151px"}
    />
  );
};

export default RiskerLogo;
