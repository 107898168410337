import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import ApiClient from "../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";

const LargeButton = ({ buttonLabel, authPayload }) => {
  const navigate = useNavigate();
  const [isLoader, setisLoader] = useState(false);
  const { http, setAccessToken } = ApiClient();
  const handleAuthButtonOnClick = async () => {
    if (buttonLabel === "Sign in") {
      if (!authPayload?.userId || !authPayload?.password) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "All the fields are mandatory!",
          confirmButtonText: "CANCEL",
        });
      }
      let loginPayload = {
        username: authPayload?.userId.replace(/^\s+/, ""),
        password: authPayload?.password,
        userType: "agent",
      };

      try {
        setisLoader(true);

        let res = await http.post("/login", loginPayload);
        if (
          res.data.role === 2 &&
          !res.data.customerId &&
          res.data.trial === 0
        ) {
          setisLoader(false);
          return Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Please contact your Agent to make payment!",
            confirmButtonText: "CANCEL",
          });
        }
        if (res.status === 200) {
          setAccessToken(
            res.data.token,
            res.data.expired
            // res.data.data.refreshToken
          );
          let profileDetails = {
            firstName: res.data.data.firstName,
            lastName: res.data.data.lastName,
            profileImage: res.data.data.profileImage,
            billingAddress: res.data.data.billingAddress,
            trial: res.data.trial,
            customerId: res.data.customerId,
          };

          sessionStorage.setItem(
            "profileDetails",
            JSON.stringify(profileDetails)
          );
          sessionStorage.setItem("userId", JSON.stringify(res.data.userId));
          sessionStorage.setItem("agentId", JSON.stringify(res.data.agentId));
          sessionStorage.setItem("userRole", JSON.stringify(res.data.role));
          setisLoader(false);
          if (res.data.customerId) {
            navigate(`${process.env.REACT_APP_BASE_URL}/AgentDashboard`);
          } else {
            navigate(`${process.env.REACT_APP_BASE_URL}/SelectYourPlan`);
          }
        }
      } catch (error) {
        setisLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message || "Sorry, Something went wrong",
          confirmButtonText: "CANCEL",
        });
      }
    } else if (buttonLabel === "Admin Sign In") {
      if (authPayload.username && authPayload.password) {
        let payload = {
          username: authPayload.username.replace(/^\s+/, ""),
          password: authPayload.password,
        };

        try {
          setisLoader(true);
          let res = await http.post("/adminLogin", payload);
          console.log(res.data, "D");
          if (res === undefined) {
            setisLoader(false);
            return;
          }

          setAccessToken(
            res.data.token,
            res.data.expired
            // res.data.data.refreshToken
          );

          let profileDetails = {
            firstName: res.data.data.adminFirstName,
            lastName: res.data.data.adminLastName,
            profileImage: res.data.data.profileImage,
            billingAddress: res.data.data.billingAddress,
            adminId: res.data.data.adminId,
          };

          sessionStorage.setItem(
            "profileDetails",
            JSON.stringify(profileDetails)
          );
          sessionStorage.setItem("userRole", JSON.stringify(res.data.role));

          setisLoader(false);
          navigate(`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`);
        } catch (error) {
          setisLoader(false);

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message || "Sorry, Something went wrong",
            confirmButtonText: "CANCEL",
          });
        }
      } else {
        return Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "All the fields are mandatory!",
          confirmButtonText: "CANCEL",
        });
      }
    } else {
      if (
        authPayload?.firstName &&
        authPayload?.lastName &&
        authPayload?.email &&
        authPayload?.orgName &&
        authPayload?.password
      ) {
        if (authPayload?.password !== authPayload?.confPassword) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please match both the passwords!",
            confirmButtonText: "CANCEL",
          });
          return;
        }
        let data = {
          masterEntity: {
            entityType: "Agent",
          },
          organisationName: authPayload?.orgName,
        };

        try {
          setisLoader(true);
          let res = await http.post("/addagent", data);
          if (res.status !== 200) {
            setisLoader(false);
            return;
          }

          let adduserPayload = {
            agentEntity: {
              entityId: res.data?.entityId, //treat as a agentId
            },
            firstName: authPayload?.firstName,
            lastName: authPayload?.lastName,
            userEmail: authPayload?.email.replace(/^\s+/, ""),
            password: authPayload?.confPassword,
          };

          try {
            let response = await http.post("/addUser", {
              ...adduserPayload,
              userRole: 1,
            });
            if (response?.status === 200) {
              setAccessToken(
                response.data.token,
                response.data.expired
                // res.data.data.refreshToken
              );
              let profileDetails = {
                firstName: response?.data?.data?.firstName,
                lastName: response?.data?.data?.lastName,
                profileImage: response?.data?.data?.profileImage,
                billingAddresponses: response?.data?.data?.billingAddresponses,
                trial: response?.data?.trial,
                customerId: response?.data?.customerId,
              };
              sessionStorage.setItem(
                "profileDetails",
                JSON.stringify(profileDetails)
              );

              sessionStorage.setItem(
                "agentId",
                adduserPayload.agentEntity.entityId
              );
              sessionStorage.setItem("userId", response.data?.userId);

              sessionStorage.setItem(
                "userRole",
                JSON.stringify(response.data.role)
              );
              setisLoader(false);
              navigate(`${process.env.REACT_APP_BASE_URL}/SelectYourPlan`);
            }
          } catch (error) {
            setisLoader(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                error.response.data.message || "Sorry, Something went wrong",
              confirmButtonText: "CANCEL",
            });
          }
        } catch (error) {
          setisLoader(false);

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message || "Sorry, Something went wrong",
            confirmButtonText: "CANCEL",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "All the fields are mandatory!",
          confirmButtonText: "CANCEL",
        });
      }
    }
  };
  return (
    <>
      {isLoader && <Loader />}
      <Button
        variant="primary"
        type="button"
        className={`login sign_in_button`}
        onClick={() => handleAuthButtonOnClick()}
      >
        {buttonLabel}
      </Button>
    </>
  );
};

export default LargeButton;
