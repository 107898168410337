let lastLocation = (location) => {
  let pathName = location.pathname.split("/");
  let productIds = pathName[pathName.length - 1];
  return productIds;
};

let secondLastLocation = (location) => {
  let pathName = location.pathname.split("/");
  let productIds = pathName[pathName.length - 2];
  return productIds;
};

export { lastLocation, secondLastLocation };
