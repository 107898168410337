import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import IMAGE_PATHS from "../../constants";
import LargeButton from "../../components/Buttons/LargeButton";

const AgentUpdateProfile = () => {
  const location = useLocation();
  const [agentupdateprofiledetails, setagentupdateprofiledetails] = useState({
    name: "",
    lastName: "",
    email: "",
    oldPass: "",
    newPass: "",
    rePass: "",
  });
  const handleAgentProfileUpdatedDetailsOnChange = (event) => {
    setagentupdateprofiledetails({
      ...agentupdateprofiledetails,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="loginBoxOuter">
      <div className="container-fluid container_outer_02">
        <div className="container">
          <div className="AgentUpdateProfile_top">
            <img
              src={IMAGE_PATHS.risker_logo_white}
              loading="lazy"
              alt="Logo"
              width={"151px"}
            />
          </div>
          <div className="AgentUpdateProfile_bottom">
            <div className="left">
              <div className="user_circle">
                <img
                  src={
                    location?.state?.picture
                      ? location.state.picture
                      : IMAGE_PATHS.userBlankIcon
                  }
                  style={{ borderRadius: "100px" }}
                  loading="lazy"
                  alt="Logo"
                  width={location?.state?.picture ? "100%" : "21px"}
                />
                <div className="user_circle_edit">
                  <Link>
                    <img
                      src={IMAGE_PATHS.userIonEdit}
                      loading="lazy"
                      alt="Logo"
                      width={"21px"}
                    />
                  </Link>
                </div>
              </div>
              <p className="user_name">Thomas H.Dennis</p>
            </div>
            <div className="right">
              <div className="middle">
                <div className="formContainer">
                  <div className="login_top">
                    <h3>Welcome back!</h3>
                  </div>
                  <Form>
                    <div className="row">
                      <Form.Group className="mb_12">
                        <Form.Label
                          className="label_text"
                          htmlFor="formBasicName"
                        >
                          Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Name"
                          id="formBasicName"
                          value={agentupdateprofiledetails.name}
                          name="name"
                          onChange={(event) =>
                            handleAgentProfileUpdatedDetailsOnChange(event)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="mb_12">
                        <Form.Label
                          className="label_text"
                          htmlFor="formBasiclastName"
                        >
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="formBasiclastName"
                          placeholder="Enter last Name"
                          value={agentupdateprofiledetails.lastName}
                          name="lastName"
                          onChange={(event) =>
                            handleAgentProfileUpdatedDetailsOnChange(event)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="mb_12">
                        <Form.Label
                          className="label_text"
                          htmlFor="formBasicEmail"
                        >
                          Email address
                        </Form.Label>
                        <Form.Control
                          type="email"
                          id="formBasicEmail"
                          placeholder="Enter Email Address"
                          value={agentupdateprofiledetails.email}
                          name="email"
                          onChange={(event) =>
                            handleAgentProfileUpdatedDetailsOnChange(event)
                          }
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="row">
                      <Form.Group className="mb_12">
                        <Form.Label
                          className="label_text"
                          htmlFor="oldpassword"
                        >
                          Old Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          id="oldpassword"
                          placeholder="Enter Old Password"
                          value={agentupdateprofiledetails.oldPass}
                          name="oldPass"
                          onChange={(event) =>
                            handleAgentProfileUpdatedDetailsOnChange(event)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="mb_12">
                        <Form.Label
                          className="label_text"
                          htmlFor="newpassword"
                        >
                          New Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          id="newpassword"
                          placeholder="Enter New Password"
                          value={agentupdateprofiledetails.newPass}
                          name="newPass"
                          onChange={(event) =>
                            handleAgentProfileUpdatedDetailsOnChange(event)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="mb_12">
                        <Form.Label className="label_text" htmlFor="repassword">
                          Re-enter Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          id="repassword"
                          placeholder="Enter Password again"
                          value={agentupdateprofiledetails.rePass}
                          name="rePass"
                          onChange={(event) =>
                            handleAgentProfileUpdatedDetailsOnChange(event)
                          }
                        />
                      </Form.Group>
                    </div> */}

                    <div className="row">
                      <div className="buttn_row">
                        <LargeButton buttonLabel={"Submit"} />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentUpdateProfile;
