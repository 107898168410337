import React from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import IMAGE_PATHS from "../../../constants";
import CarriersLeftMenu from "../../../components/CarriersLeftMenu/CarriersLeftMenu";

const AddNewCarrierFinish = () => {
  return (
    <Layout sideBarActive={3}>
      <div className="AdminDashboardContainer unerGuide">
        <div className="head_bread">
          <h1>Add New Carrier</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Add New Carrier</span>
          </div>
        </div>
        <div className="AdminDashboardWhiteBody twoPartCommon pb-0 overflow-hidden">
          <CarriersLeftMenu pageValue={"AddNewCarrierFinish"} />
          <div className="left carriersRight">
            <div className="modal_body">
              <p className="headingBottomText"></p>

              <div className="tab_area">
                <div className="Container357">
                  <div className="SuccessBlueTicIcon_outer">
                    <div className="SuccessBlueTicIcon">
                      <img
                        src={IMAGE_PATHS.SuccessBlueTicIconBig}
                        loading="lazy"
                        alt="Logo"
                        width={"53px"}
                      />
                      <p>Your carrier has been successfully added</p>
                      <div className="row w-100">
                        <div className="buttn_row mt_20">
                          <Link
                            to={`${process.env.REACT_APP_BASE_URL}/Admin/Carriers`}
                          >
                            <Button
                              variant="primary"
                              type="submit"
                              className="login sign_in_button"
                            >
                              Go to Carrier list
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddNewCarrierFinish;
