import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookOAuthProvider } from "facebook-oauth-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <FacebookOAuthProvider appId="3493464020941074" appVersion="v18.0">
    <GoogleOAuthProvider clientId="323401341899-trak8a2i7mg960tfvb6u1kdh18r42l0o.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </FacebookOAuthProvider>
);

reportWebVitals();
