import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IMAGE_PATHS from "../../../constants";
import ApiClient from "../../../ApiClient/ApiClient";
import { Button, Modal } from "react-bootstrap";

const LeftMenuAgent = (props) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
  };

  const userRole = parseInt(sessionStorage.getItem("userRole"));

  const { logout } = ApiClient();

  const signout = () => {
    logout("agent");
  };
  return (
    <div>
      <div className="full_logo">
        <img
          src={IMAGE_PATHS.risker_logo_admin}
          loading="lazy"
          alt="Logo"
          width={"120px"}
          onClick={() =>
            navigate(`${process.env.REACT_APP_BASE_URL}/AgentDashboard`)
          }
        />
      </div>
      <div className="small_logo">
        <img
          src={IMAGE_PATHS.risker_logo_admin_small}
          loading="lazy"
          alt="Logo"
          width={"120px"}
        />
      </div>
      <div>
        <ul className="mainMenu">
          <li className="AgentDashboard">
            <Link
              className={props.sideBarActive === 21 ? "active" : ""}
              to={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}
            >
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="AgentSearchHistory">
            <Link
              className={props.sideBarActive === 22 ? "active" : ""}
              onClick={() => sessionStorage.removeItem("todaySearch")}
              to={`${process.env.REACT_APP_BASE_URL}/SearchHistoryAgent`}
            >
              <span>Search History</span>
            </Link>
          </li>
          <li className="AgentCarriers">
            <Link
              className={props.sideBarActive === 23 ? "active" : ""}
              onClick={() => sessionStorage.removeItem("tab")}
              to={`${process.env.REACT_APP_BASE_URL}/CarriersAgent`}
            >
              <span>Carriers</span>
            </Link>
          </li>
          {userRole === 1 ? (
            <li className="AgentUserManagement">
              <Link
                className={props.sideBarActive === 24 ? "active" : ""}
                to={`${process.env.REACT_APP_BASE_URL}/UserManagementAgent`}
              >
                <span>User Management</span>
              </Link>
            </li>
          ) : null}
          <li className="AgentReport">
            <Link
              className={props.sideBarActive === 25 ? "active" : ""}
              to={`${process.env.REACT_APP_BASE_URL}/ReportAgent`}
            >
              <span>Report</span>
            </Link>
          </li>
          <li className="AgentBillingManagement">
            <Link
              className={props.sideBarActive === 26 ? "active" : ""}
              to={`${process.env.REACT_APP_BASE_URL}/BillingManagement`}
            >
              <span>Billing Management</span>
            </Link>
          </li>
          {/* <li className="AgentReviews">
            <Link
              className={props.sideBarActive === 27 ? "active" : ""}
              to={`${process.env.REACT_APP_BASE_URL}/ReviewsAgent`}
            >
              <span>Reviews</span>
            </Link>
          </li> */}
          <li
            onClick={() => {
              setShow(true);
            }}
            className="Logout"
          >
            <Link>
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
      <Modal show={show} onHide={handleClose} size="sm" centered>
        <Modal.Body>
          <div className="modal_body pad20">
            <img
              src={IMAGE_PATHS.Close_modal_normal}
              loading="lazy"
              alt="Logo"
              width={"15px"}
              onClick={handleClose}
              className="modalCrossNormal hand"
            />
            <p className="logoutText">
              Are you sure,
              <br />
              you want to logout?
            </p>
            <div className="logoutButton">
              <Button
                variant="primary"
                onClick={handleClose}
                className="button_grayBorder"
              >
                Cancel
              </Button>
              <Button
                className="button_lightBlueFill"
                variant="danger"
                onClick={() => {
                  signout();
                }}
              >
                Logout
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeftMenuAgent;
