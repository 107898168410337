import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import { convertPngTosvg } from "../../../components/FunctionHelper";

let allAddNewCategoryColors = [
  { color: "#65cfc9", classNames: "color_01" },
  { color: "#e99e74", classNames: "color_02" },
  { color: "#daae57", classNames: "color_03" },
  { color: "#df6a6a", classNames: "color_04" },
  { color: "#528be0", classNames: "color_05" },
  { color: "#9beaac", classNames: "color_06" },
  { color: "#ffb35a", classNames: "color_07" },
  { color: "#d9ca42", classNames: "color_08" },
  { color: "#8eb485", classNames: "color_09" },
  { color: "#ff2257", classNames: "color_10" },
  { color: "#b95691", classNames: "color_11" },
  { color: "#ae7ec4", classNames: "color_12" },
  { color: "#849ae5", classNames: "color_13" },
  { color: "#b96666", classNames: "color_14" },
  { color: "#bba4fc", classNames: "color_15" },
  { color: "#f86588", classNames: "color_16" },
];
let allAddNewCategoryIcons = [
  IMAGE_PATHS.modalIcon_01,
  IMAGE_PATHS.modalIcon_02,
  IMAGE_PATHS.modalIcon_03,
  IMAGE_PATHS.modalIcon_04,
  IMAGE_PATHS.modalIcon_05,
  IMAGE_PATHS.modalIcon_06,
  IMAGE_PATHS.modalIcon_07,
  // IMAGE_PATHS.modalIcon_08,
];

const Products = () => {
  const [show, setShow] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  const navigate = useNavigate();

  const [productcategoriesdetails, setproductcategoriesdetails] = useState({
    categoryName: "",
    colorName: "",
    iconName: "",
    productDetails: "",
    productList: [],
  });

  const [isAddOrActive, setisAddOrActive] = useState("add");
  const handleClose = () => setShow(false);

  const handleShow = (type) => {
    if (type === "add") {
      setproductcategoriesdetails({
        ...productcategoriesdetails,
        categoryName: "",
        colorName: "",
        iconName: "",
        productDetails: "",
      });
      setisAddOrActive("add");
    } else {
      setisLoader(true);
      http
        .get(`/getProductById/${aesUtil.encrypt(JSON.stringify(type))}`, {})
        .then((res) => {
          let stringIcon = res.data.productIcon.productIcon.split(",");
          stringIcon[0] = "data:image/svg+xml;base64,";

          setproductcategoriesdetails({
            ...productcategoriesdetails,
            categoryName: res.data.productName,
            colorName: res.data.productBkColor,
            iconName: stringIcon.join(""),
            productDetails: res.data.productDetails,
          });
          setisLoader(false);
        })
        .catch((error) => {
          setisLoader(false);
          isLogin(error, navigate, Swal);
        });
      setisAddOrActive(type);
    }
    setShow(true);
  };

  const { http } = ApiClient();

  const handleProductCategoriesOnChange = async (event) => {
    let { name, value } = event.target;

    setproductcategoriesdetails({
      ...productcategoriesdetails,
      [name]: value,
    });
  };

  console.log(productcategoriesdetails, "D");

  const handleSearchProductCategoriesButtonOnClick = () => {
    if (
      !productcategoriesdetails.categoryName ||
      !productcategoriesdetails.colorName ||
      !productcategoriesdetails.iconName ||
      !productcategoriesdetails.productDetails
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "OK",
      });
      return;
    }

    let proRequest = {
      productName: productcategoriesdetails.categoryName,
      productBkColor: productcategoriesdetails.colorName,
      productIcon: {
        productIcon: productcategoriesdetails.iconName,
      },
      productDetails: productcategoriesdetails.productDetails,
      status: 1,
    };
    if (isAddOrActive === "add") {
      setisLoader(true);
      http
        .post("/saveProduct", proRequest)
        .then(() => {
          getAllProduct();
          handleClose();
        })
        .catch((error) => {
          setisLoader(false);
          isLogin(error, navigate, Swal);
        });
    } else {
      setisLoader(true);
      http
        .put("/updateProduct", { ...proRequest, productId: isAddOrActive })
        .then(() => {
          getAllProduct();
          handleClose();
        })
        .catch((error) => {
          setisLoader(false);
          isLogin(error, navigate, Swal);
        });
    }
  };

  const getAllProduct = () => {
    setisLoader(true);
    http
      .get("/getAllProduct", {})
      .then((res) => {
        let product = convertPngTosvg(res.data);

        setproductcategoriesdetails({
          ...productcategoriesdetails,
          productList: product,
        });
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllProduct();

    // convert all icon into base64

    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const convertAllIconIntoBase64 = async () => {
      let firstString = "data:image/svg+xml;base64,";
      try {
        let array = [];
        allAddNewCategoryIcons.map(async (icon) => {
          const response = await fetch(icon);
          const svgText = await response.text();
          const base64String = btoa(svgText);
          let result = firstString.concat(base64String);
          array.push(result);
        });
        allAddNewCategoryIcons = array;
      } catch (error) {
        console.error("Error converting SVG to base64:", error);
        return null;
      }
    };
    convertAllIconIntoBase64();
    return () => {};
  }, []);

  return (
    <Layout sideBarActive={2}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer ProductsPage">
        <div className="head_bread">
          <h1>Products</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Products</span>
          </div>
        </div>

        <div className="AdminDashboardWhiteBody">
          <p className="ProductCategories">Product Categories</p>
          <ul
            className="box4"
            style={{
              paddingLeft: "4.5em",
            }}
          >
            {productcategoriesdetails?.productList.length > 0 ? (
              productcategoriesdetails.productList.map((product, ind) => (
                <li
                  key={ind}
                  style={{
                    backgroundColor: `${product.productBkColor}`,
                  }}
                  className="circle_icon_container_li"
                >
                  <img
                    onClick={() =>
                      navigate(
                        `${
                          process.env.REACT_APP_BASE_URL
                        }/Admin/${product.productName.replace(
                          /\s+/g,
                          "-"
                        )}/${aesUtil.encrypt(
                          JSON.stringify(product.productId)
                        )}`
                      )
                    }
                    src={product.productIcon.productIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"56px"}
                    className="imageColorWhite"
                  />
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `${
                          process.env.REACT_APP_BASE_URL
                        }/Admin/${product.productName.replace(
                          /\s+/g,
                          "-"
                        )}/${aesUtil.encrypt(
                          JSON.stringify(product.productId)
                        )}`
                      )
                    }
                  >
                    {product.productName}
                  </p>
                  <div className="circle_icon_container">
                    <img
                      src={IMAGE_PATHS.edit_icon}
                      loading="lazy"
                      alt="Logo"
                      width={"16px"}
                      onClick={() => handleShow(product.productId)}
                    />
                  </div>
                </li>
              ))
            ) : (
              <div
                className="box4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                No data found
              </div>
            )}
          </ul>

          <div className="button_area">
            <Button
              className="button_blueBorder"
              onClick={() => handleShow("add")}
            >
              <img
                src={IMAGE_PATHS.phPlusBoldIcon}
                loading="lazy"
                alt="Logo"
                width={"16px"}
              />
              Add New Category
            </Button>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            className="AddNewCategoryModal"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <div className="modal_body">
                <div className="modal_top">
                  <div className="left">
                    <h1>
                      {isAddOrActive === "add"
                        ? "Add New Category"
                        : "Edit Category"}
                    </h1>
                  </div>
                  <div className="right">
                    <img
                      src={IMAGE_PATHS.modalCloseIcon}
                      loading="lazy"
                      alt="Logo"
                      width={"29px"}
                      onClick={handleClose}
                      className="modalCloseIcon"
                    />
                  </div>
                </div>
                <div className="modal_content">
                  <Form>
                    <div className="row">
                      <Form.Group className="mb_12" controlId="formBasicEmail">
                        <Form.Label className="label_text">
                          Name of Category
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter category name"
                          name="categoryName"
                          value={productcategoriesdetails.categoryName}
                          onChange={(event) =>
                            handleProductCategoriesOnChange(event)
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Label className="label_text">
                        Select Color
                      </Form.Label>
                      <div className="SelectColor">
                        <div className="new">
                          {allAddNewCategoryColors.map(
                            ({ color, classNames }, ind) => (
                              <div className="form-group" key={ind}>
                                <input
                                  name="colorName"
                                  type="radio"
                                  id={color}
                                  value={color}
                                  onChange={(event) =>
                                    handleProductCategoriesOnChange(event)
                                  }
                                  checked={
                                    color === productcategoriesdetails.colorName
                                  }
                                />
                                <label
                                  htmlFor={color}
                                  className={classNames}
                                ></label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <Form.Label className="label_text">
                        Select Icon
                      </Form.Label>
                      <div>
                        <fieldset className="checkbox-group">
                          {allAddNewCategoryIcons.map((icon, ind) => (
                            <div className="checkbox" key={ind}>
                              <label className="checkbox-wrapper">
                                <input
                                  type="radio"
                                  name="iconName"
                                  className="checkbox-input"
                                  value={icon}
                                  onChange={(event) =>
                                    handleProductCategoriesOnChange(event)
                                  }
                                  checked={
                                    icon === productcategoriesdetails.iconName
                                  }
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-icon">
                                    <img
                                      src={icon}
                                      loading="lazy"
                                      alt="Logo"
                                      width={"29px"}
                                    />
                                  </span>
                                </span>
                              </label>
                            </div>
                          ))}
                        </fieldset>
                      </div>
                    </div>

                    {/* <Form> */}
                    <div className="row mt-3">
                      <Form.Group className="mb_12">
                        <Form.Label className="label_text">
                          About Product
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="productDetails"
                          value={productcategoriesdetails.productDetails || ""}
                          onChange={(event) =>
                            handleProductCategoriesOnChange(event)
                          }
                        />
                      </Form.Group>
                    </div>

                    {/* </Form> */}

                    <div className="buttn_row mt_20">
                      <Button
                        variant="primary"
                        type="button"
                        className="login sign_in_button"
                        onClick={() =>
                          handleSearchProductCategoriesButtonOnClick()
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
