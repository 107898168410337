import React, { useState } from "react";
import LayoutMain from "../UI/LayoutMain/LayoutMain";
import IMAGE_PATHS from "../../../constants";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { MainPostCalls } from "../../../ApiClient/PostCall";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";

const BookADemo = () => {
  const [scheduleDetails, setscheduleDetails] = useState({
    name: "",
    email: "",
    state: "",
    phone: "",
    date: "",
    time: "",
  });
  const [isLoader, setIsLoader] = useState(false);
  const { http } = ApiClient();

  const scheduleDetailsOnChangeHandle = (event) => {
    let { name, value } = event.target;
    setscheduleDetails({ ...scheduleDetails, [name]: value });
  };

  const scheduleDetailsOnClickHandle = async () => {
    let { name, email, state, phone, date, time } = scheduleDetails;

    if (!name || !email || !state || !phone || !date || !time) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }
    let payload = {
      name,
      email,
      state,
      phoneNumber: phone,
      demoDate: date + "T" + "" + time,
    };

    let data = await MainPostCalls.saveDemo(http, setIsLoader, Swal, payload);
    if (data?.statusCode === 200) {
      Swal.fire({
        icon: "success",
        title: "Saved",
        text: data?.message || "Your demo booked successfully!",
        confirmButtonText: "OK",
      });
      setscheduleDetails({
        ...scheduleDetails,
        name: "",
        email: "",
        state: "",
        phone: "",
        date: "",
        time: "",
      });
    }
  };

  return (
    <div>
      {isLoader && <Loader />}
      <LayoutMain sideBarActive={2}>
        <div className="mainBanner">
          <img src={IMAGE_PATHS.book_a_demo_banner_img} />
          <div className="container mainBannerContainer">
            <h1>Book a demo</h1>
            <p>
              Class aptent taciti sociosqu ad litora torquent per conubia
              nostra, per inceptos himeClass aptent taciti sociosqu
            </p>
          </div>
        </div>
        <div className="book_a_demo_content">
          <div className="container book_a_demo_content_inner">
            <ul className="book_a_demo_content_ul book_a_demo_content_ul_1st">
              <li>
                <div className="orange_circle">1</div>
              </li>
              <li>
                <h3>
                  Aenean pharetra sem a nulla ultrices tincidunt. Pellentesque
                  habitant morbi commodo condimentum
                </h3>
                <ul className="book_a_demo_content_ul_bullete">
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    sit amet justo ipsum. Sed accumsan quam vitae est varius
                    fringilla.{" "}
                  </li>
                  <li>
                    Fusce cursus quam vel dui vehicula tristique sit amet nec
                    nisl.
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="book_a_demo_content_ul book_a_demo_content_ul_1st">
              <li>
                <div className="orange_circle">2</div>
              </li>
              <li>
                <h3>
                  Curabitur nec vulputate nisi. Nam nec libero sit amet arcu
                  bibendum convallis
                </h3>
                <ul className="book_a_demo_content_ul_bullete">
                  <li>
                    Pellentesque sed ligula pulvinar, pellentesque lacus a,
                    feugiat ligula. Nam varius molestie consectetur.
                  </li>
                  <li>
                    Nunc scelerisque tortor vitae augue pulvinar, eu
                    pellentesque odio vestibu
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="book_a_demo_content_ul book_a_demo_content_ul_1st">
              <li>
                <div className="orange_circle">3</div>
              </li>
              <li className="w-100">
                <h3 id="book_demo">Schedule your Demo</h3>
                <ul className="">
                  <li>
                    <div className="grayBox">
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="mb_12">
                            <Form.Label className="label_text">
                              Full Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={scheduleDetails.name}
                              onChange={scheduleDetailsOnChangeHandle}
                              placeholder="Enter name"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb_12">
                            <Form.Label className="label_text">
                              Email address
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="email"
                              value={scheduleDetails.email}
                              onChange={scheduleDetailsOnChangeHandle}
                              placeholder="Enter email address"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="mb_12">
                            <Form.Label className="label_text">
                              Name of State
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="state"
                              value={scheduleDetails.state}
                              onChange={scheduleDetailsOnChangeHandle}
                              placeholder="Enter state name"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb_12">
                            <Form.Label className="label_text">
                              Phone Number
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter phone number"
                              name="phone"
                              value={scheduleDetails.phone}
                              onChange={scheduleDetailsOnChangeHandle}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Form.Label className="label_text">
                            Select Date
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <DatePicker
                              style={{ width: "100%", padding: "12px 15px" }}
                              onChange={(event) =>
                                event &&
                                setscheduleDetails({
                                  ...scheduleDetails,
                                  date: moment(event["$d"]).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                        <Col lg={6}>
                          <Form.Label className="label_text">
                            Select Time
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <TimePicker
                              style={{ width: "100%", padding: "12px 15px" }}
                              onChange={(event) =>
                                event &&
                                setscheduleDetails({
                                  ...scheduleDetails,
                                  time: moment(event["$d"]).format(
                                    "HH:mm:ss.SSSSSS"
                                  ),
                                })
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <div
                        className="button_container"
                        onClick={scheduleDetailsOnClickHandle}
                      >
                        <Button>Submit</Button>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </LayoutMain>
    </div>
  );
};

export default BookADemo;
