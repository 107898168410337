import React, { useEffect, useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  CartesianGrid,
} from "recharts";
import moment from "moment";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";

const { RangePicker } = DatePicker;

const ReportAgent = () => {
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const { http } = ApiClient();
  const getMonthAbbreviation = (value) => {
    switch (value) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "July";
      case 8:
        return "Aug";
      case 9:
        return "Sept";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return "";
    }
  };

  const [reportLists, setReportLists] = useState({
    monthWise: [],
    productWise: [],
    userWise: [],
  });

  const searchHistoryHandleOnChangeCalendar = (event) => {
    if (event) {
      let startDate = moment(event[0]["$d"]).format("YYYY-MM-DD");
      let endDate = moment(event[1]["$d"]).format("YYYY-MM-DD");

      getMonthWiseSearch(startDate, endDate);
      getProductWiseSearch(startDate, endDate);
      getUserWiseSearch(startDate, endDate);
    }
  };

  const getMonthWiseSearch = async (startDate, endDate) => {
    try {
      setIsLoader(true);
      const res = await http.post(
        `/searchUserByMonth`,
        startDate && endDate
          ? {
              startDate,
              endDate,
            }
          : null
      );

      setReportLists((prevLists) => ({
        ...prevLists,
        monthWise: res.data,
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };
  const getProductWiseSearch = async (startDate, endDate) => {
    try {
      setIsLoader(true);
      const res = await http.post(
        `/searchUserByProduct`,
        startDate && endDate
          ? {
              startDate,
              endDate,
            }
          : null
      );
      setReportLists((prevLists) => ({
        ...prevLists,
        productWise: res.data,
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  const getUserWiseSearch = async (startDate, endDate) => {
    try {
      setIsLoader(true);
      const res = await http.post(
        `/searchUserWise`,
        startDate && endDate
          ? {
              startDate,
              endDate,
            }
          : null
      );
      setReportLists((prevLists) => ({
        ...prevLists,
        userWise: res.data,
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getMonthWiseSearch("", "");
    getProductWiseSearch("", "");
    getUserWiseSearch("", "");

    return () => {};

    // eslint-disable-next-line
  }, []);

  return (
    <LayoutAgent sideBarActive={25}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer">
        <div className="head_bread">
          <div className="searchHistoryFormBar">
            <h1>Report</h1>
            <div className="dateButtonContainer2">
              <RangePicker
                className="AgentRangePicker"
                onChange={(event) => searchHistoryHandleOnChangeCalendar(event)}
              />
            </div>
          </div>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}>
              Dashboard
            </Link>
            <span>/ Report</span>
          </div>
        </div>
        <div className="blankContainer">
          <div>
            <ul className="twoPartCommon two_50_50">
              <li className="whiteBoxShadow">
                <h3>Month Wise Search</h3>

                {reportLists.monthWise.length ? (
                  <Link
                    to={`${process.env.REACT_APP_BASE_URL}/ReportDetailsAgent`}
                  >
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        data={reportLists.monthWise}
                        // barSize={20}
                      >
                        <XAxis
                          dataKey="month"
                          interval={"preserveStartEnd"}
                          style={{
                            fontSize: "10px",
                            // fontWeight: "bold",
                          }}
                          tickFormatter={getMonthAbbreviation}
                          axisLine={false}
                          tickLine={false}
                        />
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          style={{
                            fontSize: "10px",
                            // fontWeight: "bold",
                          }}
                          domain={[0, reportLists.monthWise.length + 8]}
                          // allowDataOverflow={true}
                        />
                        {/* <Tooltip content={<CutomizedToolTip />} /> */}
                        {/* <Legend /> */}
                        <CartesianGrid vertical={false} />

                        <Bar
                          dataKey="count"
                          fill="#0AB39C"
                          // name="Month"
                          // legendType="rect"
                          maxBarSize={20}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Link>
                ) : (
                  <div>No Data Found</div>
                )}
              </li>
              <li className="whiteBoxShadow">
                <h3>Product Wise Search</h3>

                {reportLists.productWise.length ? (
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={reportLists.productWise}
                      // barSize={20}
                    >
                      <XAxis
                        dataKey="productName"
                        interval={"preserveStartEnd"}
                        axisLine={false}
                        tickLine={false}
                        style={{
                          fontSize: "10px",
                          // fontWeight: "bold",
                        }}
                      />
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        style={{
                          fontSize: "10px",
                          // fontWeight: "bold",
                        }}
                        domain={[0, reportLists.productWise.length + 8]}
                      />
                      <CartesianGrid vertical={false} />

                      <Bar dataKey="count">
                        {reportLists.productWise.map((entry, ind) => (
                          <Cell fill={entry.color} key={ind} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <div>No Data Found</div>
                )}
              </li>
            </ul>
          </div>
          <div className="whiteBoxShadow mt-3">
            <h3>User Wise Search</h3>

            {reportLists.userWise.length ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  layout="vertical"
                  data={reportLists.userWise}
                  barSize={6}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis
                    type="number"
                    style={{
                      fontSize: "10px",
                      color: "gray",
                    }}
                    axisLine={false}
                    tickLine={false}
                    domain={[0, reportLists.userWise.length + 8]}
                  />
                  <YAxis
                    dataKey="name"
                    type="category"
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                    axisLine={false}
                    tickLine={false}
                  />
                  {/* <Tooltip /> */}

                  <Bar
                    dataKey="count"
                    fill="#0AB39C"
                    style={{ borderRadius: "20px" }}
                    radius={[0, 5, 5, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
        </div>
      </div>
    </LayoutAgent>
  );
};

export default ReportAgent;
