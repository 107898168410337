import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ShowActivePage from "../../../components/ShowActivePage/ShowActivePage";
import { useState } from "react";
import ApiClient from "../../../ApiClient/ApiClient";
import moment from "moment";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";

const SaveCard = () => {
  const navigate = useNavigate();
  const [savecarddetails, setsavecarddetails] = useState({
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    zipCode: "",
  });
  const [isLoader, setIsLoader] = useState(false);

  const { http } = ApiClient();

  // const handleInputChange = (e) => {
  // setsavecarddetails({ ...savecarddetails, [e.target.name]: e.target.value });
  // setCardNumber();
  // const { value } = e.target;
  // if (/^\d*$/.test(value) && value.length <= 4) {
  //   const newCardNumber = [...cardNumber];
  //   newCardNumber[index] = value;
  //   setCardNumber(newCardNumber);
  //   if (inputRefs.current[index + 1] && value.length === 4) {
  //     inputRefs.current[index + 1].focus();
  //   }
  // }
  // };

  // const handleBackspace = (event, index) => {
  //   const { value } = event.target;

  //   if (event.key === "Backspace" && inputRefs.current[index - 1] && !value) {
  //     inputRefs.current[index - 1].focus();
  //   }
  // };

  const handlecardDetailsOnChange = (event) => {
    if (event.target.name === "expiryDate") {
      let formattedExpiry = event.target.value;
      if (
        event.nativeEvent.inputType === "deleteContentBackward" &&
        savecarddetails.expiryDate
      ) {
        const updatedExpiry = savecarddetails.expiryDate.slice(0, -1); // Remove the last character
        setsavecarddetails({
          ...savecarddetails,
          [event.target.name]: updatedExpiry,
        });
      } else if (formattedExpiry.length === 2) {
        formattedExpiry += "/";
      }

      setsavecarddetails({
        ...savecarddetails,
        [event.target.name]: formattedExpiry,
      });
    } else {
      setsavecarddetails({
        ...savecarddetails,
        [event.target.name]: event.target.value,
      });
    }
  };
  const handleSaveCardSubmit = () => {
    let { cardHolderName, cardNumber, expiryDate, cvv, zipCode } =
      savecarddetails;
    if (!cardHolderName || !cardNumber || !expiryDate || !cvv || !zipCode) {
      return Swal.fire({
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    let payload = {
      masterSubscriptionPlan: {
        subscriptionPlanId: sessionStorage.getItem("subscriptionPlanId"),
      },

      userEntity: {
        userId: sessionStorage.getItem("userId"),
      },

      masterPaymentOptions: {
        paymentOptionsId: sessionStorage.getItem("paymentOptionsId"),
      },
      accountHolderName: savecarddetails?.cardHolderName,
      accountNo: savecarddetails.cardNumber,
      cardExpDate: moment(savecarddetails.expiryDate, "MM/YY").format(
        "YYYY-MM-01"
      ),

      zipCode: savecarddetails?.zipCode,
      cvv: savecarddetails?.cvv,
    };

    if (payload.cardExpDate === "Invalid date") {
      return Swal.fire({
        title: "Oops...",
        text: "Card expiry date is invalid!",
        confirmButtonText: "Cancel",
      });
    }
    setIsLoader(true);
    http
      .post("/savePlan", payload)
      .then((res) => {
        setIsLoader(false);
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Card details saved successfully!",
            confirmButtonText: "OK",
          });
          navigate(`${process.env.REACT_APP_BASE_URL}/AgentDbSuccess`, {
            state: { paymentType: "Card" },
          });
        }
      })
      .catch((error) => {
        setIsLoader(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  return (
    <div className="loginBoxOuter">
      {isLoader && <Loader />}
      <div className="container-fluid container_outer_02">
        <div className="container">
          <div className="AgentUpdateProfile_top">
            <img
              src={IMAGE_PATHS.risker_logo_white}
              loading="lazy"
              alt="Logo"
              width={"151px"}
            />
          </div>
          <div className="AgentUpdateProfile_bottom">
            <ShowActivePage pageValue={"SaveCard"} />
            <div className="right">
              <div className="top"></div>
              <div className="middle">
                <div className="formContainer">
                  <div className="login_top">
                    <h3>Save Your Card Details</h3>
                  </div>
                  <Form>
                    <div className="row">
                      <Form.Group className="mb_12">
                        <Form.Label
                          className="label_text"
                          htmlFor="cardholdername"
                        >
                          Card Holder Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="cardholdername"
                          placeholder="Enter card holder name"
                          value={savecarddetails.cardHolderName}
                          name="cardHolderName"
                          onChange={(event) => handlecardDetailsOnChange(event)}
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="mb_12">
                        <Form.Label className="label_text" htmlFor="cardNumber">
                          Card Number
                        </Form.Label>
                        <div>
                          {/* {cardNumber.map((value, index) => ( */}
                          <Form.Control
                            // key={index}
                            type="text"
                            id="cardNumber"
                            name="cardNumber"
                            placeholder="Enter your card number"
                            value={savecarddetails.cardNumber}
                            onChange={(e) => handlecardDetailsOnChange(e)}
                            // onKeyDown={(e) => handleBackspace(e, index)}
                            // ref={(el) => (inputRefs.current[index] = el)}
                          />
                          {/* ))} */}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="row">
                      <div className="ExpiryDateOuter">
                        <div className="ExpiryDate">
                          <Form.Group className="mb_12">
                            <Form.Label
                              className="label_text"
                              htmlFor="expirydate"
                            >
                              Expiry Date
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="expirydate"
                              placeholder="MM/YY"
                              value={savecarddetails.expiryDate}
                              name="expiryDate"
                              onChange={(event) =>
                                handlecardDetailsOnChange(event)
                              }
                              maxLength={5}
                            />
                          </Form.Group>
                        </div>
                        <div className="ExpiryDate">
                          <Form.Group className="mb_12">
                            <Form.Label className="label_text" htmlFor="cvv">
                              CVV.CVC
                            </Form.Label>
                            <Form.Control
                              type="password"
                              id="cvv"
                              placeholder="xxx"
                              value={savecarddetails.cvv}
                              name="cvv"
                              onChange={(event) =>
                                handlecardDetailsOnChange(event)
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <Form.Group className="mb_12">
                        <Form.Label className="label_text" htmlFor="zipcode">
                          ZIP/ Postal code
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="zipcode"
                          placeholder="Enter zip code"
                          value={savecarddetails.zipCode}
                          name="zipCode"
                          onChange={(event) => handlecardDetailsOnChange(event)}
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <div className="buttn_row mt_20">
                        {/* <Link to="/AgentDbSuccess"> */}
                        <Button
                          variant="primary"
                          type="button"
                          className="login sign_in_button"
                          onClick={() => handleSaveCardSubmit()}
                        >
                          Submit
                        </Button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveCard;
