import React, { Fragment, useEffect, useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { DatePicker } from "antd";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { DateFormat } from "../../../components/DateFormat/DateFormat";
import moment from "moment";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import Paginations from "../../../components/Pagination";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";

const { RangePicker } = DatePicker;

const SearchHistoryAgent = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  let date = JSON.parse(sessionStorage.getItem("todaySearch"));
  const [loading, setLoading] = useState(false);
  const [searchHistoryDetails, setsearchHistoryDetails] = useState({
    count: 1,
    allProducts: [],
    customerName: null,
    productName: null,
    date: date?.date || [],
    searchHistoryLists: [],
  });
  const [currCust, setcurrCust] = useState({
    agency: "",
    product: "",
    productId: "",
    createdOn: "",
    matchedCarrier: "",
    createdBy: "",
    lastUpdatedOn: "",
    reviewdcarrier: "",
    state: "",
    stateId: "",
    customerName: "",
  });
  const [oneHist, setOneHist] = useState([
    {
      date: "2023-11-25",
      searchQuestionAnswer: [
        {
          questionAnswer: {
            additionalProp1: "string",
            additionalProp2: "string",
            additionalProp3: "string",
          },
          matchedCarrier: [
            {
              carrierId: 0,
              carrierName: "string",
              carrierEmail: "string",
              carrierContactNo: "string",
              carrierLogo: "string",
              status: 0,
              dateAdded: "2023-11-25T06:35:02.257Z",
              dateUpdated: "2023-11-25T06:35:02.257Z",
            },
          ],
          confermedCarrier: [
            {
              carrierId: 0,
              carrierName: "string",
              carrierEmail: "string",
              carrierContactNo: "string",
              carrierLogo: "string",
              status: 0,
              dateAdded: "2023-11-25T06:35:02.257Z",
              dateUpdated: "2023-11-25T06:35:02.257Z",
            },
          ],
        },
      ],
    },
  ]);
  const { http } = ApiClient();
  const ViewHandleClose = () => setViewShow(false);
  const [viewShow, setViewShow] = useState(false);

  const getIndvHistory = async (obj) => {
    try {
      setcurrCust(obj);
      const {
        agency,
        product,
        productId,
        createdOn,
        matchedCarrier,
        createdBy,
        lastUpdatedOn,
        reviewdcarrier,
        state,
        stateId,
        customerName,
      } = obj;
      let payload = {
        agency: agency,
        product: product,
        productId: productId,
        createdOn: createdOn,
        matchedCarrier: matchedCarrier,
        createdBy: createdBy,
        lastUpdatedOn: lastUpdatedOn,
        reviewdcarrier: reviewdcarrier,
        state: state,
        stateId: stateId,
        customerName: customerName,
      };
      setLoading(true);
      const res = await http.post("/getOneSearchHistory", payload);
      setOneHist(res.data);
      setViewShow(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  const ViewHandleShow = (obj) => {
    setViewShow(true);
    getIndvHistory(obj);
  };

  const searchHistoryHandleOnChangeCalendar = (event) => {
    console.log(event, moment().format("YYYY-MM-DD"), "V");

    if (event) {
      setsearchHistoryDetails({
        ...searchHistoryDetails,
        date: event.map((values) => {
          return moment(values["$d"]).format("YYYY-MM-DD");
          // return DateFormat(values);
        }),
      });
    } else {
      setsearchHistoryDetails({ ...searchHistoryDetails, date: [] });
    }
  };
  const searchHistoryHandleOnChange = (event) => {
    let { value, name } = event.target;
    setsearchHistoryDetails({
      ...searchHistoryDetails,
      [name]: value || null,
    });
  };

  const handleRepeatSearch = async (list) => {
    try {
      setLoading(true);
      let res = await http.post("/getSearchRequestBySearchId", {
        data: aesUtil.encrypt(`${list.searchId}`),
      });

      console.log(res, "R");
      let agentDashboardSearchDetails = {
        customerName: list.customerName,
        product: { proName: list.product, proId: list.productId },
        state: { stateId: list.stateId, stateName: list.stateCode },
      };
      sessionStorage.setItem(
        "agentDashboardSearchDetails",
        JSON.stringify(agentDashboardSearchDetails)
      );
      navigate(`${process.env.REACT_APP_BASE_URL}/AgentDashboardSearch`, {
        state: { repeatSearchData: res.data },
      });
    } catch (error) {
      setLoading(false);

      isLoginAgent(error, navigate, Swal);
    }
  };

  const searchHistoryOnClickHandle = () => {
    let payload = {
      customerName: searchHistoryDetails.customerName,
      productId: parseInt(searchHistoryDetails.productName),
      startDate: searchHistoryDetails.date[0] || null,
      endDate: searchHistoryDetails.date[1] || null,
    };

    setLoading(true);
    http
      .post(`/getAgentSearchHistory/${page}`, payload)
      .then((res) => {
        setsearchHistoryDetails({
          ...searchHistoryDetails,
          searchHistoryLists: res.data,
        });
        setLoading(false);
        sessionStorage.removeItem("todaySearch");
      })
      .catch((error) => {
        setLoading(false);
        isLoginAgent(error, navigate, Swal);
      });
  };

  const getAllData = async () => {
    try {
      setLoading(true);
      let res = await http.get("/getAllProduct");
      // setProducts(res.data);
      setsearchHistoryDetails((prevViewAgencyDetails) => ({
        ...prevViewAgencyDetails,
        allProducts: res.data,
      }));

      let payload = {
        customerName: searchHistoryDetails.customerName,
        productId: parseInt(searchHistoryDetails.productName),
        startDate: searchHistoryDetails.date[0] || null,
        endDate: searchHistoryDetails.date[1] || null,
      };

      res = await http.post(`/getAgentSearchHistory/${page}`, payload);

      // setsearchHistoryLists(res.data);
      setsearchHistoryDetails((prevViewAgencyDetails) => ({
        ...prevViewAgencyDetails,
        searchHistoryLists: res.data,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      isLoginAgent(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [page]);

  return (
    <LayoutAgent sideBarActive={22}>
      {loading && <Loader />}
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Search History</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}>
              Dashboard
            </Link>
            <span>/ Search History</span>
          </div>
        </div>
        <div className="mt-3">
          <div className="searchHistoryFormBar">
            <div>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Customer Name"
                  name="customerName"
                  value={searchHistoryDetails.customerName || ""}
                  onChange={(event) => searchHistoryHandleOnChange(event)}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Select
                aria-label="Default select example"
                name="productName"
                value={searchHistoryDetails.productName || ""}
                onChange={(event) => searchHistoryHandleOnChange(event)}
              >
                <option hidden>Select Product</option>
                {searchHistoryDetails?.allProducts ? (
                  searchHistoryDetails.allProducts.map(
                    ({ productName, productId }) => (
                      <option key={productId} value={productId}>
                        {productName}
                      </option>
                    )
                  )
                ) : (
                  <option>No Product Found</option>
                )}
              </Form.Select>
            </div>
            <div className="dateButtonContainer2">
              <RangePicker
                value={() =>
                  searchHistoryDetails.date.map((date) => moment(date)) || [
                    null,
                    null,
                  ]
                }
                onChange={(event) => searchHistoryHandleOnChangeCalendar(event)}
                className="AgentRangePicker"
              />
            </div>
            <div>
              <Button
                className="button_blue"
                onClick={() => searchHistoryOnClickHandle()}
              >
                <img
                  src={IMAGE_PATHS.search_icon_white}
                  loading="lazy"
                  alt="Logo"
                  width={"13px"}
                />
                Search
              </Button>
            </div>
          </div>
        </div>

        {searchHistoryDetails?.searchHistoryLists.length > 0 ? (
          searchHistoryDetails.searchHistoryLists?.map((el) =>
            el?.historyDatas?.map((list, ind) => (
              <div className="white_data_box" key={ind}>
                <h2>
                  <span className="lightText">Agency:</span> {list.agency}
                </h2>
                <div className="LeftRightPart">
                  <div className="left">
                    <ul className="agencyListing">
                      <li>
                        <ul className="agencyListingData">
                          <li>
                            <span>Product:</span> {list.product || "NA"}
                          </li>
                          <li>
                            <span>Created By:</span> {list.createdBy || "NA"}
                          </li>
                        </ul>
                      </li>
                      <li>
                        <ul className="agencyListingData">
                          <li>
                            <span>Created on:</span>{" "}
                            {DateFormat(list.createdOn) || "NA"}
                          </li>
                          <li>
                            <span>Last Updated On:</span>
                            {DateFormat(list.lastUpdatedOn) || "NA"}
                          </li>
                        </ul>
                      </li>
                      <li>
                        <ul className="agencyListingData">
                          <li>
                            <span>Matched Carrier:</span>
                            {list.matchedCarrier || "NA"}
                          </li>
                          <li>
                            <span>Reviewed Carrier:</span>
                            {list.reviewdcarrier.length || "NA"}
                          </li>
                        </ul>
                      </li>
                      <li>
                        <ul className="agencyListingData">
                          <li>
                            <span>Customer Name:</span>
                            {list.customerName || "NA"}
                          </li>
                          <li>
                            <span>Search Date:</span>
                            {DateFormat(list.searchDate) || "NA"}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="left">
                    <Button
                      className="button_blueBorder"
                      onClick={() => {
                        handleRepeatSearch(list);
                      }}
                    >
                      Repeat Search
                    </Button>
                  </div>
                  <div className="left">
                    <Button
                      className="button_blueBorder"
                      onClick={() => {
                        ViewHandleShow(list);
                      }}
                    >
                      History
                    </Button>
                  </div>
                </div>
              </div>
            ))
          )
        ) : loading ? null : (
          <div
            style={{
              textAlign: "center",
              marginTop: "5rem",
              fontWeight: "600",
            }}
          >
            No Data Found!
          </div>
        )}

        {searchHistoryDetails?.searchHistoryLists.length ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={searchHistoryDetails.searchHistoryLists[0].count}
          />
        ) : null}

        <Modal
          show={viewShow}
          onHide={ViewHandleClose}
          className="SearchHistoryModal"
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h2>Search History Details</h2>
            <ul className="SearchHistoryDetail_modal">
              <li>
                <ul className="SearchHistoryDetail_modal_inner">
                  <li>Agency Name</li>
                  <li>{currCust.agency || "NA"}</li>
                </ul>
              </li>
              <li>
                <ul className="SearchHistoryDetail_modal_inner">
                  <li>User Name</li>
                  <li>{currCust.createdBy || "NA"}</li>
                </ul>
              </li>
              <li>
                <ul className="SearchHistoryDetail_modal_inner">
                  <li>Created On:</li>
                  <li>{DateFormat(currCust.createdOn) || "NA"}</li>
                </ul>
              </li>
              <li>
                <ul className="SearchHistoryDetail_modal_inner">
                  <li>Last Modifed on:</li>
                  <li>{DateFormat(currCust.lastUpdatedOn) || "NA"}</li>
                </ul>
              </li>
              <li>
                <ul className="SearchHistoryDetail_modal_inner">
                  <li>Customer Name</li>
                  <li>{currCust.customerName || "NA"}</li>
                </ul>
              </li>
              <li>
                <ul className="SearchHistoryDetail_modal_inner">
                  <li>Location Searched</li>
                  <li>{currCust.state || "NA"}</li>
                </ul>
              </li>
              <li>
                <ul className="SearchHistoryDetail_modal_inner">
                  <li>Searched Product</li>
                  <li>{currCust.product || "NA"}</li>
                </ul>
              </li>
            </ul>
            <div className="accordian_wrapper">
              <Accordion
                defaultActiveKey="0"
                className="SearchHistoryDetail_accordian"
              >
                {oneHist?.map((hist, idx) => (
                  <Accordion.Item eventKey={`${idx}`} key={idx}>
                    <Accordion.Header>
                      {DateFormat(hist?.date) || "July 15,2023"}
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="sHaBody">
                        {hist?.searchQuestionAnswer?.map((el, indx) => {
                          return (
                            <Fragment key={indx}>
                              <li>
                                <ul className="innerPart">
                                  <li>Selected questions and answer</li>
                                  {Object.entries(el?.questionAnswer)?.map(
                                    (el, i) => (
                                      <li key={i}>
                                        {el[0]}: <span>{el[1]}</span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </li>
                              <li>
                                <ul className="innerPart">
                                  <li>Matched Carriers:</li>
                                  <li className="logoContainer">
                                    {el?.matchedCarrier.length
                                      ? el?.matchedCarrier.map(
                                          (matchCarr, indexes) => (
                                            <img
                                              key={indexes}
                                              src={matchCarr?.carrierLogo}
                                              loading="lazy"
                                              alt="Logo"
                                              width={"50px"}
                                            />
                                          )
                                        )
                                      : "NA"}
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <ul className="innerPart">
                                  <li>Confirmed Carriers:</li>
                                  <li className="logoContainer">
                                    {el?.confermedCarrier
                                      ? el?.confermedCarrier.map(
                                          (confCarr, index) => (
                                            <img
                                              key={index}
                                              src={confCarr?.carrierLogo}
                                              loading="lazy"
                                              alt="Logo"
                                              width={""}
                                            />
                                          )
                                        )
                                      : "NA"}
                                  </li>
                                </ul>
                              </li>
                            </Fragment>
                          );
                        })}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </LayoutAgent>
  );
};

export default SearchHistoryAgent;
