import { initializeApp } from "firebase/app";

import {
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";

// Initialize Firebase with your project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDEs8AO0Cev0KlnNCPgEFqeRKQeVtRrmAY",
  authDomain: "risker-fb.firebaseapp.com",
  projectId: "risker-fb",
  storageBucket: "risker-fb.appspot.com",
  messagingSenderId: "349300585430",
  appId: "1:349300585430:web:93113466591f2e5b6aaa77",
  measurementId: "G-ZCP0HEHVGY",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const fbProvider = new FacebookAuthProvider();
const googleProvider = new GoogleAuthProvider();

export { auth, fbProvider, googleProvider };

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }
