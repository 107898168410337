import React, { useState } from "react";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import ShowActivePage from "../../../components/ShowActivePage/ShowActivePage";
import { useEffect } from "react";
import ApiClient from "../../../ApiClient/ApiClient";
import Loader from "../../../components/Loader/Loader";
import { loadStripe } from "@stripe/stripe-js";
import Swal from "sweetalert2";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";
import { useNavigate } from "react-router-dom";

const SelectPaymentType = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [paymentmetods, setpaymentmetods] = useState([]);
  const { http } = ApiClient();

  const makePayment = async (paymentId) => {
    if (paymentId === 1) {
      navigate(`${process.env.REACT_APP_BASE_URL}/SaveBankAccount`);
    } else {
      let payload = {
        userEntity: {
          userId: +sessionStorage.getItem("userId"),
        },
        agentEntity: {
          entityId: 1,
        },
        masterSubscriptionPlan: {
          subscriptionPlanId: +sessionStorage.getItem("subscriptionPlanId"),
        },

        masterPaymentOptions: {
          paymentOptionsId: paymentId,
        },
      };

      try {
        setLoading(true);

        await http.post("/savePlan", payload);

        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PK);

        let stripePayload = {
          subscriptionId: +sessionStorage.getItem("subscriptionPlanId"),
          successUrl: `${process.env.REACT_APP_MAIN_PATH}${process.env.REACT_APP_BASE_URL}/AgentDbSuccess`,
          cancelUrl: `${process.env.REACT_APP_MAIN_PATH}${process.env.REACT_APP_BASE_URL}/SelectYourPlan`,
        };
        try {
          const response = await http.post("/subscription", stripePayload);

          // For tempo remove stripe

          // For tempo remove stipe

          localStorage.setItem("paymentData", JSON.stringify(response.data));
          setTimeout(() => {
            stripe.redirectToCheckout({
              sessionId: response.data.id,
            });
            // setLoading(false);
          }, 2000);
        } catch (error) {
          setLoading(false);
          isLoginAgent(error, navigate, Swal);
        }
      } catch (error) {
        setLoading(false);
        isLoginAgent(error, navigate, Swal);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    http
      .get("/allPaymentOption")
      .then((res) => {
        if (res.status === 200) {
          setpaymentmetods(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        isLoginAgent(error, navigate, Swal);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="loginBoxOuter">
      {loading && <Loader />}
      <div className="container-fluid container_outer_02">
        <div className="container">
          <div className="AgentUpdateProfile_top">
            <img
              src={IMAGE_PATHS.risker_logo_white}
              loading="lazy"
              alt="Logo"
              width={"151px"}
            />
          </div>
          <div className="AgentUpdateProfile_bottom">
            <ShowActivePage pageValue={"SelectPaymentType"} />
            <div className="right">
              <div className="top"></div>
              <div className="middle">
                <div className="formContainer">
                  <div className="login_top">
                    <h3>Select Your Payment Option</h3>
                  </div>
                </div>
                <div className="twoPlan">
                  {paymentmetods?.map((pay) => {
                    return (
                      <div
                        key={pay?.paymentOptionsId}
                        className={
                          pay?.paymentOption === "Bank"
                            ? "monthly_box payBox sky_light_box"
                            : "yearly_box payBox green_light_box"
                        }
                      >
                        <img
                          src={pay?.paymentLogo}
                          width={100}
                          height={100}
                          loading="lazy"
                          alt="icon"
                        />
                        <h2>
                          {pay?.paymentOption === "Card"
                            ? "Pay By Credit Card"
                            : "Pay By Bank Account"}
                        </h2>

                        <Button
                          onClick={() => makePayment(pay?.paymentOptionsId)}
                        >
                          Select
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPaymentType;
