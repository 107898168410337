import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import ApiClient from "../../../ApiClient/ApiClient";
import { DateFormat } from "../../../components/DateFormat/DateFormat";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
import Paginations from "../../../components/Pagination";

const Agency = () => {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const [agencyDetails, setAgencyDetails] = useState({
    isActive: "Yes",
    searchValue: "",
    allAgencyLists: [],
    initialLists: [],
    agentId: 0,
    initialCheckboxState: 1,
    count: 1,
  });

  const [isLoader, setIsLoader] = useState(false);
  const { http, getToken } = ApiClient();
  const handleClose = () => setShow(false);
  const handleShow = (agentId, agentStatus) => {
    setAgencyDetails({
      ...agencyDetails,
      agentId: agentId,
      initialCheckboxState: agentStatus,
    });

    setShow(true);
  };

  const agencyOnChangeHandle = async (event) => {
    let { value, name } = event.target;

    if (name === "searchValue") {
      const filteredAgencies = agencyDetails.initialLists.filter((agency) =>
        agency.organisationName.toLowerCase().includes(value.toLowerCase())
      );

      setAgencyDetails({
        ...agencyDetails,
        allAgencyLists: filteredAgencies,
        [name]: value,
      });
    } else if (name === "isActive" && value === "Yes") {
      setPage(1);
      setAgencyDetails({
        ...agencyDetails,
        isActive: "Yes",
      });
    } else {
      setPage(1);
      setAgencyDetails({
        ...agencyDetails,
        isActive: "No",
      });
    }
  };

  const editActiveInActiveOnChangeHandle = () => {
    setIsLoader(true);

    http
      .delete(
        `/inactiveAgent/${aesUtil.encrypt(
          JSON.stringify(agencyDetails.agentId)
        )}`
      )
      .then(() => {
        getAllActiveAgency();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
    handleClose();
  };
  const SortingOnClickHandle = (type, key) => {
    if (type === "asc") {
      agencyDetails.allAgencyLists.sort((a, b) =>
        typeof a[key] === "string"
          ? a[key].localeCompare(b[key])
          : a[key] - b[key]
      );
    } else {
      agencyDetails.allAgencyLists.sort((a, b) =>
        typeof b[key] === "string"
          ? b[key].localeCompare(a[key])
          : b[key] - a[key]
      );
    }
    setAgencyDetails({
      ...agencyDetails,
      allAgencyLists: agencyDetails.allAgencyLists,
    });
  };

  const viewOnClickHandle = (agentId) => {
    navigate(`${process.env.REACT_APP_BASE_URL}/Admin/ViewAgency`, {
      state: { agentId: agentId },
    });
  };

  const getAllActiveAgency = () => {
    setIsLoader(true);
    http
      .get(
        `/getAllAgentdetails/${
          agencyDetails.isActive === "Yes" ? 1 : 0
        }?page=${page}`
      )
      .then((res) => {
        setAgencyDetails({
          ...agencyDetails,
          allAgencyLists: res.data.data,
          initialLists: res.data.data,
          count: res.data.count,
        });

        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllActiveAgency();
    return () => {};
    // eslint-disable-next-line
  }, [page, agencyDetails.isActive]);

  return (
    <Layout sideBarActive={5}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>Agency</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Agency</span>
          </div>
        </div>
        <div className="Container_body">
          <div className="twoPart">
            <div className="left">
              <div className="orangeActiveDeactive">
                <fieldset className="checkbox-group twoOptions">
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input
                        type="radio"
                        className="checkbox-input"
                        name="isActive"
                        value="Yes"
                        defaultChecked="checked"
                        onChange={(event) => agencyOnChangeHandle(event)}
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">Active</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input
                        type="radio"
                        className="checkbox-input"
                        value="No"
                        name="isActive"
                        onChange={(event) => agencyOnChangeHandle(event)}
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">Deactive</span>
                      </span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="right">
              <InputGroup className="search_icon">
                <Form.Control
                  placeholder="Search by Agency"
                  aria-label="Search by Agency"
                  aria-describedby="basic-addon2"
                  value={agencyDetails.searchValue}
                  name="searchValue"
                  onChange={(event) => agencyOnChangeHandle(event)}
                />
                <Button variant="outline-secondary" id="button-addon2">
                  <img
                    src={IMAGE_PATHS.search_icon}
                    loading="lazy"
                    alt="Logo"
                    width={"24px"}
                  />
                </Button>
              </InputGroup>
            </div>
          </div>
          <div className="table_area">
            {/* hover1 */}
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>
                    <span className="shorting_container">
                      Agency Name
                      <span className="shorting">
                        <img
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("asc", "organisationName")
                          }
                        />
                        <img
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("desc", "organisationName")
                          }
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Name
                      <span className="shorting">
                        <img
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("asc", "firstName")
                          }
                        />
                        <img
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("desc", "firstName")
                          }
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Active Users
                      <span className="shorting">
                        <img
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("asc", "activeUsers")
                          }
                        />
                        <img
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("desc", "activeUsers")
                          }
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Subscription Type
                      <span className="shorting">
                        <img
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("asc", "subscriptionPlanName")
                          }
                        />
                        <img
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("desc", "subscriptionPlanName")
                          }
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Created On
                      <span className="shorting">
                        <img
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("asc", "ceatedOn")
                          }
                        />

                        <img
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("desc", "ceatedOn")
                          }
                        />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="shorting_container">
                      Expire On
                      <span className="shorting">
                        <img
                          src={IMAGE_PATHS.up_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("asc", "expireOn")
                          }
                        />
                        <img
                          src={IMAGE_PATHS.down_arrow}
                          loading="lazy"
                          alt="Logo"
                          width={"14px"}
                          onClick={() =>
                            SortingOnClickHandle("desc", "expireOn")
                          }
                        />
                      </span>
                    </span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {agencyDetails.allAgencyLists?.length ? (
                  agencyDetails.allAgencyLists.map(
                    ({
                      activeUsers,
                      firstName,
                      lastName,
                      organisationName,
                      subscriptionPlanName,
                      ceatedOn,
                      expireOn,
                      agentId,
                      agentStatus,
                    }) => (
                      <tr key={agentId}>
                        <td>{organisationName}</td>
                        <td>{firstName + " " + lastName}</td>
                        <td>{activeUsers}</td>
                        <td>{subscriptionPlanName}</td>
                        <td>{DateFormat(ceatedOn)}</td>
                        <td>{DateFormat(expireOn)}</td>
                        <td>
                          <span className="action_icon_container">
                            <span>
                              <img
                                src={IMAGE_PATHS.view_icon}
                                loading="lazy"
                                alt="Logo"
                                width={"20px"}
                                onClick={() => viewOnClickHandle(agentId)}
                              />
                            </span>
                            <span>
                              <img
                                src={IMAGE_PATHS.edit_icon_table}
                                loading="lazy"
                                alt="Logo"
                                width={"20px"}
                                onClick={() => handleShow(agentId, agentStatus)}
                              />
                            </span>
                          </span>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: 800 }}>No Data Found</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Modal
              show={show}
              onHide={handleClose}
              className="switchModal"
              backdrop="static"
              keyboard={false}
              size="sm"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <h3>
                  Are you sure,
                  <br />
                  you want to inactive agency?{" "}
                </h3>
                <div className="wrap">
                  <label htmlFor="switchA1" className="switch-item">
                    <input
                      type="checkbox"
                      id="switchA1"
                      className="control"
                      defaultChecked={agencyDetails.initialCheckboxState}
                      onChange={() => editActiveInActiveOnChangeHandle()}
                    />
                    <span className="label"></span>
                    <span className="label_L"></span>
                  </label>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        {agencyDetails.allAgencyLists.length ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={agencyDetails.count}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default Agency;
