import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import IMAGE_PATHS from "../../../constants";
import Modal from "react-bootstrap/Modal";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { PolicyDownload } from "../../../components/PolicyDownload/Policydownload";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";

let sortPayload = {
  status: 1,
  productid: null,
};

const Carriers = () => {
  const [show, setShow] = useState(false);
  const [isLoader, setisLoader] = useState(false);

  const [carrierdetails, setcarrierdetails] = useState({
    state: [],
    stateId: [],
    allProducts: [],
    isActive: 1,
    sortPayload: { status: 1 },
    statesOfcarriers: [],
    carrierLists: [],
  });
  const navigate = useNavigate();
  const { http } = ApiClient();

  const handleClose = () => {
    setcarrierdetails({ ...carrierdetails, stateId: [], state: [] });
    setShow(false);
  };
  const handleShow = () => {
    getAllState();
    setShow(true);
  };

  const carrierdetailsOnClickHandle = () => {
    setisLoader(true);
    http
      .post(`/adminCarrierSort`, {
        ...sortPayload,
        stateId: carrierdetails.stateId,
      })
      .then((res) => {
        setcarrierdetails({
          ...carrierdetails,
          carrierLists: res.data,
        });
        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
    setShow(false);
  };

  const getAllState = () => {
    setisLoader(true);
    http
      .get("/allState", {})
      .then((res) => {
        setcarrierdetails({
          ...carrierdetails,
          statesOfcarriers: res.data,
          state: [],
          stateId: [],
        });

        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  const carrierdetailsOnChangeHandle = (event) => {
    const { name, value, id } = event.target;
    if (name === "isActive") {
      setcarrierdetails((carrierDetails) => ({
        ...carrierDetails,
        sortPayload: { ...carrierDetails.sortPayload, status: +value },
      }));
      sortPayload = { ...sortPayload, status: +value };
    }
    if (name === "category") {
      setcarrierdetails((carrierDetails) => ({
        ...carrierDetails,
        sortPayload: {
          ...carrierDetails.sortPayload,
          productId: +value,
        },
      }));

      sortPayload = { ...sortPayload, productid: +value };
    }

    if (name === "state") {
      const itemsId = carrierdetails.stateId;
      const itemsState = carrierdetails.state;

      const indexId = itemsId.indexOf(+id);
      const indexState = itemsState.indexOf(value);

      if (indexId !== -1) {
        itemsId.splice(indexId, 1);
        itemsState.splice(indexState, 1);
      } else {
        itemsId.push(+id);
        itemsState.push(value);
      }
      setcarrierdetails({
        ...carrierdetails,
        stateId: itemsId,
        state: itemsState,
      });
    }
    if (name !== "state") {
      setisLoader(true);
      http
        .post(`/adminCarrierSort`, sortPayload)
        .then((res) => {
          setcarrierdetails({
            ...carrierdetails,
            carrierLists: res.data,
          });
          setisLoader(false);
        })
        .catch((error) => {
          setisLoader(false);
          isLogin(error, navigate, Swal);
        });
    }
  };

  const selectAllStateHandleOnClick = () => {
    const arrayOfIds = carrierdetails.statesOfcarriers.map(
      (state) => state.stateId
    );

    const arrayOfState = carrierdetails.statesOfcarriers.map(
      (state) => state.stateCode
    );
    setcarrierdetails({
      ...carrierdetails,
      stateId: arrayOfIds,
      state: arrayOfState,
    });
  };

  const downloadPdf = (carrierId, carrierName) => {
    setisLoader(true);
    http
      .get(`/getPolicy/${aesUtil.encrypt(JSON.stringify(carrierId))}`, {})
      .then((res) => {
        PolicyDownload(res.data.carrierPolicy, carrierName);

        setisLoader(false);
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  const getAllCarriers = () => {
    setisLoader(true);
    http
      .get(`/getAllCarrierByStatus/${1}`, {})
      .then((res) => {
        setisLoader(false);
        setcarrierdetails((prevCarriers) => ({
          ...prevCarriers,
          carrierLists: res.data,
        }));
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  const getAllProduct = () => {
    setisLoader(true);
    http
      .get("/getAllProduct", {})
      .then((res) => {
        setcarrierdetails((prevProducts) => ({
          ...prevProducts,
          allProducts: res.data,
        }));
      })
      .catch((error) => {
        setisLoader(false);
        isLogin(error, navigate, Swal);
      });
    // }
  };
  useEffect(() => {
    getAllProduct();
    getAllCarriers();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={3}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer ProductsPage">
        <div className="head_bread">
          <h1>Carriers</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Carriers</span>
          </div>
        </div>
        <div className="Container_body">
          <div className="twoPart">
            <div className="left">
              <div className="orangeActiveDeactive">
                <fieldset className="checkbox-group twoOptions">
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input
                        type="radio"
                        className="checkbox-input"
                        name="isActive"
                        value={1}
                        defaultChecked="checked"
                        onChange={(event) =>
                          carrierdetailsOnChangeHandle(event)
                        }
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">Active</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input
                        type="radio"
                        className="checkbox-input"
                        value={0}
                        name="isActive"
                        onChange={(event) =>
                          carrierdetailsOnChangeHandle(event)
                        }
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">Deactive</span>
                      </span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="right">
              <span>Sort by:</span>
              <div onClick={() => handleShow()}>
                <Form.Select aria-label="Default select example">
                  <option>{carrierdetails.state[0] || "States"}</option>
                </Form.Select>
              </div>
              <div>
                <Form.Select
                  aria-label="Default select example"
                  name="category"
                  onChange={(event) => carrierdetailsOnChangeHandle(event)}
                >
                  <option hidden>Category</option>
                  {carrierdetails.allProducts.length ? (
                    carrierdetails.allProducts.map(
                      ({ productName, productId }) => (
                        <option key={productId} value={productId}>
                          {productName}
                        </option>
                      )
                    )
                  ) : (
                    <option disabled>No Products</option>
                  )}
                </Form.Select>
              </div>
              <div>
                <Link
                  to={`${process.env.REACT_APP_BASE_URL}/Admin/AddNnewcarrier`}
                >
                  <Button
                    className="button_blueBorder"
                    style={{ padding: "11px 15px" }}
                  >
                    <img
                      src={IMAGE_PATHS.phPlusBoldIcon}
                      loading="lazy"
                      alt="Logo"
                      width={"16px"}
                    />
                    Add New Carrier
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="carriers_item">
            <ul>
              {carrierdetails.carrierLists?.map(({ carrier }) => (
                <li key={carrier.carrierId}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `${
                          process.env.REACT_APP_BASE_URL
                        }/Admin/CarriersDetails/${aesUtil.encrypt(
                          JSON.stringify(carrier.carrierId)
                        )}`
                      )
                    }
                  >
                    <h3>{carrier.carrierName}</h3>
                    <div className="image_container">
                      <img
                        src={carrier.carrierLogo}
                        loading="lazy"
                        alt="Logo"
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="twoPart">
                    <div
                      className="left"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        downloadPdf(carrier.carrierId, carrier.carrierName)
                      }
                    >
                      {"UnderWritting GuideLines Status"}
                    </div>
                    {/* <div className="right carrierProgress">
                      <div
                        role="progressbar"
                        aria-valuenow="67"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ "--value": 65 }}
                      ></div>
                    </div> */}
                  </div>
                </li>
              ))}
              {!carrierdetails.carrierLists.length && (
                <li
                  style={{
                    width: "205px",
                    minHeight: "30px",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  No Data Found
                </li>
              )}
            </ul>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="row">
                <div className="twoPartCommon align-items-center mb-3">
                  <div className="left"></div>
                  <div className="right">
                    <img
                      src={IMAGE_PATHS.Close_modal}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      onClick={handleClose}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="twoPartCommon align-items-center">
                  <div className="left">Add states</div>
                  <div className="left">
                    <Button
                      className="button_blueBorder_small"
                      onClick={() => selectAllStateHandleOnClick()}
                    >
                      Select All
                    </Button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="SelectedStateRadio SelectedState mt-2">
                  <div className="row">
                    <fieldset className="checkbox-group">
                      {carrierdetails.statesOfcarriers ? (
                        carrierdetails.statesOfcarriers.map(
                          ({ stateId, stateCode }) => (
                            <div className="checkbox" key={stateId}>
                              <label className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  name="state"
                                  id={stateId}
                                  value={stateCode}
                                  onChange={(event) =>
                                    carrierdetailsOnChangeHandle(event)
                                  }
                                  checked={carrierdetails.stateId.includes(
                                    stateId
                                  )}
                                />
                                <span className="checkbox-tile">
                                  <span className="checkbox-label">
                                    {stateCode}
                                  </span>
                                </span>
                              </label>
                            </div>
                          )
                        )
                      ) : (
                        <Loader />
                      )}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button"
                  onClick={() => carrierdetailsOnClickHandle()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default Carriers;
