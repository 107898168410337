import React, { useEffect, useState } from "react";
import LayoutMain from "../UI/LayoutMain/LayoutMain";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { MainPostCalls } from "../../../ApiClient/PostCall";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";

const StartATrial = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [subsDetails, setsubsDetails] = useState({
    allSubs: [],
  });
  const navigate = useNavigate();

  const { http } = ApiClient();

  const getSubsPlan = async () => {
    let data = await MainPostCalls.getSubscription(http, setIsLoader, Swal);

    setsubsDetails((prevDetails) => ({
      ...prevDetails,
      allSubs: data,
    }));
  };

  useEffect(() => {
    getSubsPlan();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {isLoader && <Loader />}
      <LayoutMain>
        <>
          <div className="innerBanner_01">
            <h1>Discover the Best Carrier for choose your plan</h1>
            <p>No contracts, cancel or change plan any time.</p>
          </div>

          <div className="monthly_yearly_outer">
            <div className="monthly_yearly">
              {subsDetails.allSubs.length ? (
                subsDetails.allSubs.map(
                  ({
                    subscriptionPlanId,
                    subscriptionPlanName,
                    subscriptionAmount,
                  }) => (
                    <div
                      className={subscriptionPlanName.toLowerCase()}
                      key={subscriptionPlanId}
                    >
                      <div className="top">
                        <h3>{subscriptionPlanName}</h3>
                        <p className="price">
                          ${subscriptionAmount}
                          <span>USD</span>
                        </p>
                        <p className="small_text">
                          /
                          {subscriptionPlanName === "Monthly"
                            ? "month"
                            : "year"}
                          , billed {subscriptionPlanName.toLowerCase()}
                        </p>
                      </div>
                      <div className="bottom">
                        <ul>
                          <li>
                            <img
                              src={IMAGE_PATHS.orange_tic}
                              loading="lazy"
                              alt="Logo"
                              width={"15px"}
                            />{" "}
                            Nullam sit amet lacus eget
                          </li>
                          <li>
                            <img
                              src={IMAGE_PATHS.orange_tic}
                              loading="lazy"
                              alt="Logo"
                              width={"15px"}
                            />{" "}
                            Phasellus diam telluscidunt
                          </li>
                          <li>
                            <img
                              src={IMAGE_PATHS.orange_tic}
                              loading="lazy"
                              alt="Logo"
                              width={"15px"}
                            />{" "}
                            Sed in scelerisque nunc vehicula
                          </li>
                        </ul>
                        <Button
                          onClick={() =>
                            navigate(`${process.env.REACT_APP_BASE_URL}/SignUp`)
                          }
                        >
                          Get Started Now
                        </Button>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div>No Data Found</div>
              )}

              {/* <div className="yearly">
                <div className="top">
                  <h3>Yearly</h3>
                  <p className="price">
                    $50<span>USD</span>
                  </p>
                  <p className="small_text">/month, billed monthly</p>
                </div>
                <div className="bottom">
                  <ul>
                    <li>
                      <img
                        src={IMAGE_PATHS.orange_tic}
                        loading="lazy"
                        alt="Logo"
                        width={"15px"}
                      />
                      Nullam sit amet lacus eget
                    </li>
                    <li>
                      <img
                        src={IMAGE_PATHS.orange_tic}
                        loading="lazy"
                        alt="Logo"
                        width={"15px"}
                      />
                      Phasellus diam telluscidunt
                    </li>
                    <li>
                      <img
                        src={IMAGE_PATHS.orange_tic}
                        loading="lazy"
                        alt="Logo"
                        width={"15px"}
                      />
                      Sed in scelerisque nunc vehicula
                    </li>
                  </ul>
                  <Button>Get Started Now</Button>
                </div>
              </div> */}
            </div>
          </div>

          <div className="innerBanner_bottom">
            <div className="container">
              <div className="help_you_box">
                <h3>Help you focus on what’s important to you</h3>
                <Button
                  className="Find_a_Carriers"
                  onClick={() =>
                    navigate(
                      `${process.env.REACT_APP_BASE_URL_MAIN}/CarriersMain`
                    )
                  }
                >
                  Find a Carriers
                </Button>
              </div>
            </div>
          </div>
        </>
      </LayoutMain>
    </div>
  );
};

export default StartATrial;
