import Layout from "../Layout";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  LabelList,
  CartesianGrid,
} from "recharts";
import Loader from "../../../components/Loader/Loader";
import {
  calculateTotalWithPercentage,
  getAllChartUsers,
  getMonthAbbreviation,
} from "../../../components/ChartsUtils/ChartsUtils";
import { isLogin } from "../../../components/IsLogin/IsLogin";

const { RangePicker } = DatePicker;

const Report = () => {
  const [isLoader, setIsLoader] = useState(false);
  const { http } = ApiClient();
  const navigate = useNavigate();

  const [reportLists, setReportLists] = useState({
    monthWise: [],
    productWise: [],
    userWise: [],
    subsriberCountWise: [],
  });

  const searchHistoryHandleOnChangeCalendar = (event) => {
    if (event) {
      let startDate = moment(event[0]["$d"]).format("YYYY-MM-DD");
      let endDate = moment(event[1]["$d"]).format("YYYY-MM-DD");

      getMonthWiseSearch(startDate, endDate);
      getProductWiseSearch(startDate, endDate);
      getSearchSubscriberCountByMonth(startDate, endDate);
      getMonthWiseUserSearch(startDate, endDate);
    }
  };

  const getMonthWiseSearch = async (startDate, endDate) => {
    try {
      setIsLoader(true);
      const res = await http.post(
        `/searchAdminByMonth`,
        startDate && endDate
          ? {
              startDate,
              endDate,
            }
          : null
      );

      setReportLists((prevLists) => ({
        ...prevLists,
        monthWise: res.data,
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };
  const getProductWiseSearch = async (startDate, endDate) => {
    try {
      setIsLoader(true);
      const res = await http.post(
        `/searchAdminByProduct`,
        startDate && endDate
          ? {
              startDate,
              endDate,
            }
          : null
      );
      setReportLists((prevLists) => ({
        ...prevLists,
        productWise: res.data,
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };

  const getSearchSubscriberCountByMonth = async (startDate, endDate) => {
    try {
      setIsLoader(true);
      const res = await http.post(
        `/searchSubscriberCountByMonth`,
        startDate && endDate
          ? {
              startDate,
              endDate,
            }
          : null
      );
      setReportLists((prevLists) => ({
        ...prevLists,
        subsriberCountWise: res.data,
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };

  const getMonthWiseUserSearch = async (startDate, endDate) => {
    try {
      setIsLoader(true);
      const res = await http.post(
        `/searchAllUserAdminByMonthWise`,
        startDate && endDate
          ? {
              startDate,
              endDate,
            }
          : null
      );

      setReportLists((prevLists) => ({
        ...prevLists,
        userWise: calculateTotalWithPercentage(res.data),
        users: getAllChartUsers(res.data),
      }));
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      isLogin(error, navigate, Swal);
    }
  };

  useEffect(() => {
    getMonthWiseSearch("");
    getProductWiseSearch("");
    getSearchSubscriberCountByMonth("");
    getMonthWiseUserSearch("");

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Layout sideBarActive={7}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <div className="ReportDate">
            <h1>Report</h1>
            <div className="dateButtonContainer" style={{ width: "250px" }}>
              <RangePicker
                onChange={(event) => searchHistoryHandleOnChangeCalendar(event)}
                style={{ padding: "10px 20px" }}
              />
            </div>
          </div>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Reports</span>
          </div>
        </div>
        <div className="blankContainer">
          <div className="report3Clo">
            <ul>
              <li className="scribe_count">
                <div className="whiteBoxShadow">
                  <h3>Month Wise Subscribers Count</h3>
                  {reportLists.subsriberCountWise.length ? (
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        data={reportLists.subsriberCountWise}
                        // barSize={20}
                      >
                        <XAxis
                          dataKey="month"
                          interval={"preserveStartEnd"}
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={getMonthAbbreviation}
                          style={{
                            fontSize: "10px",
                            // fontWeight: "bold",
                          }}
                        />
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          style={{
                            fontSize: "10px",
                          }}
                          domain={[
                            0,
                            reportLists.subsriberCountWise.length + 8,
                          ]}
                        />
                        <CartesianGrid vertical={false} />

                        <Bar
                          dataKey="count"
                          fill="#F88B68"
                          label={{ fill: "white", fontSize: 10 }}
                          // name="Product"
                          // legendType="rect"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              </li>
              <li className="mpnth_search">
                <div className="whiteBoxShadow">
                  <h3>Month Wise Search</h3>

                  {reportLists.monthWise.length ? (
                    <Link
                      to={`${process.env.REACT_APP_BASE_URL}/Admin/MonthSearchHistory`}
                    >
                      <ResponsiveContainer
                        width="100%"
                        height={300}
                        style={{ cursor: "pointer" }}
                      >
                        <BarChart
                          data={reportLists.monthWise}
                          // barSize={20}
                        >
                          <XAxis
                            dataKey="month"
                            interval={"preserveStartEnd"}
                            style={{
                              fontSize: "10px",
                              // fontWeight: "bold",
                            }}
                            tickFormatter={getMonthAbbreviation}
                            axisLine={false}
                            tickLine={false}
                          />
                          <YAxis
                            axisLine={false}
                            tickLine={false}
                            style={{
                              fontSize: "10px",
                              // fontWeight: "bold",
                            }}
                            domain={[0, reportLists.monthWise.length + 8]}
                            // allowDataOverflow={true}
                          />
                          {/* <Tooltip content={<CutomizedToolTip />} /> */}
                          {/* <Legend /> */}
                          <CartesianGrid vertical={false} />

                          <Bar
                            dataKey="count"
                            fill="#82ACD3"
                            // name="Month"
                            // legendType="rect"
                            maxBarSize={20}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Link>
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              </li>
              <li className="product_search">
                <div className="whiteBoxShadow">
                  <h3>Product Wise Search</h3>

                  {reportLists.productWise.length ? (
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        data={reportLists.productWise}
                        // barSize={20}
                      >
                        <XAxis
                          dataKey="productName"
                          interval={"preserveStartEnd"}
                          axisLine={false}
                          tickLine={false}
                          style={{
                            fontSize: "10px",
                            // fontWeight: "bold",
                          }}
                        />
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          style={{
                            fontSize: "10px",
                            // fontWeight: "bold",
                          }}
                          domain={[0, reportLists.productWise.length + 8]}
                        />
                        <CartesianGrid vertical={false} />
                        <Bar dataKey="count" fill="#8884d8">
                          {reportLists.productWise.map((entry, ind) => (
                            <Cell fill={entry.color} key={ind} />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              </li>
            </ul>
            <div className="whiteBoxShadow mt-3">
              <h3>Month Wise User Search</h3>
              {/* <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/MonthlySwarchHistory`}> */}
              {reportLists.userWise.length ? (
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={reportLists.userWise} layout="vertical">
                    <XAxis
                      type="number"
                      interval={"preserveStartEnd"}
                      style={{
                        fontSize: "10px",
                      }}
                      axisLine={false}
                      tickLine={false}
                      domain={[0, reportLists.monthWise.length + 8]}
                    />
                    <YAxis
                      dataKey="month"
                      type="category"
                      axisLine={false}
                      tickLine={false}
                      tickFormatter={getMonthAbbreviation}
                      style={{
                        fontSize: "10px",
                      }}
                    />
                    {reportLists.users.map((user) => (
                      <Bar
                        key={user}
                        dataKey={user}
                        stackId="a"
                        fill={`#${Math.floor(Math.random() * 16777215).toString(
                          16
                        )}`}
                      >
                        <LabelList
                          dataKey={user}
                          position="middle"
                          style={{ fill: "white", fontSize: "10" }}
                          formatter={(value) => `${value}%`}
                        />
                      </Bar>
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <div>No Data Found</div>
              )}
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Report;
