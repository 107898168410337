import React, { useEffect, useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import ApiClient from "../../../ApiClient/ApiClient";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/Loader";
import { DateFormat } from "../../../components/DateFormat/DateFormat";
import { isLoginAgent } from "../../../components/IsLogin/IsLogin";

const SubscriptionAgent = (props) => {
  const navigate = useNavigate();
  const [isLoader, setisLoader] = useState(false);
  const { http } = ApiClient();
  const [planDetails, setplanDetails] = useState({
    planName: "",
    planDate: "",
    planAmount: "",
    agentId: "",
    trialPeriods: "",
    totalPlanAmount: "",
  });

  const subscriptionOnClickHandle = async () => {
    if (planDetails.trialPeriods <= 0) {
      let payload = {
        customerId: planDetails.customerId,
        paymentMethod: planDetails.paymentMethod,
        agentId: planDetails.agentId,
      };
      setisLoader(true);
      await http.post("/getSubscriptionCharge", payload);

      setisLoader(false);
      navigate(`${process.env.REACT_APP_BASE_URL}/FinishAgent`);
    } else {
      navigate(`${process.env.REACT_APP_BASE_URL}/FinishAgent`);
    }
  };

  useEffect(() => {
    const getSubsPlan = () => {
      setisLoader(true);
      http
        .get("/getPlan", {})
        .then((res) => {
          setplanDetails({
            ...planDetails,
            trialPeriods: res.data.trialPeriods,
            customerId: res.data.customerId,
            paymentMethod: res.data.paymentMethod,
            agentId: res.data.entityPlanId,
            planName: res.data.subscriptionPlanName,
            planAmount: res.data.subscriptionAmount,
            planDate: res.data.planEndDate,
            totalPlanAmount: res.data.totalAmount,
          });
          setisLoader(false);
        })
        .catch((error) => {
          setisLoader(false);
          isLoginAgent(error, navigate, Swal);
        });
    };
    getSubsPlan();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <LayoutAgent sideBarActive={24}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Add a User</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/UserManagementAgent`}>
              User Management
            </Link>
            <span>/ Add Sub-Agent</span>
          </div>
        </div>
        <div className="AdminDashboardWhiteBody twoPartCommon pb-0 overflow-hidden">
          <div className="carriersLeft">
            <div className="select_plan_process">
              <ul className="displayCarriersInnerLeft">
                <li className="completed">
                  <div>
                    <img
                      src={IMAGE_PATHS.blueCirleTick}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                      height={"31px"}
                    />
                    <span>Add User</span>
                  </div>
                </li>
                <li className="active">
                  <div>
                    <img
                      src={IMAGE_PATHS.subscription_agent_active}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                    />
                    <span>Subscription</span>
                  </div>
                </li>
                <li className="">
                  <div>
                    <img
                      src={IMAGE_PATHS.finish_agent_deactive}
                      loading="lazy"
                      alt="Logo"
                      width={"31px"}
                    />
                    <span>Finish</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="carriersRight unerGuide">
            <div className="modal_body">
              <div className="Container357">
                <div className="SuccessBlueTicIcon_outer">
                  <div className="SubscriptionAgentBorder">
                    <h3>Select Subscription Plan</h3>
                    <div className="purpleBox">
                      <h4>Current Plan: {planDetails.planName} </h4>
                      <p>Valid Till : {DateFormat(planDetails.planDate)}</p>
                    </div>
                    <h5>Prorata Payment this year</h5>
                    <p>
                      {" "}
                      For the current year, prorata payments of{" "}
                      <span>${planDetails.planAmount}</span> will be deducted
                      from account.
                    </p>
                    <p className="smallFont">
                      Starting from the next year, full payment of amount{" "}
                      <span>{planDetails.totalPlanAmount}</span> will be
                      required.
                    </p>
                    <div className="w-100">
                      <div
                        className={props.sideBarActive === 24 ? "active" : ""}
                      >
                        <Button
                          className="login sign_in_button mt_20"
                          onClick={() => subscriptionOnClickHandle()}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutAgent>
  );
};

export default SubscriptionAgent;
