import React, { useState } from "react";
import LayoutAgent from "../LayoutAgent/LayoutAgent";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import IMAGE_PATHS from "../../../constants";
import { Link } from "react-router-dom";

const BillingManagementEdit = () => {
  const [billiingManageDetails, setBilliingManageDetails] = useState({
    typeOfBill: "Card",
  });
  const billingManageOnChangeHandle = (event) => {
    let { value, name } = event.target;

    setBilliingManageDetails({ ...billiingManageDetails, [name]: value });
  };

  return (
    <LayoutAgent sideBarActive={26}>
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Billing Management</h1>
          <div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/AgentDashboard`}>
              Dashboard
            </Link>
            /
            <Link to={`${process.env.REACT_APP_BASE_URL}/BillingManagement`}>
              Billing Management
            </Link>
            <span>/ Modify Saved Card</span>
          </div>
        </div>
        <div className="AdminDashboardWhiteBody unerGuide">
          <div className="heading">
            <h3>Modify Payment Method</h3>
          </div>
          <div className="tab_area">
            <div className="Container357">
              <div className="orangeActiveDeactiveContainer">
                <span>Payment Options</span>
                <div className="orangeActiveDeactive">
                  <fieldset className="checkbox-group twoOptions">
                    <div className="checkbox">
                      <label className="checkbox-wrapper">
                        <input
                          type="radio"
                          className="checkbox-input"
                          name="typeOfBill"
                          value="Card"
                          defaultChecked="checked"
                          onChange={(event) =>
                            billingManageOnChangeHandle(event)
                          }
                        />
                        <span className="checkbox-tile">
                          <span className="checkbox-label">Card</span>
                        </span>
                      </label>
                    </div>
                    <div className="checkbox">
                      <label className="checkbox-wrapper">
                        <input
                          type="radio"
                          className="checkbox-input"
                          value="Account"
                          name="typeOfBill"
                          onChange={(event) =>
                            billingManageOnChangeHandle(event)
                          }
                        />
                        <span className="checkbox-tile">
                          <span className="checkbox-label">Account</span>
                        </span>
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="PaymentCardPayment">
                {billiingManageDetails.typeOfBill === "Card" ? (
                  <div className="PaymentCardDetails">
                    <div className="">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Card holder Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          //   value="Thomas H.Dennis "
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group controlId="formBasicEmail2">
                        <Form.Label>Card Number</Form.Label>
                        <div className="sideBySideTextBox">
                          <Form.Control
                            type="text"
                            placeholder=""
                            // value="4587"
                          />
                          <Form.Control
                            type="text"
                            placeholder=""
                            // value="5873"
                          />
                          <Form.Control
                            type="text"
                            placeholder=""
                            // value="2541"
                          />
                          <Form.Control
                            type="text"
                            placeholder=""
                            // value="8953"
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="row">
                      <div className="sideBySideTextBox">
                        <Form.Group controlId="formBasicEmail3">
                          <Form.Label>Expiry Date</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            // value="10/2025"
                          />
                        </Form.Group>
                        <div>
                          <Form.Label>CVV.CVC</Form.Label>
                          <InputGroup className="InputGroupCVV">
                            <Form.Control
                              placeholder="Recipient's username"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              //   value="xxx"
                            />
                            <Button
                              variant="outline-secondary"
                              id="button-addon2"
                            >
                              <img
                                src={IMAGE_PATHS.help_icon}
                                loading="lazy"
                                alt="Logo"
                                width={"16px"}
                              />
                            </Button>
                          </InputGroup>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <Form.Group controlId="formBasicEmail5">
                        <Form.Label>ZIP/ Postal code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          //   value="96952"
                        />
                      </Form.Group>
                    </div>
                    <div className="row ">
                      <div className="buttn_row mt_20">
                        <Button className="login sign_in_button">Save</Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="PaymentAccountDetails">
                    <div className="row">
                      <Form.Group controlId="formBasicEmail21">
                        <Form.Label>Bank Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter account number"
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group controlId="formBasicEmail22">
                        <Form.Label>Bank Name</Form.Label>
                        <Form.Control type="text" placeholder="Bank Name" />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group controlId="formBasicEmail22">
                        <Form.Label>Account Holder Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your name"
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group controlId="formBasicEmail22">
                        <Form.Label>Routing Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Routing Code"
                        />
                      </Form.Group>
                    </div>
                    <div className="row ">
                      <div className="buttn_row mt_20">
                        <Button className="login sign_in_button">Save</Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutAgent>
  );
};

export default BillingManagementEdit;
