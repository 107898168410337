import React, { useEffect, useState } from "react";
import IMAGE_PATHS from "../../constants";

const ShowActivePage = ({ pageValue }) => {
  const [classesname, setclassesname] = useState({
    selectYourPlan: "",
    selectPaymentType: "",
    saveCard: "",
    saveBankAccount: "",
    agentDbSuccess: "",
  });
  const [activeCompleteImages, setactiveCompleteImages] = useState({
    selectYourPlan: IMAGE_PATHS.SelectPlanActiveIcon,
    selectPaymentType: IMAGE_PATHS.PaymentOptionDeactiveIcon,
    saveCard: IMAGE_PATHS.AccountDeactiveIcon,
    agentDbSuccess: IMAGE_PATHS.FinishDeactiveIcon,
  });
  useEffect(() => {
    const setClassesForPages = () => {
      if (pageValue === "SelectYourPlan") {
        setclassesname({ ...classesname, selectYourPlan: "active" });
      } else if (pageValue === "SelectPaymentType") {
        setclassesname({
          ...classesname,
          selectYourPlan: "completed",
          selectPaymentType: "active",
        });
        setactiveCompleteImages({
          ...activeCompleteImages,
          selectYourPlan: IMAGE_PATHS.blueCirleTick,
          selectPaymentType: IMAGE_PATHS.PaymentOptionActiveIcon,
        });
      } else if (pageValue === "SaveCard" || pageValue === "SaveBankAccount") {
        setclassesname({
          ...classesname,
          selectYourPlan: "completed",
          selectPaymentType: "completed",
          saveCard: "active",
          saveBankAccount: "active",
        });
        setactiveCompleteImages({
          ...activeCompleteImages,
          selectYourPlan: IMAGE_PATHS.blueCirleTick,
          selectPaymentType: IMAGE_PATHS.blueCirleTick,
          saveCard: IMAGE_PATHS.AccountActiveIcon,
        });
      } else {
        setclassesname({
          ...classesname,
          selectYourPlan: "completed",
          selectPaymentType: "completed",
          saveCard: "completed",
          saveBankAccount: "completed",
          agentDbSuccess: "active",
        });
        setactiveCompleteImages({
          ...activeCompleteImages,
          selectYourPlan: IMAGE_PATHS.blueCirleTick,
          selectPaymentType: IMAGE_PATHS.blueCirleTick,
          saveCard: IMAGE_PATHS.blueCirleTick,
          agentDbSuccess: IMAGE_PATHS.FinishActiveIcon,
        });
      }
    };
    setClassesForPages();

    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <div className="left">
      <div className="select_plan_process">
        <ul>
          <li className={classesname.selectYourPlan}>
            <div>
              <img
                src={activeCompleteImages.selectYourPlan}
                loading="lazy"
                alt="Logo"
                width={"31px"}
              />
              <span>Select Plan</span>
            </div>
          </li>
          <li className={classesname.selectPaymentType}>
            <div>
              <img
                src={activeCompleteImages.selectPaymentType}
                loading="lazy"
                alt="Logo"
                width={"31px"}
              />
              <span>Payment Option</span>
            </div>
          </li>
          <li className={classesname.saveCard}>
            <div>
              <img
                src={activeCompleteImages.saveCard}
                loading="lazy"
                alt="Logo"
                width={"31px"}
              />
              <span>Save Card / Account</span>
            </div>
          </li>
          <li className={classesname.agentDbSuccess}>
            <div>
              <img
                src={activeCompleteImages.agentDbSuccess}
                loading="lazy"
                alt="Logo"
                width={"31px"}
              />
              <span>Finish</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ShowActivePage;
