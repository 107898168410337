import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../components/Loader/Loader";
import { isLogin } from "../../../components/IsLogin/IsLogin";
import { uploadImage } from "../../../components/FunctionHelper";

const Teams = () => {
  const [teamDetails, setTeamDetails] = useState({
    allTeams: [],
    name: "",
    designation: "",
    profileImage: "",
    teamId: 0,
    initialCheckboxState: 1,
    popupType: "Add",
  });

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [editShow, setEditShow] = useState(false);
  const handleShow = (teamId, status) => {
    setTeamDetails({
      ...teamDetails,
      teamId,
      initialCheckboxState: status,
    });
    setShow(true);
  };
  const handleEditShowClose = () => setEditShow(false);

  const { http } = ApiClient();

  const handleEditShow = async (teamDetail, type) => {
    if (type === "Add") {
      setTeamDetails({
        ...teamDetails,
        name: "",
        designation: "",
        profileImage: "",
        popupType: type,
      });
    } else {
      setTeamDetails({
        ...teamDetails,
        teamId: teamDetail.teamId,
        name: teamDetail.name,
        designation: teamDetail.designation,
        profileImage: teamDetail.image,
        popupType: type,
      });
    }
    setEditShow(true);
  };
  const deleteTeamOnClick = (teamId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoader(true);
        http
          .delete(`/deleteTeamDetails/${teamId}`)
          .then(() => {
            setIsLoader(false);
            getAllTeams();
          })
          .catch((error) => {
            setIsLoader(false);
            isLogin(error, navigate, Swal);
          });
      }
    });
  };

  const editActiveInActiveOnChangeHandle = () => {
    setIsLoader(true);
    http
      .delete(`/inactiveTeamDetails/${teamDetails.teamId}`)
      .then(() => {
        getAllTeams();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
    handleClose();
  };

  const teamOnChangeHandle = (event) => {
    let { name, value } = event.target;
    if (name === "profileImage") {
      uploadImage(event, Swal, setTeamDetails, teamDetails, name);
    } else {
      setTeamDetails({
        ...teamDetails,
        [name]: value,
      });
    }
  };

  const handleSubmitEditButtonOnClick = () => {
    const { teamId, name, designation, profileImage, popupType } = teamDetails;
    if (!name || !designation || !profileImage) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "CANCEL",
      });
    }

    let teamPayload = {
      name,
      designation,
      image: profileImage,
    };

    setIsLoader(true);
    if (popupType === "Add") {
      http
        .post("/saveTeamDetails", [teamPayload])
        .then(() => {
          getAllTeams();
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          isLogin(error, navigate, Swal);
        });
    } else {
      http
        .put("/updateTeamDetails", {
          ...teamPayload,
          teamId,
        })
        .then(() => {
          getAllTeams();
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          isLogin(error, navigate, Swal);
        });
    }
    handleEditShowClose();
  };

  const getAllTeams = () => {
    setIsLoader(true);
    http
      .get(`/getTeamDetailsAdmin`)
      .then((res) => {
        setTeamDetails({
          ...teamDetails,
          allTeams: res.data,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllTeams();

    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <Layout sideBarActive={10}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>Our Teams</h1>
          <div className="rightWithButton">
            <div>
              <Button
                className="button_blueBorder"
                onClick={() => handleEditShow("", "Add")}
              >
                <img
                  src={IMAGE_PATHS.phPlusBoldIcon}
                  loading="lazy"
                  alt="Logo"
                  width={"16px"}
                />
                Add Team
              </Button>
            </div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ Teams</span>
          </div>
        </div>

        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Designation</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {teamDetails?.allTeams?.length ? (
                teamDetails.allTeams.map((team) => (
                  <tr key={team.teamId}>
                    <td>{team.name}</td>
                    <td>{team.designation}</td>

                    <td>
                      <span
                        className={
                          team.status === 1
                            ? "active_green cursorPointer"
                            : "pending_red cursorPointer"
                        }
                        onClick={() => handleShow(team.teamId, team.status)}
                      >
                        {team.status === 1 ? "Active" : "InActive"}
                      </span>
                    </td>
                    <td>
                      <span className="action_icon_container">
                        <span onClick={() => handleEditShow(team, "Edit")}>
                          <img
                            src={IMAGE_PATHS.edit_icon_table}
                            loading="lazy"
                            alt="Logo"
                            width={"20px"}
                          />
                        </span>

                        <span onClick={() => deleteTeamOnClick(team.teamId)}>
                          <img
                            src={IMAGE_PATHS.DeleteIconGray}
                            loading="lazy"
                            alt="Logo"
                            height={"18px"}
                          />
                        </span>
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>No Data Found</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          <Modal
            show={show}
            onHide={handleClose}
            className="switchModal"
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h3>
                Are you sure,
                <br />
                you want to{" "}
                {teamDetails.initialCheckboxState === 0
                  ? "active"
                  : "inActive"}{" "}
                Team?{" "}
              </h3>
              <div className="wrap">
                <label htmlFor="switchA1" className="switch-item">
                  <input
                    type="checkbox"
                    name="editIsActive"
                    className="control"
                    defaultChecked={teamDetails.initialCheckboxState}
                    onChange={() => editActiveInActiveOnChangeHandle()}
                  />
                  <span className="label"></span>
                  <span className="label_L"></span>
                </label>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <Modal
          show={editShow}
          onHide={handleEditShowClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="modal_top">
                <div className="left">
                  <h1>{teamDetails.popupType} Team</h1>
                </div>
                <div className="right">
                  <img
                    src={IMAGE_PATHS.modalCloseIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"29px"}
                    onClick={handleEditShowClose}
                    className="modalCloseIcon"
                  />
                </div>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Team Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    value={teamDetails.name}
                    onChange={(event) => teamOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">
                    Enter Designation
                  </Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter Designation"
                    name="designation"
                    value={teamDetails.designation}
                    onChange={(event) => teamOnChangeHandle(event)}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <div>
                  <div className="formBox01 noBorder" style={{ width: "100%" }}>
                    <div className="twoPart">
                      <div className="left">
                        <p className="head">Upload Image</p>

                        <p className="smallTest">
                          <img
                            src={
                              teamDetails.profileImage ||
                              IMAGE_PATHS.userBlankIcon
                            }
                            loading="lazy"
                            alt="Logo"
                            width={50}
                            height={50}
                          />
                        </p>
                      </div>
                      <div className="right">
                        <div className="upload_btn_wrapper">
                          <input
                            type="file"
                            name="profileImage"
                            onChange={(event) => teamOnChangeHandle(event)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button"
                  onClick={() => handleSubmitEditButtonOnClick()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* {teamDetails?.allTopics?.length ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={teamDetails.count}
          />
        ) : null} */}
      </div>
    </Layout>
  );
};

export default Teams;
