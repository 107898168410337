import React from "react";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

import IMAGE_PATHS from "../../../../constants";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const HeaderMain = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="headerMainContainerOuter">
        <div className="headerMainContainer">
          <div className="left">
            <Link
              to={`${process.env.REACT_APP_BASE_URL_MAIN}`}
              className="homeLink"
            >
              <img
                src={IMAGE_PATHS.Risker_final_logo}
                className="Risker_final_logo"
                loading="lazy"
                alt="Logo"
                width={""}
              />
            </Link>
            <ul className="mainMenu desktop_show_tab_off">
              <li>
                <Link
                  to={`${process.env.REACT_APP_BASE_URL_MAIN}/ProductsMain`}
                  className={props.sideBarActive == 1 ? "active" : ""}
                >
                  Products
                </Link>
              </li>
              <li>
                <Link
                  to={`${process.env.REACT_APP_BASE_URL_MAIN}/BookADemo`}
                  className={props.sideBarActive == 2 ? "active" : ""}
                >
                  Book a Demo
                </Link>
              </li>
              <li>
                <Link
                  to={`${process.env.REACT_APP_BASE_URL_MAIN}/CarriersMain`}
                  className={props.sideBarActive == 3 ? "active" : ""}
                >
                  Carriers
                </Link>
              </li>
              <li>
                <Link
                  to={`${process.env.REACT_APP_BASE_URL_MAIN}/AgentMain`}
                  className={props.sideBarActive == 4 ? "active" : ""}
                >
                  Agents
                </Link>
              </li>
              <li>
                <Link
                  to={`${process.env.REACT_APP_BASE_URL_MAIN}/AboutMain`}
                  className={props.sideBarActive == 5 ? "active" : ""}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to={`${process.env.REACT_APP_BASE_URL_MAIN}/FAQMain`}
                  className={props.sideBarActive == 6 ? "active" : ""}
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  to={`${process.env.REACT_APP_BASE_URL_MAIN}/ContactMain`}
                  className={props.sideBarActive == 7 ? "active" : ""}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="right">
            <div className="button_menu_area tab_show_mobile_off">
              <span className="phone">
                <img
                  src={IMAGE_PATHS.phone_icon}
                  loading="lazy"
                  alt="Logo"
                  width={"24px"}
                />{" "}
                +1-202-555-0127
              </span>
              <Button
                className="login_main_header"
                onClick={() => navigate(`${process.env.REACT_APP_BASE_URL}`)}
              >
                Login
              </Button>
              <Link to={`${process.env.REACT_APP_BASE_URL_MAIN}/StartATrial`}>
                <Button className="trial_main_header">Start my Trial</Button>
              </Link>
            </div>
            <Button
              variant="primary"
              onClick={handleShow}
              className="hamburger_menu desktop_off_tab_show"
            >
              <img
                src={IMAGE_PATHS.hamburger_menu}
                loading="lazy"
                alt="Logo"
                width={"24px"}
              />
            </Button>
          </div>

          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <Link to={`${process.env.REACT_APP_BASE_URL_MAIN}`}>
                  <img
                    src={IMAGE_PATHS.Risker_final_logo}
                    className="Risker_final_logo"
                    loading="lazy"
                    alt="Logo"
                    width={""}
                  />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul className="OffcanvasMenu">
                <li>
                  <Link to={`${process.env.REACT_APP_BASE_URL_MAIN}`}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.REACT_APP_BASE_URL_MAIN}/ProductsMain`}
                  >
                    Products
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.REACT_APP_BASE_URL_MAIN}/BookADemo`}>
                    Book a Demo
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.REACT_APP_BASE_URL_MAIN}/CarriersMain`}
                  >
                    Carriers
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.REACT_APP_BASE_URL_MAIN}/AgentMain`}>
                    Agents
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.REACT_APP_BASE_URL_MAIN}/AboutMain`}>
                    About
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.REACT_APP_BASE_URL_MAIN}/FAQMain`}>
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.REACT_APP_BASE_URL_MAIN}/ContactMain`}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </>
  );
};

export default HeaderMain;
