import React from "react";
import Layout from "../Layout";
import IMAGE_PATHS from "../../../constants";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

const ResetPasswordAdmin = () => {
  return (
    <Layout>
      <div className="AdminDashboardContainer ">
        <div className="head_bread">
          <h1>Profile Settings</h1>
          <div>
            {/* <Link to="#">Dashboard</Link> <span>/ Search History</span> */}
          </div>
        </div>
        <div className="Container_body">
          <div className="twoPart">
            <div className="left">
              <div className="orangeActiveDeactive">
                <fieldset className="checkbox-group twoOptions">
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input
                        type="radio"
                        className="checkbox-input"
                        name="isActive"
                        value="Yes"
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">Edit Profile</span>
                      </span>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label className="checkbox-wrapper">
                      <input
                        type="radio"
                        className="checkbox-input"
                        value="No"
                        name="isActive"
                        defaultChecked="checked"
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">Reset Password</span>
                      </span>
                    </label>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="right"></div>
          </div>
          <div className="AdminDashboardWhiteBody">
            <div className="Container357">
              <div className="profile_image_area">
                <img
                  src={IMAGE_PATHS.profile_image_01}
                  loading="lazy"
                  alt="Logo"
                  width={"120px"}
                />
                <p className="">Thomas H.Dennis </p>
                <div className="row w-100">
                  <div className="col">
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Billing Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value="2759 Windler Bypass Jedediahville"
                        />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div className="row w-100">
                  <div className="cal-12 twoButtonSideBySide2 mt-0">
                    <button className="button_grayBorder w-100 justify-content-center">
                      Cancel
                    </button>
                    <button className="button_lightBlueFill w-100 justify-content-center">
                      Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="reset_password_area">
                <p>Reset Password</p>
                <div className="row w-100">
                  <div className="col">
                    <Form>
                      <InputGroup className="InputGroupCVV mb-3">
                        <Form.Control
                          placeholder="New Password"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          //   value="xxx"
                        />
                        <Button variant="outline-secondary" id="button-addon2">
                          <img
                            src={IMAGE_PATHS.eye_close}
                            loading="lazy"
                            alt="Logo"
                            width={"16px"}
                          />
                        </Button>
                      </InputGroup>
                      <InputGroup className="InputGroupCVV">
                        <Form.Control
                          placeholder="Confirm Password"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          //   value="xxx"
                        />
                        <Button variant="outline-secondary" id="button-addon2">
                          <img
                            src={IMAGE_PATHS.eye_close}
                            loading="lazy"
                            alt="Logo"
                            width={"16px"}
                          />
                        </Button>
                      </InputGroup>
                    </Form>
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-12">
                    <button className="button_lightBlueFill w-100 justify-content-center">
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPasswordAdmin;
