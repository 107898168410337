import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "react-bootstrap";
import IMAGE_PATHS from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import ApiClient from "../../../ApiClient/ApiClient";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../components/Loader/Loader";
import { isLogin } from "../../../components/IsLogin/IsLogin";
// import { aesUtil } from "../../../ApiClient/EncryptDecrypt/EncryptDecrypt";
// import Paginations from "../../../components/Pagination";

const FAQTopics = () => {
  const [editFAQTopicsDetails, setEditFAQTopicsDetails] = useState({
    allTopics: [],
    topicName: "",
    topicId: 0,
    initialCheckboxState: 1,
    popupType: "Add",
  });

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [editShow, setEditShow] = useState(false);
  const handleShow = (topicId, status) => {
    setEditFAQTopicsDetails({
      ...editFAQTopicsDetails,
      topicId,
      initialCheckboxState: status,
    });
    setShow(true);
  };
  const handleEditShowClose = () => setEditShow(false);

  const { http } = ApiClient();

  const handleEditShow = async (topicId, type) => {
    if (type === "Add") {
      setEditFAQTopicsDetails({
        ...editFAQTopicsDetails,
        topicName: "",
        popupType: type,
      });
    } else {
      try {
        setIsLoader(true);
        let res = await http.get(`/getFAQTopic/${topicId}`);
        setEditFAQTopicsDetails({
          ...editFAQTopicsDetails,
          topicName: res.data.topic,
          topicId,
          popupType: type,
        });
        setIsLoader(false);
      } catch (error) {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      }
    }
    setEditShow(true);
  };
  const deleteFAQTopicsOnClick = (faqTopicId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoader(true);
        http
          .delete(`/deleteFAQTopic/${faqTopicId}`)
          .then(() => {
            setIsLoader(false);
            getAllFAQTopics();
          })
          .catch((error) => {
            setIsLoader(false);
            isLogin(error, navigate, Swal);
          });
      }
    });
  };

  const editActiveInActiveOnChangeHandle = () => {
    setIsLoader(true);
    http
      .delete(`/inactiveFAQTopic/${editFAQTopicsDetails.topicId}`)
      .then(() => {
        getAllFAQTopics();
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
    handleClose();
  };

  const handleSubmitEditButtonOnClick = () => {
    const { topicName, popupType } = editFAQTopicsDetails;
    if (!topicName) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "All the fields are mandatory!",
        confirmButtonText: "Cancel",
      });
    }

    let topicPayload = {
      topic: editFAQTopicsDetails.topicName,
    };

    setIsLoader(true);
    if (popupType === "Add") {
      http
        .post("/saveFAQTopics", [topicPayload])
        .then(() => {
          getAllFAQTopics();
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          isLogin(error, navigate, Swal);
        });
    } else {
      http
        .put("/updateFAQTopic", {
          ...topicPayload,
          faqTopicId: editFAQTopicsDetails.topicId,
        })
        .then(() => {
          getAllFAQTopics();
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          isLogin(error, navigate, Swal);
        });
    }
    handleEditShowClose();
  };

  const getAllFAQTopics = () => {
    setIsLoader(true);
    http
      .get(`/getFAQTopicsAdmin`)
      .then((res) => {
        setEditFAQTopicsDetails({
          ...editFAQTopicsDetails,
          allTopics: res.data,
        });
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        isLogin(error, navigate, Swal);
      });
  };

  useEffect(() => {
    getAllFAQTopics();

    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <Layout sideBarActive={10}>
      {isLoader && <Loader />}
      <div className="AdminDashboardContainer AvencyPage">
        <div className="head_bread">
          <h1>FAQ Topics</h1>
          <div className="rightWithButton">
            <div>
              <Button
                className="button_blueBorder"
                onClick={() => handleEditShow("", "Add")}
              >
                <img
                  src={IMAGE_PATHS.phPlusBoldIcon}
                  loading="lazy"
                  alt="Logo"
                  width={"16px"}
                />
                Add Topic
              </Button>
            </div>
            <Link to={`${process.env.REACT_APP_BASE_URL}/Admin/AdminDashboard`}>
              Dashboard
            </Link>
            <span>/ FAQTopics</span>
          </div>
        </div>

        <div className="table_area">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Topic</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {editFAQTopicsDetails?.allTopics?.length ? (
                editFAQTopicsDetails.allTopics.map(
                  ({ faqTopicId, topic, status }) => (
                    <tr key={faqTopicId}>
                      <td>{topic}</td>
                      <td>
                        <span
                          className={
                            status === 1
                              ? "active_green cursorPointer"
                              : "pending_red cursorPointer"
                          }
                          onClick={() => handleShow(faqTopicId, status)}
                        >
                          {status === 1 ? "Active" : "InActive"}
                        </span>
                      </td>
                      <td>
                        <span className="action_icon_container">
                          <span
                            onClick={() => handleEditShow(faqTopicId, "Edit")}
                          >
                            <img
                              src={IMAGE_PATHS.edit_icon_table}
                              loading="lazy"
                              alt="Logo"
                              width={"20px"}
                            />
                          </span>

                          <span
                            onClick={() => deleteFAQTopicsOnClick(faqTopicId)}
                          >
                            <img
                              src={IMAGE_PATHS.DeleteIconGray}
                              loading="lazy"
                              alt="Logo"
                              height={"18px"}
                            />
                          </span>
                        </span>
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>No Data Found</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          <Modal
            show={show}
            onHide={handleClose}
            className="switchModal"
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h3>
                Are you sure,
                <br />
                you want to{" "}
                {editFAQTopicsDetails.initialCheckboxState === 0
                  ? "active"
                  : "inActive"}{" "}
                FAQ Topics?{" "}
              </h3>
              <div className="wrap">
                <label htmlFor="switchA1" className="switch-item">
                  <input
                    type="checkbox"
                    name="editIsActive"
                    className="control"
                    defaultChecked={editFAQTopicsDetails.initialCheckboxState}
                    onChange={() => editActiveInActiveOnChangeHandle()}
                  />
                  <span className="label"></span>
                  <span className="label_L"></span>
                </label>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <Modal
          show={editShow}
          onHide={handleEditShowClose}
          className="AddNewCategoryModal"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modal_body">
              <div className="modal_top">
                <div className="left">
                  <h1>{editFAQTopicsDetails.popupType} FAQ Topics</h1>
                </div>
                <div className="right">
                  <img
                    src={IMAGE_PATHS.modalCloseIcon}
                    loading="lazy"
                    alt="Logo"
                    width={"29px"}
                    onClick={handleEditShowClose}
                    className="modalCloseIcon"
                  />
                </div>
              </div>
              <div className="row">
                <Form.Group className="mb_12">
                  <Form.Label className="label_text">Topic Name</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter Topic Name"
                    name="topicName"
                    value={editFAQTopicsDetails.topicName}
                    onChange={(event) =>
                      setEditFAQTopicsDetails({
                        ...editFAQTopicsDetails,
                        topicName: event.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>
              <div className="buttn_row mt_20">
                <Button
                  variant="primary"
                  type="button"
                  className="login sign_in_button"
                  onClick={() => handleSubmitEditButtonOnClick()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* {editFAQTopicsDetails?.allTopics?.length ? (
          <Paginations
            page={page}
            setPage={setPage}
            count={editFAQTopicsDetails.count}
          />
        ) : null} */}
      </div>
    </Layout>
  );
};

export default FAQTopics;
